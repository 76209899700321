import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { PageNotfoundComponent } from './component/page-notfound/page-notfound.component';
import { WelcomeComponent } from './component/welcome/welcome.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import {AuthGuard} from './service/auth.guard';
import { AdminComponent } from './component/admin/admin.component';
import { AdminLogsComponent } from './component/admin-logs/admin-logs.component';
import { GameTransactionComponent } from './component/game-transaction/game-transaction.component';
import { GameTransactionsComponent } from './component/game-transactions/game-transactions.component';
import { AdminDetailComponent } from './component/admin-detail/admin-detail.component';
import { AdminDetailHistorysComponent } from './component/admin-detail-historys/admin-detail-historys.component';
import { AgentComponent } from './component/agent/agent.component';
import { AgentDetailComponent } from './component/agent-detail/agent-detail.component';
import { UserComponent } from './component/user/user.component';
import { SMKLockUserListComponent } from './component/skm-lock-user-list/skm-lock-user-list.component';
import { UserActivInactiveComponent } from './component/user-active/user-active.component';
import { UserInactiveComponent } from './component/user-inactive/user-inactive.component';
import { UserInactiveByAdminComponent } from './component/user-inactive-byadmin/user-inactive-byadmin.component';
import { UserInactiveByForgotComponent } from './component/user-inactive-byforgot/user-inactive-byforgot.component';
import { SearchMemberComponent } from './component/search-member/search-member.component';
import { ThreeDLiveListComponent } from './component/3D-live-setting/3D-live-setting.component';
import { TransactionTutorialListComponent } from './component/transaction-tutorial/transaction-tutorial.component';
import { UserDetailComponent } from './component/user-detail/user-detail.component';

import { UserDetailInactiveComponent } from './component/user-detail-inactive/user-detail-inactive.component';
import { UserDetailActiveComponent } from './component/user-detail-active/user-detail-active.component';

import { TwodComponent } from './component/twod/twod.component';
import { TwodDetailComponent } from './component/twod-detail/twod-detail.component';
import { ThreedComponent } from './component/threed/threed.component';
import { ThreedDetailComponent } from './component/threed-detail/threed-detail.component';
import { TopupCheckComponent } from './component/topup-check/topup-check.component';
import { DetailReportComponent } from './component/detailreports/detailreports.component';
import { TopupCheckDetailComponent } from './component/topup-check-detail/topup-check-detail.component';
import { TopupCheckDetailUpdateComponent } from './component/topup-check-detail-update/topup-check-detail-update.component';
import { WithdrawCheckComponent } from './component/withdraw-check/withdraw-check.component';
import { WithdrawCheckDetailComponent } from './component/withdraw-check-detail/withdraw-check-detail.component';
import { TwodbetRecordComponent } from './component/twodbet-record/twodbet-record.component';//amk edit
import { TwodbetCancelRecordComponent } from './component/twodbet-cancel-list/twodbet-cancel-list.component';
import { ChangepasswordComponent } from './component/changepassword/changepassword.component';
import { ChangepasswordDetailComponent } from './component/changepassword-detail/changepassword-detail.component';
import { ServicephoneComponent } from './component/servicephone/servicephone.component';
import { ServicephoneDetailComponent } from './component/servicephone-detail/servicephone-detail.component';
import { TwodBetAmountLimitationComponent } from './component/twod-bet-amount-limitation/twod-bet-amount-limitation.component';
import { TwodBetAmountLimitationDetailComponent } from './component/twod-bet-amount-limitation-detail/twod-bet-amount-limitation-detail.component';
import {TwodBetAmountLimitationDetailUpdateComponent} from './component/twod-bet-amount-limitation-detail-update/twod-bet-amount-limitation-detail-update.component';

import { ThreedBetAmountLimitationComponent } from './component/threed-bet-amount-limitation/threed-bet-amount-limitation.component';
import { ThreedBetAmountLimitationDetailComponent } from './component/threed-bet-amount-limitation-detail/threed-bet-amount-limitation-detail.component';
import { ThreedBetAmountLimitationDetailUpdateComponent} from './component/threed-bet-amount-limitation-detail-update/threed-bet-amount-limitation-detail-update.component';

import { PromotionAndNewsComponent } from './component/promotion-and-news/promotion-and-news.component';
import { PromotionAndNewsDetailComponent } from './component/promotion-and-news-detail/promotion-and-news-detail.component';
import { TwodWinnersComponent } from './component/twod-winners/twod-winners.component';
import { ThreedbetRecordComponent } from './component/threedbet-record/threedbet-record.component';
import { ThreedbetCancelListRecordComponent } from './component/threedbet-cancel-list/threedbet-cancel-list.component';
import { ThreedWinnersComponent } from './component/threed-winners/threed-winners.component';
import { BanListComponent } from './component/ban-list/ban-list.component';

 import { FeedbackComponent } from './component/feedback/feedback.component';
 import { FaqAddnewbackComponent } from './component/faq-addnew/faq-addnew.component';
import { FaqTypebackComponent } from './component/faq-type/faq-type.component';
import { FaqbackComponent } from './component/faq/faq.component';
import { FaqStepbackComponent } from './component/faq-step/faq-step.component';
import { FeedbackDetailComponent } from './component/feedback-detail/feedback-detail.component';
import { HolidayComponent } from './component/holiday/holiday.component';
import { HolidayDetailComponent } from './component/holiday-detail/holiday-detail.component';
import { ThreedResultConfigurationComponent } from './component/threed-result-configuration/threed-result-configuration.component';
import { ThreedResultConfigurationDetailComponent } from './component/threed-result-configuration-detail/threed-result-configuration-detail.component';
import { PaymentComponent } from './component/payment/payment.component';
import { PaymentDetailComponent } from './component/payment-detail/payment-detail.component';
import { TwoReportComponent } from './component/twodreport/twodreport.component';
import { ThreeDReportComponent } from './component/threedreport/threedreport.component';
import { ThreeDDetailsReportComponent } from './component/threeddetailsreport/threeddetailsreport.component';
import { TwodWinnerListReportComponent } from './component/twodwinnerlistreport/twodwinnerlistreport.component';
import { TwoddetailWinnerListReportComponent } from './component/twoddetailwinnerlistreport/twoddetailwinnerlistreport.component';
import { ThreedWinnerListReportComponent } from './component/threedwinnerlistreport/threedwinnerlistreport.component';
import { ThreeddetailWinnerListReportComponent } from './component/threeddetailwinnerlistreport/threeddetailwinnerlistreport.component';
import { UserStatementsReportComponent } from './component/userstatementsreport/userstatementsreport.component';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { NotificationsComponent } from './component/notification/notification.component';

import { OddEntryComponent } from './component/oddentry/oddentry.component';
import { OddEntryDetailComponent } from './component/oddentry_detail/oddentry-detail.component';

import { CommissionCalculationComponent } from './component/commission-calculation/commission-calculation.component';
import { CommissionCalculationDetailComponent } from './component/commission-calculation-detail/commission-calculation-detail.component';
import { CommissionConfrimComponent } from './component/commission-comfirmation/commission-comfirmation.component';
//import { ConfirguationComponent } from './component/thai2d3d-confirguration/thai2d3d-confirguation.component';
import { AgentWithdrawCheckComponent } from './component/agent-withdraw-check-list/withdraw-check-list.component';
import { AgentWithdrawCheckDetailComponent } from './component/agent-withdraw-check-detail/agent-withdraw-check-detail.component';
import { AgentWithdrawAddNewComponent } from './component/agent-withdraw-add-new/agent-withdraw-check-new.component';
import { PromoterCommissionReportComponent } from './component/promotercommissionreport/promoter-commission-report.component';
import { FirstTopupReportComponent } from './component/firsttopupreport/firsttopup-report.component';
import { WinnerMonthlyReportComponent } from './component/winnerreportmonthly/winnerreport_monthly.component';
import { FirstTopupReportDailyComponent } from './component/firsttopupreport_daily/firsttopup-report-daily.component';

import { AdsComponent } from './component/ads/ads.component';
import { AdsDetailComponent } from './component/ads-detail/ads-detail.component';
import { AdvertiseTeamListComponent } from './component/advertise-team-list/advertise-team-list.component';
import { AdvertiseTeamDetailComponent } from './component/advertise-team-detail/advertise-team-detail.component';
//paymentInfo
import {PaymentInfoComponent} from './component/payment_info/payment_info.component';
import {PaymentDetailInfoComponent} from './component/payment_info_detail/payment-detail-info.component';
import {PaymentInfoDetailHistoryComponent} from './component/payment-info-detail-history/payment-info-detail-history.component';
import {PaymentInfoNewHistoryComponent} from './component/payment-info-new-history/payment-info-new-history.component';
import { ConfirguationListComponent } from './component/thai2d3d-confirguation-list/thai2d3d-confirguation-list.component';
import { TwoDDetailReportComponent } from './component/twoddetailreport/twoddetailreport.component';
import { ThreeDDetailReportComponent } from './component/threeddetailreport/threeddetailreport.component';
import { TwoDDetailsReportComponent } from './component/twoddetailsreport/twoddetailsreport.component';

import { FinancialReportComponent } from './component/userfinancialreport/userfinancialreport.component';
import { FinancialDetailReportComponent } from './component/userfinancialdetailreport/userfinancialdetailreport.component';
import { GameTransactionReportComponent } from './component/gametransactionreport/gametransactionreport.component';
import { TransactionAllReportComponent } from './component/transactionallreport/transactionallreport.component';
import { UserRegistrationComponent } from './component/userregistrationreport/userregistrationreport.component';
import { TwoDWinLoseReportComponent } from './component/twodwinlosereport/twodwinlosereport.component';
import { ThreeDWinLoseReportComponent } from './component/threedwinlosereport/threedwinlosereport.component';
import { AccountHolderReportComponent } from './component/handle-amount-report/handle-amount-report.component';
import { Twod50PercentReportComponent } from './component/twodbet50percentreport/twodbet50percentreport.component';
import { Twod35PercentReportComponent } from './component/twodbetreport35percent/twodbet35percentreport.component';
import {TwoBetLimitReportComponent} from './component/twod-bet-amount-limit-report/twod-bet-amount-limit-report.component'
import { BlockBankAccountComponent } from './component/blockbankaccount/blockbankaccount.component';
import { BlockBankAccountDetailComponent } from './component/blockbankaccount-detail/blockbankaccount-detail.component';
import { MarqueeDetailComponent } from './component/marquee-component/marquee-component.component';
import {CustomerServiceComponent} from './component/customer-service/customer-service.component'
import {CustomerServiceDetailComponent} from './component/customer-service-detail/customer-service-detail.component';
import {SalePromoterComponent} from './component/sale-promoter/sale-promoter.component';
import {SalePromoterDetailComponent} from './component/sale-promoter-detail/sale-promoter-detail.component';
import {ThreedBetLimitReportComponent} from './component/threed-bet-amount-limit-report/threed-bet-amount-limit-report.component';
import { GameComponent } from './component/awc-game/game.component';
import { AWCGameDetailComponent } from './component/awc-game-detail/awc-game-detail.component';
import { DiscountComponent } from './component/discount-entry/discount.component';
import { DiscountDetailComponent } from './component/discount-detail/discount-detail.component';
import { GSGameComponent } from './component/gs-game/gs-game.component';
import { GSDetailGameComponent } from './component/gs-game-detail/gs-game-detail.component';

import { PromotionAdsComponent } from './component/promotion-ads/promotion-ads.component';
import { PromotionAdsDetailComponent } from './component/promotion-ads-detail/promotion-ads-detail.component';
import { AccountHolderBeforeCleanReportComponent } from './component/handel-amount-report-beforeclean/handle-amount-beforeclean-report.component';
import { GSGameProviderComponent } from './component/game-provider/game-provider.component';
import { GameProviderDetailComponent } from './component/game-provider-detail/provider-detail.component';
import { TransactionTutorialDetailDetailComponent } from './component/transaction-tutorial-detail/transaction-tutorial-detail.component';
import { GSGameAlertProviderComponent } from './component/game-alert/game-alert.component';
import { GameAlertDetailComponent} from './component/game-alert-detail/game-alert-detail.component';
import { DreamBookAddNewComponent} from './component/dream-book-addnew/dream-book-addnew.component';
import { DreamBookListComponent} from './component/dream-book-list/dream-book-list.component';
import { DreamBookUpdateComponent} from './component/dream-book-update/dream-book-update.component';
import { NeedHelpComponent} from './component/needhelp/need-help.component';
import { NeedHelpDetailComponent} from './component/needhelp-detail/need-help-detail.component';
import {WithdrawalBankAccReportComponent} from './component/withdrawal-bankacc-report/withdrawalbankacc-report.component';
import {MarqueeComponent} from './component/marquee/marquee.component'; //PointPromotionComponent
import {PointPromotionComponent } from './component/point-promotion/point-promotion.component';
import { PointPromotionDetailComponent } from './component/point-promotion-detail/point-promotion-detail.component';
import {TwodLiveResultComponent } from './component/twod-live-result/twod-live-result.component';
import { TwodLiveDetailComponent } from './component/twod-live-result-detail/twod-live-detail.component';
import { PointPromotionUsersComponent } from './component/point-promotion-users/point-promotion-users.component';
import { PointPromotionUserDetilsComponent } from './component/point-promotion-user-detils/point-promotion-user-detils.component';

import { PromotionConfirmedUsersComponent } from './component/promotion-confirmed-users/promotion-confirmed-users.component';
import { PromotionConfirmedUsersDetailComponent } from './component/promotion-confirmed-users-detail/promotion-confirmed-users-detail.component';
import { Version129PointUserListComponent } from './component/version129-point-user-list/version129-point-user-list.component';
import { Version129ConfirmedPointUserListComponent } from './component/version129-confirmed-point-user-list/version129-confirmed-point-user-list.component';
import { UserActivityReportComponent } from './component/user-activity-report/user-activity-report.component';
import { TwodWinnerCommentComponent } from './component/twod-winner-comment/twod-winner-comment.component';
import { TwodWinnerCommentDetailComponent } from './component/twod-winner-comment-detail/twod-winner-comment-detail.component';
import { ThreedWinnerCommentComponent } from './component/threed-winner-comment/threed-winner-comment.component';
import { FirstTopupComponent } from './component/first-topup/first-topup.component';
import { ThreedWinnerCommentDetailComponent } from './component/threed-winner-comment-detail/threed-winner-comment-detail.component';
import { BankSlipComponent } from './component/bank-slip/bank-slip.component';
import { BankSlipDetailComponent } from './component/bank-slip-detail/bank-slip-detail.component';
import { TwodPackageComponent } from './component/twod-package/twod-package.component';
import { TwodPackageDetailComponent } from './component/twod-package-detail/twod-package-detail.component';
import { TwodDreamBookComponent } from './component/twod-dream-book/twod-dream-book.component';
import { TwodDreamBookAddNewComponent } from './component/twod-dream-book-addnew/twod-dream-book-addnew.component';
import { TwodDreamBookUpdateComponent } from './component/twod-dream-book-update/twod-dream-book-update.component';
import { TwodSectionComponent } from './component/twod-section/twod-section.component';
import { TwodSectionDetailComponent } from './component/twod-section-detail/twod-section-detail.component';
import { BankMaintenanceTopupComponent } from './component/bank-maintenance-topup/bank-maintenance-topup.component';
import { BankMaintenanceTopupDetailComponent } from './component/bank-maintenance-topup-detail/bank-maintenance-topup-detail.component';
import { BankMaintenanceWithdrawComponent } from './component/bank-maintenance-withdraw/bank-maintenance-withdraw.component';
import { BankMaintenanceWithdrawDetailComponent } from './component/bank-maintenance-withdraw-detail/bank-maintenance-withdraw-detail.component';
import { ServicephoneSortComponent } from './component/servicephone-sort/servicephone-sort.component';
import { GameProviderSortComponent } from './component/game-provider-sort/game-provider-sort.component';
import { TwoDManualDetailComponent } from './component/twod-manual-detail/twod-manual-detail.component';
import { TwoDManualOnOffComponent } from './component/twod-manual-onoff/twod-manual-onoff.component';
import { UserBetLimitComponent } from './component/user-bet-limit/user-bet-limit.component';
import { AdjustUserBalanceComponent } from './component/adjust-user-balance/adjust-user-balance.component';
import { UsersWithdrawalComponent } from './component/users-withdrawal/users-withdrawal.component';
import { UsersTopupComponent } from './component/users-topup/users-topup.component';
import { TopupWithdrawLogsComponent } from './component/topup-withdraw-logs/topup-withdraw-logs.component';
import { ConfigureUserBetlimitComponent } from './component/configure-user-betlimit/configure-user-betlimit.component';
import { ConfigureUser3DBetlimitComponent } from './component/configure-user-3Dbetlimit/configure-user-3Dbetlimit.component';
import { TwoDHistoryLogComponent } from './component/twoDHistoryLog/twoDHistoryLog.component';
import { GameMaintainAllComponent } from './component/game-maintain-all/game-maintain-all.component';
import { UserOtpsComponent } from './component/user-otps/user-otps.component';
import { ManageWalletsComponent } from './component/manage-wallets/manage-wallets.component';
import { UserGameWalletlogsComponent } from './component/user-game-walletlogs/user-game-walletlogs.component';
import { ManageWalletsProviderDetailComponent } from './component/manage-wallets-provider-detail/manage-wallets-provider-detail.component';
import { ModernInternetComponent } from './component/modern-internet/modern-internet.component';
import { FirstTopupPromotionUserReportComponent } from './component/first_topup_promotionuser_report/first_topup_promotionuser_report.component';
import { GameUsernameComponent } from './component/game-username/game-username.component';
import { WinnerInputComponent } from './component/winner-input/winner-input.component';
//import { WinnerInputsComponent } from './component/winner-inputs/winner-inputs.component';
import { BlackListComponent } from './component/black-list/black-list.component';
import { WinnerInputDetailComponent } from './component/winner-input-detail/winner-input-detail.component';
import { RiskyComponent } from './component/risky/risky.component';
import { BankAccComponent } from './component/bankAcc/bankAcc.component';
import { SmsOperatorComponent } from './component/smsoperator/smsoperator.component';
import { LockgscapisComponent } from './component/lockgscapis/lockgscapis.component';
import { LockgscapisDetailComponent } from './component/lockgscapis-detail/lockgscapis-detail.component';
import { UserRoleComponent } from './component/user-role/user-role.component';
import { PermissionAccessComponent } from './component/permission-access/permission-access.component';
//import { PermessionAccessComponent } from './component/permession-access/permession-access.component';

import { BetHistoryComponent } from './component/bet-history/bet-history.component';

import { WinHistoryComponent } from './component/win-history/win-history.component';
import { DetailsHistoryComponent } from './component/details-history/details-history.component';
import { ResultHistoryComponent } from './component/result-history/result-history.component';
import { FeedBackHistoryComponent } from './component/feedback-history/feedback-history.component';
import { FeedBackHistoryCopyComponent } from './component/feedback-history-copy/feedback-history-copy.component';
import { FaqsHistoryComponent } from './component/faqs-history/faqs-history.component';
import { ConfigHistoryComponent } from './component/config-history/config-history.component';
import { OddsHistoryComponent } from './component/odds-history/odds-history.component';
import { ReportsHistoryComponent } from './component/reports-history/reports-history.component';


import { ProuserHistoryComponent } from './component/prouser-history/prouser-history.component';
// import { AdminDetailHistoryComponent } from './component/admin-detail-history/admin-detail-history.component';

import { DrmHistoryComponent } from './component/drm-history/drm-history.component';
// import { ManageGameWalletUserDetailComponent } from './component/manage-wallet-user-detail/manage-wallet-user-detail.component';
// import { UserDetailActiveCancleComponent } from './component/user-detail-active-cancle/user-detail-active-cancle.component';
// import { UserDetailInActiveCancleComponent } from './component/user-detail-inactive-cancle/user-detail-inactive-cancle.component';
// import { UserDetailInActiveByForgotCancleComponent } from './component/user-detail-inactiveByforgot-cancle/user-detail-inactiveByforgot-cancle.component';
// import { UserDetailInActiveByAdminCancleComponent } from './component/user-detail-inactiveByadmin-cancle/user-detail-inactiveByadmin-cancle.component';
import { CommentssHistoryComponent } from './component/commentss-history/commentss-history.component';
import { WinLoseHistoryComponent } from './component/win-lose-history/win-lose-history.component';

import { PaymaintanceHistoryComponent } from './component/paymaintance-history/paymaintance-history.component';

// import { BankAccSearchMemberDetailComponent } from './component/bank-acc-search-member-detail/bank-acc-search-member-detail.component';
// const routes: Routes = [
//   {
//     path: '', 
//     redirectTo: 'welcome',
//     pathMatch: 'full'
//   },//amk edit

  const routes: Routes = [
    {
      path: '', 
      redirectTo: 'ad-login',
      pathMatch: 'full'
    },
  {
    path: 'ad-login', component: LoginComponent
  },
  // {
  //   path: 'welcome', component: WelcomeComponent
  // },//amk
  {
    path: 'admin-list', component: AdminComponent, canActivate:[AuthGuard]
  },
  {
    path: 'admin-logs', component: AdminLogsComponent, canActivate:[AuthGuard]
  },
  {
    path: 'game-transaction-list', component: GameTransactionComponent, canActivate:[AuthGuard]
  },
  {
    path: 'game-transactions-list', component: GameTransactionsComponent, canActivate:[AuthGuard]
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard]
  },
  {
    path: 'admin-details', component: AdminDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'admin-details/:id', component: AdminDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'admin-detail', component: AdminDetailHistorysComponent, canActivate:[AuthGuard]
  },
  {
    path: 'admin-detail/:id', component: AdminDetailHistorysComponent, canActivate:[AuthGuard]
  },
  {
    path: 'agent-list', component: AgentComponent, canActivate:[AuthGuard]
  },
  {
    path: 'agent-detail', component: AgentDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'agent-detail/:id', component: AgentDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'user-list', component: UserComponent, canActivate:[AuthGuard]
  },
  {
    path: 'skmlockuserlist', component: SMKLockUserListComponent, canActivate:[AuthGuard]
  },  
   {
    path: 'user-active-list', component: UserActivInactiveComponent, canActivate:[AuthGuard]
  },
  {
    path: 'user-inactive-list', component: UserInactiveComponent, canActivate:[AuthGuard]
  },
  {
    path: 'search-member-list', component: SearchMemberComponent, canActivate:[AuthGuard]
  },
  {
    path: 'search-member-list/:type', component: SearchMemberComponent, canActivate:[AuthGuard]
  },
  {
    path: 'user-detail/:id', component: UserDetailComponent, canActivate:[AuthGuard]
  },
  // {
  //   path: 'user-detail/:id/type', component: UserDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'feedback-user-detail-cancel/:id', component: FeedBackUserDetailCancelComponent, canActivate:[AuthGuard]
  // },
  
  // {
  //   path: 'first-topup-user-detail/:id', component: FirstTopupUserDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'topup-cancel-detail/:id', component: TopupCancelDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'user-forgotpassword-detail-cancel/:id', component: UserForgotPasswordDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'user-otp-detail/:id', component: UserOtpDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'user-detail-active-cancle/:id', component: UserDetailActiveCancleComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'user-detail-inactive-cancle/:id', component: UserDetailInActiveCancleComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'user-detail-inactiveByforgot-cancle/:id', component: UserDetailInActiveByForgotCancleComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'user-detail-inactiveByadmin-cancle/:id', component: UserDetailInActiveByAdminCancleComponent, canActivate:[AuthGuard]
  // },
  //   {
  //   path: 'bank-acc-search-member-detail/:id', component: BankAccSearchMemberDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'search-member-detail-list', component: SearchMemberDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'search-member-detail/:id', component: SearchMemberDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'adjust-user-balance-detail/:id', component: AdjustUserBalanceDetailComponent, canActivate:[AuthGuard]
  // },
  {
    path: 'twod-list', component: TwodComponent, canActivate:[AuthGuard]
  },

  {
    path: 'twod-list/:type', component: TwodComponent, canActivate:[AuthGuard]
  },//amk
  {
    path: 'twod-detail', component: TwodDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twod-detail/:id', component: TwodDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-list', component: ThreedComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-list/:type', component: ThreedComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-detail', component: ThreedDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-detail/:id', component: ThreedDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'topup-check-list', component: TopupCheckComponent, canActivate:[AuthGuard]
  },
  {
    path: 'topup-check-list/:id', component: TopupCheckComponent, canActivate:[AuthGuard]
  },

  {
    path: 'detailreports-list', component: DetailReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'topup-check-detail', component: TopupCheckDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'topup-check-detail-update/:id', component: TopupCheckDetailUpdateComponent, canActivate:[AuthGuard]
  },
  {
    path: 'withdraw-check-list', component: WithdrawCheckComponent, canActivate:[AuthGuard]
  },
  {
    path: 'withdraw-check-list/:id', component: WithdrawCheckComponent, canActivate:[AuthGuard]
  },
  {
    path: 'withdraw-check-detail/:id', component: WithdrawCheckDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twodbet-record-list', component: TwodbetRecordComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twodbet-cancel-list', component: TwodbetCancelRecordComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threedbet-record-list', component: ThreedbetRecordComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threedbet-cancel-list', component: ThreedbetCancelListRecordComponent, canActivate:[AuthGuard]
  },
  
  {
    path: 'twod-bet-amount-limitation-list', component: TwodBetAmountLimitationComponent, canActivate:[AuthGuard]
  },
  // {
  //   path: 'twod-bet-amount-limitation-detail', component: TwodBetAmountLimitationDetailComponent, canActivate:[AuthGuard]
  // },
  {
    path: 'threed-bet-amount-limitation-list', component: ThreedBetAmountLimitationComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-bet-amount-limitation-detail', component: ThreedBetAmountLimitationDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-bet-amount-limitation-detail/:id', component: ThreedBetAmountLimitationDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-bet-amount-limitation-detail-update/:id', component: ThreedBetAmountLimitationDetailUpdateComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twod-bet-amount-limitation-detail-update/:id', component: TwodBetAmountLimitationDetailUpdateComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twod-winners-list', component: TwodWinnersComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-winners-list', component: ThreedWinnersComponent, canActivate:[AuthGuard]
  },
  {
    path: 'changepassword-list', component: ChangepasswordComponent, canActivate:[AuthGuard]
  },
  {
    path: 'changepassword-detail/:id', component: ChangepasswordDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'servicephone-list', component: ServicephoneComponent, canActivate:[AuthGuard]
  },
  {
    path: 'servicephone-detail', component: ServicephoneDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'servicephone-detail/:id', component: ServicephoneDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'promotion-and-news-list', component: PromotionAndNewsComponent, canActivate:[AuthGuard]
  },
  {
    path: 'promotion-and-news-detail', component: PromotionAndNewsDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'promotion-and-news-detail/:id', component: PromotionAndNewsDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'feedback-list', component: FeedbackComponent, canActivate:[AuthGuard]
  },
  {
    path: 'faqsteps-addnew', component: FaqAddnewbackComponent, canActivate:[AuthGuard]
  },
  {
    path: 'faqtype', component: FaqTypebackComponent, canActivate:[AuthGuard]
  },
  {
    path: 'faq-list', component: FaqbackComponent, canActivate:[AuthGuard]
  },
  {
    path: 'faqsteps-list', component: FaqStepbackComponent, canActivate:[AuthGuard]
  },
  {
    path: 'feedback-detail/:id', component: FeedbackDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'holiday-list', component: HolidayComponent, canActivate:[AuthGuard]
  },
  {
    path: 'holiday-detail', component: HolidayDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'holiday-detail/:id', component: HolidayDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-result-configuration-list', component: ThreedResultConfigurationComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-result-configuration-detail', component: ThreedResultConfigurationDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threed-result-configuration-detail/:id', component: ThreedResultConfigurationDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'payment-list', component: PaymentComponent, canActivate:[AuthGuard]
  },
  {
    path: 'payment-detail', component: PaymentDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'payment-detail/:id', component: PaymentDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twodreport', component: TwoReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threedreport', component: ThreeDReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threeddetailsreport', component: ThreeDDetailsReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threedwinnerlistreport', component: ThreedWinnerListReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threeddetailwinnerlistreport', component: ThreeddetailWinnerListReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twodwinnerlistreport', component: TwodWinnerListReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twoddetailwinnerlistreport', component: TwoddetailWinnerListReportComponent, canActivate:[AuthGuard]
  },  
  {
    path: 'userstatementsreport', component: UserStatementsReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'notification', component: NotificationListComponent, canActivate:[AuthGuard]
  },
  {
    path: 'notification-detail', component: NotificationsComponent, canActivate:[AuthGuard]
  },
  {
    path: 'notification-detail/:id', component: NotificationsComponent, canActivate:[AuthGuard]
  },
  {
     path : 'oddentry',component: OddEntryComponent, canActivate:[AuthGuard]
  },
  {
    path: 'odd-detail', component: OddEntryDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'oddentry-detail/:id', component: OddEntryDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'commission', component: CommissionCalculationComponent, canActivate:[AuthGuard]
  },
  {
    path: 'commission-calculation', component: CommissionCalculationDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'commission-confirmation', component: CommissionConfrimComponent, canActivate:[AuthGuard]
  },
  {
    path: 'agent-withdraw', component: AgentWithdrawCheckComponent, canActivate:[AuthGuard]
  },
  {
    path: 'agent-withdraw-check-detail/:id', component: AgentWithdrawCheckDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'add-new-withdraw', component: AgentWithdrawAddNewComponent, canActivate:[AuthGuard]
  }, //AgentWithdrawCheckNewComponent
 /*{
    path: 'thai2d3d-configuration', component: ConfirguationComponent, canActivate:[AuthGuard]
  },*/   /*need to comment*/
  {
    path: 'confirguation-detail/:id', component: ConfirguationListComponent, canActivate:[AuthGuard]
  },
  {
    path: 'confirguation-detail', component: ConfirguationListComponent, canActivate:[AuthGuard]
  },
  {
    path: 'detailtwodreport', component: TwoDDetailReportComponent, canActivate:[AuthGuard]
  }, 
  {
    path: 'twoddetailsreport', component: TwoDDetailsReportComponent, canActivate:[AuthGuard]
  },   
  {
    path: 'detailthreedreport', component: ThreeDDetailReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'ads-list', component: AdsComponent, canActivate:[AuthGuard]
  },
  {
    path: 'ads-detail/:id', component: AdsDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'ads-detail', component: AdsDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'payment-info', component: PaymentInfoComponent, canActivate:[AuthGuard]
  },
  // {
  //   path: 'payment-info-detail', component: PaymentDetailInfoComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'payment-info-detail/:id', component: PaymentDetailInfoComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'payment-info-detail/:id/:type', component: PaymentDetailInfoComponent, canActivate:[AuthGuard]
  // },
  
  {
    path: 'payment-info-detail', component: PaymentInfoDetailHistoryComponent, canActivate:[AuthGuard]
  },
  {
    path: 'payment-info-detail/:id', component: PaymentInfoDetailHistoryComponent, canActivate:[AuthGuard]
  },
  {
    path: 'payment-info-detail/:id/:type', component: PaymentInfoDetailHistoryComponent, canActivate:[AuthGuard]
  },

  {
    path: 'payment-info-detail-new', component: PaymentInfoNewHistoryComponent, canActivate:[AuthGuard]
  },

  {
    path: 'userfinancialreport', component: FinancialReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'userfinancialdetailreport', component: FinancialDetailReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'gametransactionreport', component: GameTransactionReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'transactionallreport', component: TransactionAllReportComponent, canActivate:[AuthGuard]
  },
  {
    path : 'userregistrationreport', component : UserRegistrationComponent, canActivate:[AuthGuard]
  }, 
  {
    path : 'twod-win-lose-report', component : TwoDWinLoseReportComponent, canActivate:[AuthGuard]
  }, 
  {
    path : 'win-lose-report', component : WinLoseHistoryComponent, canActivate:[AuthGuard]
  }, 
  {
    path : 'threed-win-lose-report', component : ThreeDWinLoseReportComponent, canActivate:[AuthGuard]
  }, 
  
  {
    path: 'promotercommissionreport', component: PromoterCommissionReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'firsttopupreport', component: FirstTopupReportComponent, canActivate:[AuthGuard]
  }, 
  {
    path: 'winnerreportmonthly', component: WinnerMonthlyReportComponent, canActivate:[AuthGuard]
  }, 
  {
    path: 'firsttopupreportdaily', component: FirstTopupReportDailyComponent, canActivate:[AuthGuard]
  }, 
  {
    path: 'bankaccountdetailreport', component: AccountHolderReportComponent, canActivate:[AuthGuard]
  }, 
  {
    path: 'block-bank-acc-list', component: BlockBankAccountComponent, canActivate:[AuthGuard]
  }, 
  {
    path: 'block-bankaccount-detail', component: BlockBankAccountDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twodbethistoryrpt50percent', component: Twod50PercentReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twodbethistoryrpt35percent', component: Twod35PercentReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'twodBetLimitReport', component: TwoBetLimitReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'marquee-detail', component: MarqueeDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'marquee-detail/:id', component: MarqueeDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'customer-service-list', component: CustomerServiceComponent, canActivate:[AuthGuard]
  },
  {
    path: 'customer-service-detail', component: CustomerServiceDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'customer-service-detail/:id', component: CustomerServiceDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'sale-promoter-list', component: SalePromoterComponent, canActivate:[AuthGuard]
  },
  {
    path: 'sale-promoter-detail', component: SalePromoterDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'sale-promoter-detail/:id', component: SalePromoterDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'threedbetlimitreport', component: ThreedBetLimitReportComponent, canActivate:[AuthGuard]
  },
  {
    path: 'game-list', component: GameComponent, canActivate:[AuthGuard]
  },
  {
    path: 'game-detail', component: AWCGameDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'game-detail/:id', component: AWCGameDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'discount-entry', component: DiscountComponent, canActivate:[AuthGuard]
  },
  {
    path: 'discount-detail/:id', component: DiscountDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'gs-game', component: GSGameComponent, canActivate:[AuthGuard]
  },
  {
    path: 'gsgame-detail/:id', component: GSDetailGameComponent, canActivate:[AuthGuard]
  },
  {
    path: 'gsgame-detail', component: GSDetailGameComponent, canActivate:[AuthGuard]
  },
  {
    path: 'promotion-ads-list', component: PromotionAdsComponent, canActivate:[AuthGuard]
  },
  {
    path: 'promotion-ads-detail/:id', component: PromotionAdsDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'promotion-ads-detail', component: PromotionAdsDetailComponent, canActivate:[AuthGuard]
  },
  {
   path :'bankaccountdetailreport-beforeclean', component:AccountHolderBeforeCleanReportComponent,canActivate:[AuthGuard]
  },
  {
    path :'game-provider', component:GSGameProviderComponent,canActivate:[AuthGuard]
   },
   {
    path :'provider-detail/:id', component:GameProviderDetailComponent,canActivate:[AuthGuard]
   },
   {
    path :'transaction-tutorial-detail/:id', component:TransactionTutorialDetailDetailComponent,canActivate:[AuthGuard]
   },
   {
    path :'transaction-tutorial-detail', component:TransactionTutorialDetailDetailComponent,canActivate:[AuthGuard]
   },
   
   {
    path :'game-alert', component:GSGameAlertProviderComponent,canActivate:[AuthGuard]
   },
   {
    path :'game-alert-detail', component:GameAlertDetailComponent,canActivate:[AuthGuard]
   },
   {
    path :'game-alert-detail/:id', component:GameAlertDetailComponent,canActivate:[AuthGuard]
   },
   {
    path :'dream-book', component:DreamBookListComponent,canActivate:[AuthGuard]
   },
   {
    path :'dream-book-add-new', component:DreamBookAddNewComponent,canActivate:[AuthGuard]
   },
   {
    path :'dream-book-detail-update/:id', component:DreamBookUpdateComponent,canActivate:[AuthGuard]
   },
   {
    path :'needhelp', component:NeedHelpComponent,canActivate:[AuthGuard]
   },
   {
    path :'need-help-detail', component:NeedHelpDetailComponent,canActivate:[AuthGuard]
   },
   {
    path :'need-help-detail/:id', component:NeedHelpDetailComponent,canActivate:[AuthGuard]
   },
   {
    path :'withdrawalbankacc-report', component:WithdrawalBankAccReportComponent,canActivate:[AuthGuard]
   },
   {
    path :'marquee-list', component:MarqueeComponent,canActivate:[AuthGuard]
   },
   {
    path :'point-promotion', component:PointPromotionComponent, canActivate:[AuthGuard]
   },
   {
    path :'point-promotion-detail/:id', component: PointPromotionDetailComponent, canActivate:[AuthGuard]
   },
   {
    path :'twod-live-result', component: TwodLiveResultComponent, canActivate:[AuthGuard]
   },
   {
    path :'twod-live-detail', component: TwodLiveDetailComponent, canActivate:[AuthGuard]
   },
   {
    path :'twod-live-detail/:id', component: TwodLiveDetailComponent, canActivate:[AuthGuard]
   },
   {
    path :'point-promotion-users', component: PointPromotionUsersComponent, canActivate:[AuthGuard]
   },
  
  {
   path :'point-promotion-users-detail/:id', component: PointPromotionUserDetilsComponent, canActivate:[AuthGuard]
  },
  {
    path :'confirmed-promotion-users', component: PromotionConfirmedUsersComponent, canActivate:[AuthGuard]
   },
 
   {
     path :'confirmed-promotion-users-detail/:id', component: PromotionConfirmedUsersDetailComponent, canActivate:[AuthGuard]
   },
   {
    path :'v129promotionUsers', component: Version129PointUserListComponent, canActivate:[AuthGuard]
   },
   {
    path :'v129confirmedpromotionUsers', component: Version129ConfirmedPointUserListComponent, canActivate:[AuthGuard]
   },
   {
    path :'useractivityreport', component: UserActivityReportComponent, canActivate:[AuthGuard]
   },
   {
    path :'advertise-team-list', component: AdvertiseTeamListComponent, canActivate:[AuthGuard]
   },
   {
    path :'advertise-team-detail/:id', component: AdvertiseTeamDetailComponent, canActivate:[AuthGuard]
   },
   {
    path :'advertise-team-detail', component: AdvertiseTeamDetailComponent, canActivate:[AuthGuard]
   },
   {
     path: 'twod-winner-comment-list', component: TwodWinnerCommentComponent, canActivate:[AuthGuard]
   },
   {
     path: 'twod-winner-comment-detail/:id/:remark', component: TwodWinnerCommentDetailComponent, canActivate:[AuthGuard]
   },
   {
     path: 'threed-winner-comment-list', component: ThreedWinnerCommentComponent, canActivate:[AuthGuard]
   },
   {
    path: 'firsttopuplist', component: FirstTopupComponent, canActivate:[AuthGuard]
  },
   {
    path: 'ban-list', component: BanListComponent, canActivate:[AuthGuard]
  },
   {
     path: 'threed-winner-comment-detail/:id/:remark', component: ThreedWinnerCommentDetailComponent, canActivate:[AuthGuard]
   },
   {
     path: 'bank-slip-list', component: BankSlipComponent, canActivate:[AuthGuard]
   },
   {
    path: 'bank-slip-list/:type', component: BankSlipComponent, canActivate:[AuthGuard]
  },
   {
     path: 'bank-slip-detail/:id',component: BankSlipDetailComponent,canActivate:[AuthGuard]
   },
   {
     path: 'twod-package-list', component: TwodPackageComponent, canActivate:[AuthGuard]
   },
   {
     path: 'twod-package-detail/:id', component: TwodPackageDetailComponent, canActivate:[AuthGuard]
   },
   {
     path: 'twod-dream-book', component: TwodDreamBookComponent, canActivate:[AuthGuard]
   },
   {
     path: 'twod-dream-book-add-new', component: TwodDreamBookAddNewComponent, canActivate:[AuthGuard]
   },
   {
     path: 'twod-dream-book-detail-update/:id', component: TwodDreamBookUpdateComponent, canActivate:[AuthGuard]
   },
   {
     path: 'twod-section-list', component: TwodSectionComponent,canActivate:[AuthGuard]
   },
   {
    path: 'twod-section-list/:type', component: TwodSectionComponent,canActivate:[AuthGuard]
  },
   {
     path: 'twod-section-detail/:id', component: TwodSectionDetailComponent, canActivate:[AuthGuard]
   },
   {
    path: 'bank-maintenance-topup', component: BankMaintenanceTopupComponent, canActivate:[AuthGuard],
   },
   {
    path: 'bank-maintenance-topup-detail/:id', component: BankMaintenanceTopupDetailComponent, canActivate:[AuthGuard],
   },
   {
    path: 'bank-maintenance-withdraw', component: BankMaintenanceWithdrawComponent, canActivate:[AuthGuard],
   },
   {
    path: 'bank-maintenance-withdraw-detail/:id', component: BankMaintenanceWithdrawDetailComponent, canActivate:[AuthGuard],
   },
   {
    path: 'servicephone-sort', component: ServicephoneSortComponent, canActivate:[AuthGuard],
   },
   {
    path: 'game-provider-sort', component: GameProviderSortComponent, canActivate:[AuthGuard],
   },
   {
    path: 'twod-manual-detail', component: TwoDManualDetailComponent, canActivate:[AuthGuard]
   },
   {
    path: 'twod-manual-onoff', component: TwoDManualOnOffComponent, canActivate:[AuthGuard]
   },
   {
    path: 'user-bet-limit', component: UserBetLimitComponent, canActivate:[AuthGuard]
   }
   ,
   {
    path :'configure-userbalance',component:AdjustUserBalanceComponent,canActivate:[AuthGuard]
   },
   {
    path :'withdrawal-action',component:UsersWithdrawalComponent,canActivate:[AuthGuard]
   },
   {
    path :'topup-action',component:UsersTopupComponent,canActivate:[AuthGuard]
   },
   {
    path :'action-logs/:id',component:TopupWithdrawLogsComponent,canActivate:[AuthGuard]
   },
   {
    path :'action-logs',component:TopupWithdrawLogsComponent,canActivate:[AuthGuard]
   },
   {
    path :'configure-twodbetlimit',component:ConfigureUserBetlimitComponent,canActivate:[AuthGuard]
   },
   {
    path :'configure-threedbetlimit',component:ConfigureUser3DBetlimitComponent,canActivate:[AuthGuard]
   },
   {
    path :'configure-threedbetlimit/:type',component:ConfigureUser3DBetlimitComponent,canActivate:[AuthGuard]
   },
   {
    path: 'twoDHistoryLog', component: TwoDHistoryLogComponent, canActivate:[AuthGuard]
   },
   {
    path: 'twoDHistoryLog/:objectId', component: TwoDHistoryLogComponent, canActivate:[AuthGuard]
   },
   {
    path: 'game-maintain-all', component:GameMaintainAllComponent, canActivate:[AuthGuard]
   },
   {
    path: 'user-otps', component:UserOtpsComponent, canActivate:[AuthGuard]
   },
   {
    path: 'manage-wallets', component:ManageWalletsComponent, canActivate:[AuthGuard]
   },
   {
    path: 'user-game-walletlogs', component:UserGameWalletlogsComponent, canActivate:[AuthGuard]
   },
   {
    path: 'manage-wallets-provider-detail/:id', component:ManageWalletsProviderDetailComponent, canActivate:[AuthGuard]
   },
   {
    path: 'modern-internet', component:ModernInternetComponent, canActivate:[AuthGuard]
   },
   {
    path: 'first_topup_promotionuser_report', component:FirstTopupPromotionUserReportComponent, canActivate:[AuthGuard]
   },
   {
    path: 'game-username', component:GameUsernameComponent, canActivate:[AuthGuard]
   },
   {
    path: 'winner-input/:type', component:WinnerInputComponent, canActivate:[AuthGuard]
   },
   {
    path: 'blacklist/:type', component:BlackListComponent, canActivate:[AuthGuard]
   },
   {
    path: 'winner-input-detail/:type/:id', component:WinnerInputDetailComponent, canActivate:[AuthGuard]
   },
   {
    path: 'risky-list', component:RiskyComponent, canActivate:[AuthGuard]
   },
   {
    path: 'bankAcc-list', component:BankAccComponent, canActivate:[AuthGuard]
   },
   {
    path: 'smsoperator-list', component:SmsOperatorComponent, canActivate:[AuthGuard]
   },
   {
    path: 'lockgscapis', component:LockgscapisComponent, canActivate:[AuthGuard]
   },
   {
    path: 'lockgscapis-detail', component:LockgscapisDetailComponent, canActivate:[AuthGuard]
   },
   {
    path: 'lockgscapis-detail/:id', component: LockgscapisDetailComponent, canActivate:[AuthGuard]
  },
  {
    path: 'winner-input-detail/:id', component: WinnerInputDetailComponent, canActivate:[AuthGuard]
  },
  // {
  //   path: 'roles', component: UserRoleComponent, canActivate:[AuthGuard]
  // },
  {
    path: 'user-role', component: UserRoleComponent, canActivate:[AuthGuard]
  },
  // {
  //   path: 'permession-access/:id/:roleName', component: PermessionAccessComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path: 'permession-access', component: PermessionAccessComponent, canActivate:[AuthGuard]
  // },

  {
    path: 'permession-access', component: PermissionAccessComponent, canActivate:[AuthGuard]
  },
  {
    path: 'permession-access/:id/:roleName', component: PermissionAccessComponent, canActivate:[AuthGuard]
  },

  // {
  //   path :'manage-wallet-user-detail/:id', component: ManageGameWalletUserDetailComponent, canActivate:[AuthGuard]
  // },
  {
    path :'3D-live-list', component: ThreeDLiveListComponent, canActivate:[AuthGuard]
  },
  {
    path :'transactiontutorial', component: TransactionTutorialListComponent, canActivate:[AuthGuard]
  },
  
  // {
  //   path :'manage-wallet-user-detail/:id', component: ManageGameWalletUserDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path :'bet-history-cancel-detail/:id', component: BetHistoryDetailCancelComponent, canActivate:[AuthGuard]
  // },
  
  // {
  //   path :'configure-2D-bet-limit-cancel-detail/:id', component: Configure2DBetLimitCancelDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path :'configure-3D-bet-limit-cancel-detail/:id', component: Configure3DBetLimitCancelDetailComponent, canActivate:[AuthGuard]
  // },
  
  // {
  //   path :'winner-confirm-cancel-detail/:id', component: WinnerConfirmCancelDetailComponent, canActivate:[AuthGuard]
  // },  
  // {
  //   path :'game-transaction-cash-cancel-detail/:id', component: GameTransactionCashDetailCancelComponent, canActivate:[AuthGuard]
  // },
  
  // {
  //   path :'withdraw-cancel-detail/:id', component: WithdrawCancelDetailComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path :'manage-wallet-log-cancel/:id', component: ManageWalletLogCancelComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path :'manage-withdraw-cancel/:id', component: ManageWithdrawCancelComponent, canActivate:[AuthGuard]
  // },
  // {
  //   path :'manage-topup-cancel/:id', component: ManageTopupCancelComponent, canActivate:[AuthGuard]
  // },
  {
    path :'user-detail-active/:id', component: UserDetailActiveComponent, canActivate:[AuthGuard]
  },
  {
    path :'user-detail-inactive/:id', component: UserDetailInactiveComponent, canActivate:[AuthGuard]
  },

  




  {
    path: 'bethistory',
      component: BetHistoryComponent,
      children: [
        { path: 'twod', component: TwodbetRecordComponent },  
        { path: 'twodcancel', component: TwodbetCancelRecordComponent },       
        { path: 'threed', component: ThreedbetRecordComponent },
        { path: 'threedcancel', component: ThreedbetCancelListRecordComponent },        
        { path: '', redirectTo: 'twod', pathMatch: 'full' },
      ],
    },

    {
      path: 'paymentsshistory',
        component: PaymaintanceHistoryComponent,
        children: [
          { path: 'twod', component: BankMaintenanceTopupComponent },
          { path: 'threed', component: BankMaintenanceWithdrawComponent },
          { path: '', redirectTo: 'twod', pathMatch: 'full' },
        ],
      },
      {
        path: 'winhistory',
          component: WinHistoryComponent,
          children: [
            { path: 'twod', component: TwodWinnersComponent },
            { path: 'twod/:detail', component: TwodWinnersComponent },
            { path: 'threed', component: ThreedWinnersComponent },          
            { path: '', redirectTo: 'twod', pathMatch: 'full' },
          ],
        },     
  {
    path: 'resulthistory',
      component: ResultHistoryComponent,
      children: [
        { path: 'twod', component: TwodComponent },
        { path: 'threed', component: ThreedComponent },
        { path: '', redirectTo: 'twod', pathMatch: 'full' },
      ],
    },
    {
      path: 'feedbackandfaq',
        component: FeedBackHistoryComponent,
        children: [
          { path: 'feedback-list', component: FeedbackComponent },
          { path: 'faqtype', component: FaqTypebackComponent },
          { path: 'faq-list', component: FaqbackComponent },
          { path: '', redirectTo: 'feedback-list', pathMatch: 'full' },
        ],
      },
      {
        path: 'feedbackandfaqs',
          component: FeedBackHistoryCopyComponent,
          children: [
            { path: 'faqsteps-addnew', component: FaqAddnewbackComponent },            
            { path: 'faqsteps-list', component: FaqStepbackComponent },
            { path: '', redirectTo: 'faqsteps-addnew', pathMatch: 'full' },
          ],
        },
    {
      path: 'confighistory',
        component: ConfigHistoryComponent,
        children: [
          { path: 'twod', component: TwodSectionComponent },
          { path: 'threed', component: ThreedResultConfigurationComponent },
          { path: '', redirectTo: 'twod', pathMatch: 'full' },
        ],
      },

      {
        path: 'commentsshistory',
          component: CommentssHistoryComponent,
          children: [
            { path: 'twod', component: TwodWinnerCommentComponent },
            { path: 'threed', component: ThreedWinnerCommentComponent },
            { path: 'ban', component: BanListComponent },
            { path: '', redirectTo: 'twod', pathMatch: 'full' },
          ],
        },

        {
          path: 'win-lose-report',
            component: WinLoseHistoryComponent,
            children: [
              { path: 'twod', component: TwoDWinLoseReportComponent },
              { path: 'threed', component: ThreeDWinLoseReportComponent },      
              { path: '', redirectTo: 'twod', pathMatch: 'full' },
            ],
          },

      

      {
        path: 'oddshistory',
          component: OddsHistoryComponent,
          children: [
            { path: 'twod', component: OddEntryComponent },
           // { path: 'threed', component: ThreedResultConfigurationComponent },
            { path: '', redirectTo: 'twod', pathMatch: 'full' },
          ],
        },


        
          {
            path: 'reportshistory',
              component: ReportsHistoryComponent,
              children: [
                { path: 'twod', component: TwodWinnerListReportComponent },
                { path: 'threed', component: ThreedWinnerListReportComponent },
                { path: '', redirectTo: 'twod', pathMatch: 'full' },
              ],
            },
        
            {
              path: 'prouserhistory',
                component: ProuserHistoryComponent,
                children: [
                  { path: 'twod', component:  PointPromotionUsersComponent },  
                  { path: 'threed', component: PromotionConfirmedUsersComponent  },                
                  { path: '', redirectTo: 'twod', pathMatch: 'full' },
                  
                ],
            },
            
            {
              path: 'drmhistory',
                component: DrmHistoryComponent,
                children: [
                  { path: 'twod', component:  TwodDreamBookComponent },               
                  { path: 'threed', component: DreamBookListComponent },           
                  { path: '', redirectTo: 'twod', pathMatch: 'full' },
                ],
            },

            
 

      {
        path: 'detailshistory',
          component: DetailsHistoryComponent,
          children: [
            { path: 'active', component: UserActivInactiveComponent },
            { path: 'inactive', component: UserInactiveComponent },
            { path: 'inactive-user-byadmin', component: UserInactiveByAdminComponent },  
            { path: 'inactivebyforgot', component: UserInactiveByForgotComponent },           
            { path: '', redirectTo: 'twod', pathMatch: 'full' },
          ],
        },

   

          

           

   {
    path: '**', component: PageNotfoundComponent
   },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
