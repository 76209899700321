import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { Subject } from 'rxjs';

declare var $: any;


@Component({
  selector: 'app-point-promotion',
  templateUrl: './point-promotion.component.html',
  styleUrls: ['./point-promotion.component.css']
})
export class PointPromotionComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  pointPromotionModel : any;
  
  token : any;

  
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,) 
    {    
      this.storage.store('isNotiSong', "");
      this.hardcode_view = "PointPromotion_View";
      this.hardcode_edit = "PointPromotion_Edit";  


      
      this.dto.menuCodes = this.storage.retrieve('menucodes');  
      const filteredMenuCodes_view = [];   
      const filteredMenuCodeds_edit =[];
   
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }      
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }      
        
      });
      if(filteredMenuCodes_view.length != 0)
      {
            this.hardcode_view_bool = true;
      }   
      if(filteredMenuCodeds_edit.length != 0)
      {
            this.hardcode_edit_bool = true;
      }   

    }

  ngOnInit(): void {
    this.getPointPercentage();
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
   
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }

    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  getPointPercentage()
  {
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      this.http.get(this.funct.ipaddress + 'point-promotion/GetAllPointPercentage', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => 
        {
          this.dto.Response = {};
          this.dto.Response = result;
          this.pointPromotionModel = this.dto.Response;
          this.dtTrigger.next();
        });
   }


   addStepAndNavigate(pointPromotionModel: any): void
   { 
    localStorage.setItem('pointPromotionModelData', JSON.stringify({
      id:pointPromotionModel.id,
      userType:pointPromotionModel.userType,
      percentage:pointPromotionModel.percentage,
      fromAmount:pointPromotionModel.fromAmount,
      toAmount:pointPromotionModel.toAmount,
      status:pointPromotionModel.status
   
    }));
 
    this.router.navigate(['/point-promotion-detail', pointPromotionModel.id]);
  }
  
   
}
