import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd ,ActivatedRoute} from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment_info.component.html',
  styleUrls: ['./payment_info.component.css']
})
export class PaymentInfoComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtOptions1 : DataTables.Settings = {};
  dtOptions2 : DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2 : Subject<any> = new Subject();
  dtTrigger1 : Subject<any> = new Subject();

  accountNo: string = '';
  type: string = '';
  status: string = '';
  BankType: string = null;
  paymentList: any;
  bankTypeList: any;
  idIndex: any;
  idIndex2 : any;
  token: any;
  bankName : any;
  account_no : any;
  code_number : any;
  account_name : any;
  paymentBankAccountHolderDetaiList : any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_add_Account_Number:any;
  hardcode_add_Account_Name:any;
  hardcode_add_Code_Number:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  paymentList1:any;
  idIndex1:any;
  config:any;
  persentage :any;
  persentage_data:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService,
    private router: Router, private funct: FunctService, private toastr: ToastrService,private route: ActivatedRoute) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.idIndex1 = 1;
    this.idIndex2 = 1;
    this.config = "0.00";
    this.persentage =" %";

    this.hardcode_view = "BankAccount_View";
    this.hardcode_edit = "BankAccount_Edit";
    this.hardcode_add = "BankAccount_Add";  
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => 
      {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodes_view.length != 0)
    {
         this.hardcode_view_bool = true;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
         this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
         this.hardcode_edit_bool = true;
    }
    this.route.queryParams.subscribe(queryParams => 
    {
      this.type = queryParams['type'];
    });
    if(this.type == "" || this.type == undefined)
    {
       this.type ="TOPUP";
    }

    // this.type="TOPUP";
    //this.getBankTypeList();

      if(this.type == 'TOPUP')
      {
        this.getBankTypeList();      
      }
      else
      {
        this.getAllBankTypeWithdraw();
      }
     this.search();    
  }

  ngOnInit(): void 
  {
    // this.route.queryParams.subscribe(queryParams => 
    //   {
    //   this.type = queryParams['type'];
    // });
    // if(this.type == "" || this.type == undefined)
    // {
    //    this.type ="TOPUP";
    // } 
    //this.search();
 
    // if(this.dto.persentage == undefined || this.dto.persentage ==null || this.dto.persentage =="")
    // {
    //   this.route.queryParams.subscribe(params => 
    //     {
    //     const response = params['response'];
    //     if (response)
    //      {
  
    //       this.config = JSON.parse(response);
        
    //     }
        
    //   });
    // }
    // else
    // {
    //   this.config = this.dto.persentage;
    // }

    this.dto.persentage = this.storage.retrieve('persentage');
    this.config = this.dto.persentage;
   


    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
       
        this.router.navigated = false;
     
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: []
    }

    this.dtOptions2 = {
      responsive: true,
      order: []
    }
    this.dtOptions.columnDefs = [
    ];

    if(this.type =="TOPUP")
    {
      if (!this.storage.retrieve('loadFlag')) 
      {
        this.storage.store('loadFlag', 'noLoad');
        setTimeout(function () {
          location.reload();
        }, 5);
      }
      else {
        this.storage.clear('loadFlag');
      }
    }
    else
    {
      if (!this.storage.retrieve('loadFlag')) 
      {
        this.storage.store('loadFlag', 'noLoad');
        setTimeout(function () {
          location.reload();
        }, 3);
      }
      else {
        this.storage.clear('loadFlag');
      }
    }

    //this.search();  
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblpayment';
      $('#tblpayment').dataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }

    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search() 
  {
    if (this.dtTrigger.isStopped) 
    {
      this.dtTrigger = new Subject<any>();
    }
    this.paymentList = [];
    var id = 'tblpayment';
    //var table = $('#' + id).DataTable();
    this.idIndex = this.idIndex + 1;
    $('#tblpayment').dataTable().fnClearTable();
    $('#tblpayment').dataTable().fnDestroy();
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('accountNo', this.accountNo).set('status', this.status).set('bankType', this.BankType).set('Type',this.type);
    this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
    .pipe(//GetPaymentDetailListPyParams
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList = this.dto.Response;       
        // this.dtTrigger.next();
        this.spinner.hide();
        if (this.paymentList && this.paymentList.length) {
          this.dtTrigger.next(); 
        }
      });
  }

  searchForWithdrawal() 
  {
    this.paymentList1 = [];
    var id = 'tblpayment1';
    this.idIndex1 = this.idIndex1 + 1;
    $('#tblpayment1').dataTable().fnClearTable();
    $('#tblpayment1').dataTable().fnDestroy();
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('accountNo', this.accountNo).set('status', this.status).set('bankType', this.BankType).set('Type',this.type);
    this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList1 = this.dto.Response;       
        this.dtTrigger1.next();
        this.spinner.hide();
      });
  }

  ngOnDestroy(): void 
  {
    this.dtTrigger.unsubscribe();
  }

  goModal(id,  accountNo, bankName, codeNumber , accountName){
    var id1 = 'paymentBankAccountHolderDetaiList' + this.idIndex2;
    var table = $('#' + id1).DataTable();
    table.destroy();
    this.idIndex2 = this.idIndex2 +1;
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    this.paymentBankAccountHolderDetaiList = [];
     params = params.set('paymentDetailId', id);
    this.http.get(this.funct.ipaddress + 'payment/GetAccountHolderDetaiList', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentBankAccountHolderDetaiList = this.dto.Response;
        this.account_no = accountNo;
        this.bankName = bankName;
        this.code_number = codeNumber;
        this.account_name = accountName;
        console.log("AKP: " +JSON.stringify(this.paymentBankAccountHolderDetaiList));
        this.dtTrigger2.next();
      }
    ); 
    this.spinner.hide();
    $('#browseAccountHolderList').modal("show");
  }

  getBankTypeList() {
    this.bankTypeList = [];
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.http.get(this.funct.ipaddress + 'payment/GetBankTypeList', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankTypeList = this.dto.Response;
        this.spinner.hide();
      });
  }

  addStepAndNavigate(payment: any): void
  { 
   localStorage.setItem('paymentData', JSON.stringify({
    id:payment.id,
    status:payment.status,
    min_amount:payment.min_amount,
    paymentId:payment.paymentId,
    account_no:payment.account_no,
    account_name:payment.account_name,
    code_number:payment.code_number,
    max_amount:payment.max_amount,
    limited_amt:payment.limited_amt,
    maxAccountCount:payment.maxAccountCount,
    type :payment.type

   }));
  this.router.navigate(['/payment-info-detail', payment.id,payment.type]);
 }

 onChangeType()
 {    
        if(this.type == 'TOPUP')
        {
          this.getBankTypeList();      
        }
        else
        {
          this.getAllBankTypeWithdraw();
          this.type ="WITHDRAWAL";
        }
        this.router.navigate(['/payment-info'] , { queryParams: { type: this.type } }).then(() => 
      {

      });
      this.search();    
 }

 getAllBankTypeWithdraw()
  {
    this.bankTypeList = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token );
    this.http.get(this.funct.ipaddress + 'payment/GetWithdrawBankTypeList', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankTypeList = this.dto.Response;
        this.spinner.hide();
      }
    );
  }

  readNotiData()
  {    
    $("#readData").modal("show");
  }
  closeTran()
  {
      $('#readData').modal("hide");
      this.router.navigate(['/payment-info']).then(() => 
      {      
    });
  }
  
readNoti()
{
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const formData = new FormData();
    formData.append("code",'bank_amount_reminder_percentage');   
    formData.append("value",this.persentage_data);  
    this.http.post(this.funct.ipaddress + 'SysParam/setvalue', formData, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response == true)
        {   
          $('#readData').modal("hide"); 
          this.getPersentage(); 
          this.persentage_data ="";
            // this.router.navigate(['/payment-info'], 
            // {
            // })
        }
       
      });
}



getPersentage() 
{    
 
  this.dto.token = this.storage.retrieve('token');
  if (!this.dto.token) {
    console.error('Token is missing. Cannot make the request.');
    return;
  }  
  let headers = new HttpHeaders().set('Authorization', `Bearer ${this.dto.token}`);
  let params = new HttpParams().set('code', 'bank_amount_reminder_percentage');
  this.http.get(this.funct.ipaddress + 'SysParam/getvalue', { params: params, headers: headers }) 
    .pipe(
      catchError((error) => 
      {
        console.error('Request failed with error:', error);
        return this.handleError(error); 
      })
    )
    .subscribe(
      result => 
      {        
        this.dto.Response = result;
        this.dto.persentage = result;
        this.storage.store('persentage', this.dto.persentage);
        this.config = this.dto.persentage;
      
      }
      
    );
}



}
