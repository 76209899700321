<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>

                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Bank Maintenance Withdraw Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                                <div class="row">
                                                    <label class="col-md-2"> Bank Name</label>
                                                    <div class="form-group col-md-3">
                                                        <input class="form-control form-control-sm"
                                                            [(ngModel)]="bankDTO.bank_type"
                                                            [ngModelOptions]="{standalone: true}" [readonly]="addOrEdit" >
                                                    </div>
                                                </div>
        
        
                                                <div class="row">
                                                    <label class="col-md-2">Description (English)</label>
                                                    <div class="form-group col-md-3">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="bankDTO.description_en"
                                                            [ngModelOptions]="{standalone: true}"></textarea>
                                                    </div>
                                                     <div class = "col-md-1"></div>
                                                    <label class="col-md-2"> Description (Burmese)</label>
                                                    <div class="form-group col-md-3">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="bankDTO.description_my"
                                                            [ngModelOptions]="{standalone: true}"></textarea>
                                                    </div>
                                                </div>


                                                <div class="row">
                                                    <label class="col-md-2"> Description (Thailand)</label>
                                                    <div class="form-group col-md-3">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="bankDTO.description_th"
                                                            [ngModelOptions]="{standalone: true}"></textarea>
                                                    </div>
                                                    <div class = "col-md-1"></div>
                                                    <label class="col-md-2"> Description (Chinese)</label>
                                                    <div class="form-group col-md-3">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="bankDTO.description_zh"
                                                            [ngModelOptions]="{standalone: true}"></textarea>
                                                    </div>
                                                </div>                                     
        
                                                <div class="row" style="display: none;">
                                                    <label class="col-md-2"> bank Code </label>
                                                    <div class="form-group col-md-3">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="bankDTO.bankCode"
                                                            [ngModelOptions]="{standalone: true}"></textarea>
                                                    </div>
                                                    <div class = "col-md-1"></div>
                                                    <label class="col-md-2"> Description (Chinese)</label>
                                                    <div class="form-group col-md-3">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="bankDTO.description_zh"
                                                            [ngModelOptions]="{standalone: true}"></textarea>
                                                    </div>
                                                </div> 
                                        <div class="row">
                                            <label class="col-md-2"> Is Maintenance </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" checked id="radioSuccess1"
                                                            [value]="true" [(ngModel)]="bankDTO.is_maintain"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" id="radioSuccess2"
                                                            [value]="false" [(ngModel)]="bankDTO.is_maintain"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div _ngcontent-dxs-c175="" class="row">
                                            <label _ngcontent-dxs-c175=""
                                                class="col-md-2"> From Date</label>
                                            <div _ngcontent-dxs-c175="" class="form-group col-md-3"><input
                                                    _ngcontent-dxs-c175="" type="datetime-local" id="appt" name="appt"                                                   
                                                    class="form-control ng-pristine ng-valid ng-touched"
                                                    [(ngModel)]="bankDTO.fromTime"
                                                    style="width: 210px;">
                                                </div>
                                            <div _ngcontent-dxs-c175="" class="col-md-1">
                                                <!-- min="09:00" max="18:00" required="" -->

                                            </div>
                                            <label
                                                _ngcontent-dxs-c175="" class="col-md-2"> To Date</label>
                                            <div _ngcontent-dxs-c175="" class="form-group col-md-3"><input
                                                    _ngcontent-dxs-c175="" type="datetime-local" id="appt1" name="appt1"
                                                   
                                                    [(ngModel)]="bankDTO.toTime"
                                                    class="form-control ng-pristine ng-valid ng-touched"
                                                    style="width: 210px;"></div>
                                        </div>
                                        <!-- min="09:00" max="18:00" required=""                                         -->


                                        <div _ngcontent-iad-c175="" class="row"><label _ngcontent-iad-c175=""
                                            class="col-md-2"> Notification Option </label>
                                        <div _ngcontent-iad-c175="" class="form-group col-md-3 radio">
                                            <div _ngcontent-iad-c175="" class="form-group clearfix">
                                                <div _ngcontent-iad-c175="" class="icheck-success d-inline">
                                                  
                                                    <input type="radio" name="r6" checked id="radioSuccess3"
                                                        value="true" [(ngModel)]="bankDTO.sendNow"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label for="radioSuccess3">is Now? &nbsp;&nbsp;
                                                    </label>

                                                </div>

                                                <div _ngcontent-iad-c175="" class="icheck-success d-inline">                                                    
                                                    <input type="radio" name="r6" id="radioSuccess4" value="false"
                                                        [(ngModel)]="bankDTO.sendNow"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label for="radioSuccess4">Advance?
                                                    </label>


                                                </div>
                                            </div>
                                        </div>
                                        <div _ngcontent-iad-c175="" class="col-md-1"></div><label
                                            _ngcontent-iad-c175="" class="col-md-2"> Send Noti? </label>
                                        <div _ngcontent-iad-c175="" class="form-group col-md-2 radio">
                                            <div _ngcontent-iad-c175="" class="form-group clearfix">
                                                <div _ngcontent-iad-c175="" class="icheck-success d-inline">                                                    
                                                    <input _ngcontent-gba-c139="" type="radio" name="r5" checked=""
                                                        id="radioTrue" value="false" [(ngModel)]="bankDTO.NotSend"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label _ngcontent-gba-c139="" for="radioTrue">True &nbsp;&nbsp;
                                                    </label>

                                                </div>
                                                <div _ngcontent-iad-c175="" class="icheck-success d-inline">
                                                   
                                                    <input type="radio" name="r5" id="radioFalse" value="true"
                                                        [(ngModel)]="bankDTO.NotSend"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label _ngcontent-gba-c139="" for="radioFalse">False
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/paymentsshistory/threed']"> Cancel </button>
                                                </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                    
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form>
                                        <div class="row">
                                            <label class="col-md-2"> Created By</label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="bankDTO.created_by"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Created Date</label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="bankDTO.created_date_str"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <label class="col-md-2"> Updated By</label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="bankDTO.updated_by"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Updated Date</label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="bankDTO.updated_date_str"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


