<div class="wrapper">
  <!-- Navbar -->
  <app-header-sidebar></app-header-sidebar>

  <!-- here here here -->
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> User Detail </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <form>
                    <div class="row">
                      <label class="col-md-2"> Profile Picture </label>
                      <div class="form-group col-md-3" *ngIf="isProfile">
                       
                        <img [src]="imgURL" height="150" width="160" *ngIf="imgURL">
                      </div>
                      <div class="form-group col-md-3" *ngIf="!isProfile">
                        <img src="assets/image/userProfile.jpg" height="150" width="160">
                      </div>
                    </div>

                    <!-- <div class="row">
                      <label class="col-md-2"> Profile Picture </label>
                      <div class="form-group col-md-3" *ngIf="isProfile">
                          <img [src]="imgURL ? imgURL : 'assets/image/defaultIcon.jpg'" height="150" width="160">
                      </div>
                      <div class="form-group col-md-3" *ngIf="!isProfile">
                          <img src="assets/image/userProfile.jpg" height="150" width="160">
                      </div>
                  </div> -->
                  

                    <div class="row">

                      <label class="col-md-2"> Name </label>
                      <div class="form-group col-sm-3">
                        <input disabled class="form-control form-control-sm" [(ngModel)]="userDTO.name"
                          [ngModelOptions]="{standalone: true}">
                      </div>

                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Total Topup ( + ) </label>
                      <div class="form-group col-sm-3">
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="userDTO.topup_balance_Str"
                          [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Phone No </label>
                      <div class="form-group col-sm-3">
                        <input class="form-control form-control-sm" [(ngModel)]="userDTO.phone_no"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Win Amount ( + ) </label>
                      <div class="form-group col-sm-3">
                        <input type="string" class="form-control form-control-sm" [(ngModel)]="userDTO.win_amount_Str"
                          [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Agent </label>
                      <div class="form-group col-sm-3">
                        <select (change)="flagAgent()" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="userDTO.agent_name" [ngModelOptions]="{standalone: true}" disabled>
                           <option value={{userDTO.agent_name}}>{{userDTO.agent_name}} 
                        </select>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-sm-2"> Total Withdraw ( - ) </label>
                      <div class="col-sm-3">
                        <input type="string" class="form-control form-control-sm"
                          [(ngModel)]="userDTO.withdraw_balance_Str" [ngModelOptions]="{standalone: true}" step="500"
                          min="0" disabled>
                      </div>
                    </div>

                    <!-- amk -->
                    <div class="row">

                      <label class="col-md-2">  </label>
                      <div class="form-group col-sm-3">
                       
                      </div>

                      <div class="col-md-1"></div>
                      <label class="col-sm-2"> Freeze Amount ( - ) </label>
                      <div class="form-group col-sm-3">
                        <input type="string" class="form-control form-control-sm"
                          [(ngModel)]="userDTO.freeze_balance_Str" [ngModelOptions]="{standalone: true}"
                          step="500" min="0" disabled>
                      </div>
                    </div>


                    <div class="row">
                      <label class="col-sm-2"> Blocked by forgot password? </label>
                      <div class="col-sm-3">
                        <input type="string" class="form-control form-control-sm"
                          value="{{userDTO.blocked_by_forgotpassword}}" step="500" min="0" disabled>
                      </div>

                      <div class="col-md-1"></div>
                      <label class="col-sm-2"> Total Game Withdraw ( + ) </label>
                      <div class="col-sm-3">
                        <input type="string" class="form-control form-control-sm"
                          [(ngModel)]="userDTO.gs_game_withdrawal_balance" [ngModelOptions]="{standalone: true}"
                          step="500" min="0" disabled>
                      </div>

                    </div>
                    <br />
                    <div class="row">

                      <label class="col-md-2"> Status </label>
                      <div class="form-group col-md-3 radio">
                        <div class="form-group clearfix">
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" checked id="radioSuccess1" value="ACTIVE"
                              [(ngModel)]="userDTO.status" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess1">Active &nbsp;&nbsp;
                            </label>
                          </div>
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" id="radioSuccess2" value="INACTIVE"
                              [(ngModel)]="userDTO.status" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess2">Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-sm-2"> Total Game Deposit ( - ) </label>
                      <div class="col-sm-3">
                        <input type="string" class="form-control form-control-sm"
                          [(ngModel)]="userDTO.gs_game_deposit_balance" [ngModelOptions]="{standalone: true}" step="500"
                          min="0" disabled>
                      </div>
                    </div>

                    <div class="row">


                      <label class="col-md-2"> Remark </label>
                      <div class="col-sm-3">
                        <textarea class="form-control form-control-sm" [(ngModel)]="userDTO.description"
                          [ngModelOptions]="{standalone: true}"> </textarea>
                      </div>

                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Total Bet Amount ( - ) </label>
                      <div class="col-sm-3">
                        <input type="string" class="form-control form-control-sm" [(ngModel)]="userDTO.bet_amount_Str"
                          [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                      </div>
                    </div>
                    <br />

                    <div class="row">
                      <div class="col-md-6"></div>
                      <label class="col-md-2"> Total Promotion Wallet ( + ) </label>
                      <div class="col-sm-3">
                        <input type="string" class="form-control form-control-sm"
                          [(ngModel)]="userDTO.total_promotion_balance_Str" [ngModelOptions]="{standalone: true}"
                          step="500" min="0" disabled>
                      </div>
                    </div>




                    <br />
                    <div class="row">
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                      </div>

                      <!-- <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-danger"
                          [routerLink]="['/user-list']">Cancel</button>
                       
                      </div> -->

                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-danger"
                        (click)="Cancel()">Cancel</button>
                       
                      </div>

                      <div class="col-md-2"></div>
                      <label class="col-md-2"> Wallet
                        <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-success"
                            [routerLink]="['/action-logs',userDTO.id]">Logs</button>&nbsp;

                        </div>
                      </label>
                      <div class="col-sm-3">
                        <input type="string" class="form-control form-control-sm" [(ngModel)]="userDTO.balance_Str"
                          [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                      </div>
                    </div>

                    <div class="row">

                      <div class="col-md-6"></div>
                      <label class="col-md-2"> Actual Wallet(By Formula) </label>
                      <div class="form-group col-sm-3">
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="userDTO.balance_Str"
                          [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                      </div>
                    </div>


                    <div class="row">
                      <label class="col-md-2"></label>
                      <div class="form-group col-md-3">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <form>
                    <div class="row" style="margin-top: 2%;">
                      <label class="col-md-2"> Register Date </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="userDTO.created_date"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>

                      <!--XXXX -- add new -->
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Last Login Date/Time </label>
                      <div class="col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="userDTO.last_used_time_str"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>

                    </div>

                    <div class="row" style="margin-top: 1%;">
                      <label class="col-md-2"> Updated Date </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="userDTO.updated_date"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Updated By </label>
                      <div class="col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="userDTO.updated_by_name"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>

                  </form>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
          </div>
          <!--end of reg date section-->

          <!-- User Game Wallet List-->



          <div class="col-md-12" style="margin-top: 0.5%;">
            <div class="card">
              <div class="card-body">

                <div class="col-md-2 col-sm-6">
                  <button type="button" class="btn btn-block btn-success" (click)="GameBalance()">Game
                    Balance</button>&nbsp;
                </div>

                <table width='100%' id="{{'tblgamewallet' + idgamewallet}}" datatable [dtTrigger]="dtTriggergamewallet"
                  [dtOptions]="dtOptionsgamewallet" class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th style="font-family: unicode;">No</th>
                      <th style="font-family: unicode;">Game Provider</th>
                      <th style="font-family: unicode;">Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr *ngFor="let item of userDTO.gameWalletModel | keyvalue, let i = index"> -->
                    <!-- <tr *ngFor="let item of userDTO | keyvalue, let i = index">
                            
                            <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                            <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{item.key}}
                            </td>
                            <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{item.value}}
                            </td>
                          </tr> -->

                    <tr *ngFor="let item of gameDTO | keyvalue, let i = index">

                      <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                      <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{item.key}}
                      </td>
                      <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;" class="center-text">
                        {{item.value}}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>


          <div class="col-md-12">
            <!--Bank Information-->
            <div class="card">
              <div class="card-header">
                <div>
                  <h3 class="card-title" style="font-weight: bold;">User Bank Account Information</h3>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-4" style="float: right;">
                  <button id="bankInfoView" type="button" class="btn btn-block btn-primary btn-sm"
                    style="margin-top: 4%;" (click)="getUserbankInfo()">View</button>
                </div>
              </div>
              <div class="card-body">
                <table width='100%' id="{{'tblBank' + bankidIndex}}" datatable [dtTrigger]="dtTrigger1"
                  [dtOptions]="dtOptions1" class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th style="font-family: unicode; width:100px" class="text-left">Bank Logo</th>
                      <th style="font-family: unicode; width:100px" class="text-left">QR Code</th>
                      <th style="font-family: unicode; width:100px" class="text-left">Bank Type</th>
                      <th style="font-family: unicode; width:100px" class="text-left">Account Number</th>
                      <th style="font-family: unicode; width:100px" class="text-left">Account Name</th>
                      <th style="font-family: unicode; width:100px" class="text-left">Created Date</th>
                      <th style="font-family: unicode; width:100px" class="text-left">Approved Date</th>
                      <th style="font-family: unicode; width:100px" class="text-left">Approved By</th>
                      <th style="font-family: unicode; width:100px" class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let wallet of tempUserBankInfoDTOList">
                      <td style="font-family: unicode;"><img [src]="wallet.banklogo" height="110" width="110"
                          *ngIf="wallet.banklogo"></td>
                      <td style="font-family: unicode;">
                        <img width="100px" hegith="100px"
                          style="margin-top: 5%; object-fit: cover; border: 3px solid #ffffff; border-radius: 10px; border-color: #ffffff;"
                          [src]="wallet.qrImagelogo">
                      </td>
                      <td style="font-family: unicode;">{{wallet.bank_name}}</td>
                      <td style="font-family: unicode;">{{wallet.account_number}}</td>
                      <td style="font-family: unicode;">{{wallet.account_name}}</td>
                      <td style="font-family: unicode;">{{wallet.created_date | date: 'dd/MM/yyyy HH:mm:ss'}}</td> 
                      <td style="font-family: unicode;">{{wallet.updated_date | date: 'dd/MM/yyyy HH:mm:ss'}}</td>                      
                      <td style="font-family: unicode;">{{wallet.updated_by_name}}</td>

                      <td style="font-family: unicode;" class="text-center" class="ng-star-inserted">

                        <a (click)="deleteBankAcc(wallet.userid,wallet.payment_id,wallet.bank_acc_id, wallet.account_number , wallet.bank_name,wallet.user_account_no)">
                          <i class="fa fa-trash" style="color: red;">
                          </i>
                        </a>

                      </td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>



        </form>


        <form>
          <div class="row">
            <div class="col-md-12">

              <ul class="nav nav-tabs nav-pills">
                <li (click)="getTransactionAllByParams(userDTO.created_date)">
                  <a class="active" id="all-tab" data-toggle="tab" href="#transactionall"> Transaction ALL </a>
                </li>
                <li (click)="getUserBalances(userDTO.id)">
                  <a id="added-tab" data-toggle="tab" href="#userbalances">Balance Comparison</a>

                </li>
                <li (click)="getTwodbetHistoryByParams()">
                  <a id="added-tab" data-toggle="tab" href="#twodbethistory">2D Bet History</a>
                </li>
                <li (click)="getTwoDwinnerList()">
                  <a id="pending-tab" data-toggle="tab" href="#twodwin"> 2D Winning </a>
                </li>
                <li (click)="getTopupList()">
                  <a id="approve-tab" data-toggle="tab" href="#topup"> Top UP </a>
                </li>
                <li (click)="getWithdrawalList()">
                  <a id="denied-tab" data-toggle="tab" href="#withdrawal"> Withdrawal </a>
                </li>
                <li (click)="getThreedbetHistoryByParams()">
                  <a id="denied-tab" data-toggle="tab" href="#threedbethistory"> 3D Bet History </a>
                </li>
                <li (click)="getThreeDwinnerList()">
                  <a id="denied-tab" data-toggle="tab" href="#threedwin"> 3D Winning </a>
                </li>

                <li (click)="getQueenMakerTransactionHistory()">
                  <a id="denied-tab" data-toggle="tab" href="#gametransactionhistory"> Game Transaction History </a>
                </li>

                <li (click)="getQueenMakerTransactionHistory()" style="display: none;">
                  <a id="denied-tab" data-toggle="tab" href="#cq9transactionhistory"> CQ9 Transaction History </a>
                </li>

                <li (click)="getGameBalanceLog()">
                  <a id="denied-tab" data-toggle="tab" href="#gamewinlose"> Game Win/Lose </a>
                </li>
                <li (click)="getUserById()" style="display: none;">
                  <a id="denied-tab" data-toggle="tab" href="#bonus"> Bonus </a>
                </li>
                <li >
                  <a id="denied-tab" data-toggle="tab" href="#adjust"> Adjust </a>
                </li>
              </ul>

              <!-- Tab panes -->
              <div class="tab-content clearfix">

                <div class="tab-pane show active" id="transactionall">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="amount"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Wallet Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="wallet"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceSingle" [value]="singleDate" [(ngModel)]="alldate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeSingle()"
                        style="pointer-events: none;">
                      <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="alltodate" class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceSingle1" [value]="alltoDate" [(ngModel)]="alltodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeAllToDate()">
                      <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
                    </div>
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getTransactionAllByParams(userDTO.created_date)">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;"> Transaction All </h3>
                        </div>

                        <div class="card-body">
                          <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode; width:120px" class="text-center">Cash In</th>
                                <th style="font-family: unicode; width:120px" class="text-center">Cash Out</th>
                                <th style="font-family: unicode; width:130px" class="text-center">Amount</th>
                                <th style="font-family: unicode; width: 130px;" class="text-center">Wallet or Balance
                                </th>
                                <th style="font-family: unicode; width: 130px;" class="text-center">Is Fixed By Admin?
                                </th>
                                
                                <th style="font-family: unicode;" class="text-center">Created Date</th>
                                <th style="font-family: unicode;" class="text-center">Updated Date</th>
                                <th style="font-family: unicode;" class="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of walletHistoryDTOLit">
                                <!-- (click)="getDetail(wallet.id)" -->
                                <td style="font-family: unicode;" class="text-center">
                                  <button type="button" class="center-button" *ngIf="wallet.cashIn == 'Topup' && (wallet.isInsertByAdmin == '0' || wallet.isInsertByAdmin == null )"
                                  (click)="getDetail(wallet.id)" class="btn btn-block btn-success" style="
                                                width: 50px; 
                                                padding: 1px 0px 1px 0px; 
                                                font-size: 12px; 
                                                border-radius: 90px; 
                                                color: white; 
                                                background-color: green; 
                                                border: 2px solid white; 
                                            " class="text-center"> Top Up </button>

                                <button type="button" class="center-button" *ngIf="wallet.cashIn == 'Topup' && wallet.isInsertByAdmin == '1'"
                                (click)="getDetail(wallet.id)" class="btn btn-block btn-success" style="
                                              width: 50px; 
                                              padding: 1px 0px 1px 0px; 
                                              font-size: 12px; 
                                              border-radius: 90px; 
                                              color: white; 
                                              background-color: green; 
                                              border: 2px solid white; 
                                          " class="text-center"> Adjust </button>

                                  <!-- (click)="getDetail(wallet.id)" -->
                                  <button type="button" *ngIf="wallet.cashIn == 'Adjust'" (click)="getGameTranDetail(wallet.id,wallet.source)"
                                    class="btn btn-block btn-success"
                                    style="width : 50px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white;background-color: green;  border: 2px solid white; "
                                    class="text-center"> Adjust </button>


                                  <button type="button" *ngIf="wallet.cashIn == '2D Win'"
                                    (click)="getTwodWinnerDetail(wallet.id)" class="btn btn-block btn-warning" style="
                                        width: 50px; 
                                        padding: 1px 0px 1px 0px; 
                                        font-size: 12px; 
                                        border-radius: 90px; 
                                        color: white;
                                        background-color: orange;
                                        border: 2px solid white; 
                                        text-align: center; 
                                      " class="text-center"> 2D Win
                                  </button>

                                  <button type="button" *ngIf="wallet.cashIn == '3D Win'"
                                    (click)="getThreedWinnerDetail(wallet.id)" class="btn btn-block btn-warning"
                                    style=" width : 50px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white; background-color: orange; border: 2px solid white;"
                                    class="text-center"> 3D Win </button>

                                  <button type="button" *ngIf="wallet.cashIn == 'Point'"
                                    (click)="getPointDetail(wallet.id)" class="btn btn-block btn-success"
                                    style=" width : 50px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white;background-color: green;"
                                    class="text-center"> Point </button>

                                    <!-- *ngIf="wallet.cashIn == 'Game' -->
                                  <button type="button" *ngIf="wallet.cashIn == 'Game' && (wallet.isInsertByAdmin == '0' ||  wallet.isInsertByAdmin == null)"
                                    (click)="getGameTranDetail(wallet.id,wallet.source)" class="btn btn-block btn-success"
                                    style=" width : 100px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white;background-color: green; border: 2px solid white;"
                                    class="text-center"> Game </button>

                                    <!-- *ngIf="wallet.cashIn == 'Adjusts'" -->
                                  <button type="button"  *ngIf="wallet.cashIn == 'Game' && wallet.isInsertByAdmin == '1'"
                                    (click)="getGameTranDetail(wallet.id,wallet.source)" class="btn btn-block btn-success"
                                    style="width : 100px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white;background-color: green;"
                                    class="text-center"> Adjust </button>
                                    <!-- Adjust Game  -->

                                </td>

                                <td style="font-family: unicode;" class="text-center">
                                  <button type="button" *ngIf="wallet.cashOut == '2D Bet'" (click)="goModal1(wallet.id)"
                                    class="btn btn-block btn-info"
                                    style="background-color : #009dff; width : 50px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white ;border: 2px solid white "
                                    class="text-center"> Bet 2D </button>

                                  <button type="button" *ngIf="wallet.cashOut == '3D Bet'" (click)="goModal(wallet.id)"
                                    class="btn btn-block btn-info"
                                    style=" background-color : #009dff; width : 50px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white ;border: 2px solid white"
                                    class="text-center"> Bet 3D </button>

                                    <!-- (click)="getDetail1(wallet.id)" -->
                                    <button type="button" *ngIf="wallet.cashOut == 'Withdrawal' && (wallet.isInsertByAdmin == '0' || wallet.isInsertByAdmin == null )" (click)="getDetail1(wallet.id)" class="btn btn-block" style="background-color : orange; width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white ; border: 2px solid white" class="text-center"> Withdrawal </button>

                                    <button type="button" *ngIf="wallet.cashOut == 'Withdrawal' && wallet.isInsertByAdmin == '1'" (click)="getDetail1(wallet.id)" class="btn btn-block" style="background-color : orange; width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white ; border: 2px solid white" class="text-center"> Adjust </button>


                                    <!-- *ngIf="wallet.cashOut == 'Adjust'" -->
                                    <!-- (click)="getDetail1(wallet.id)" -->


                                  <button type="button" *ngIf="wallet.isInsertByAdmin == '1' && wallet.cashOut =='GAME-DEPOSIT'"
                                  (click)="getGameTranDetail(wallet.id,wallet.source)" class="btn btn-block"
                                    style="background-color : orange; width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white;border: 2px solid white;"
                                    class="text-center"> Adjust </button>

                                    <button type="button" *ngIf="(wallet.isInsertByAdmin == '0' ||  wallet.isInsertByAdmin == null) && wallet.cashOut == 'GAME-DEPOSIT'"
                                    (click)="getGameTranDetail(wallet.id,wallet.source)" class="btn btn-block"
                                    style=" width : 100px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white;background-color: chocolate; border: 2px solid white;"
                                    class="text-center"> Game</button>

                                    <button type="button" *ngIf="wallet.cashOut == 'Freeze'"
                                    (click)="getDetailForFreeze(wallet.id)" class="btn btn-block"
                                    style="background-color : orange; width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white ; border: 2px solid white" class="text-center"> Freeze</button>

                                   

                                </td>
                                <td style="font-family: unicode;" class="text-right">{{wallet.amount}}</td>
                                <td style="font-family: unicode;" class="text-right">{{wallet.wallet}}</td>

                                <td style="font-family: 'Unicode';" class="text-center">
                                  <span *ngIf="wallet.isInsertByAdmin == 1">true</span>
                                  <span *ngIf="wallet.isInsertByAdmin != 1">false</span>
                                </td>
                               
                                <td style="font-family: unicode;" class="text-center">{{wallet.createdDate | date:
                                  'dd/MM/yyyy HH:mm:ss'}} </td>
                                <td style="font-family: unicode;" class="text-center">
                                  <span *ngIf="wallet.cashOut === '2D Bet'">
                                    {{ wallet.approvedDate | date: 'dd/MM/yyyy ' }}
                                    {{ wallet.sectionName }}
                                  </span>
                                  <span *ngIf="wallet.cashOut !== '2D Bet'">
                                    {{ wallet.approvedDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                                  </span>

                                </td>

                                <td style="font-family: unicode;" class="text-center">
                                  <a *ngIf="wallet.isInsertByAdmin == '1'">
                                    <i class="fas fa-trash" title="Delete" (click)="delete(wallet.id,wallet.tranType , wallet.source)"
                                      style="color:#dc3545;cursor: pointer;"></i>
                                  </a>
                                  <!-- (click)="delete(wallet.id,wallet.tranType , wallet.source)" -->

                                </td>


                              </tr>
                            </tbody>
                          </table>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>


                <div class="tab-pane" id="userbalances">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Balances</h3>
                        </div>
                        <div class="card-body">
                          <table class="table table-bordered" *ngIf="userbalances != null && userbalances != undefined">
                            <tr>
                              <th>&nbsp;</th>
                              <th>Topup</th>
                              <th>Withdrawal</th>
                              <th>Game Deposit</th>
                              <th>Game Withdrawal</th>
                            </tr>
                            <tr>
                              <td>User Wallet</td>
                              <td>{{userbalances.topup_balance | number : 0}}</td>
                              <td>{{userbalances.withdrawal_balance | number : 0}}</td>
                              <td>{{userbalances.gs_game_deposit_balance | number : 0}}</td>
                              <td>{{userbalances.gs_game_withdrawal_balance | number : 0}}</td>
                            </tr>
                            <tr>
                              <td>Transactions</td>
                              <td>{{userbalances.total_actual_topup | number : 0}}</td>
                              <td>{{userbalances.total_actual_withdrawal | number : 0}}</td>
                              <td>{{userbalances.total_actual_game_deposit | number : 0}}</td>
                              <td>{{userbalances.total_actual_game_withdrawal | number : 0}}</td>
                            </tr>
                            <tr>
                              <td>Differences</td>
                              <td>{{userbalances.diffTopupAmt | number : 0}}</td>
                              <td>{{userbalances.diffWithdrawalAmt | number : 0}}</td>
                              <td>{{userbalances.diffGameDepositAmt | number : 0}}</td>
                              <td>{{userbalances.diffGameWithAmt | number : 0 }}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td><button class="btn btn-primary" (click)="showAddTranModel('TOPUP')">Action</button>
                              </td>
                              <td><button class="btn btn-primary" (click)="showAddTranModel('WITHDRAW')">Action</button>
                              </td>
                              <td><button class="btn btn-primary" (click)="showAddTranModel('DEPOSIT')">Action</button>
                              </td>
                              <td><button class="btn btn-primary"
                                  (click)="showAddTranModel('WITHDRAWAL')">Action</button></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="tab-pane" id="twodbethistory">
                  <div class="row">

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Bet Amount</label>
                      <input type="number" onKeyPress="if(this.value.length==6) return false;"
                        class="form-control form-control-sm" [(ngModel)]="twodhitamount"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Wallet Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="twodhitwallet"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="twodbetDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceTwodbet" [value]="twodbetDate" [(ngModel)]="twodbetdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeTwodbet()">
                      <ngx-date-picker #dateInstanceTwodbet></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="twodbettoDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceTwodbet1" [value]="twodbettoDate" [(ngModel)]="twodbettodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeTwodbetToDate()">
                      <ngx-date-picker #dateInstanceTwodbet1></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getTwodbetHistoryByParams()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">2D Bet History List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tbl2dbet' + idtwodindex}}" datatable [dtTrigger]="dtTrigger2"
                            [dtOptions]="dtOptions2" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">S/N</th>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Bet Date</th>
                                <th style="font-family: unicode;">Bet Detail</th>
                                <th style="font-family: unicode;">Odds</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of tempBetHistoryDTOList, let j = index">
                                <td style="font-family: unicode;">{{j+1}}</td>
                                <td style="font-family: unicode;">{{wallet.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{wallet.approvedDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;"><i class="far fa-eye"
                                    style="color:#4b88eb;cursor: pointer;" (click)="goModal1(wallet.twodbet_id)"></i>
                                </td>
                                <td style="font-family: unicode;">{{wallet.odds}}</td>
                                <td style="font-family: unicode;">{{wallet.amount}} </td>
                                <td style="font-family: unicode;">{{wallet.wallet}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="twodwin">
                  <div class="row">

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Amount</label>
                      <input type="number" onKeyPress="if(this.value.length==6) return false;"
                        class="form-control form-control-sm" [(ngModel)]="twodwinamount"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Wallet Amount</label>
                      <input type="number" onKeyPress="if(this.value.length==6) return false;"
                        class="form-control form-control-sm" [(ngModel)]="twodwinwallet"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>


                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="towdwinDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceTwodwin" [value]="twodwinDate" [(ngModel)]="twodwindate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeTwodWin()">
                      <ngx-date-picker #dateInstanceTwodwin></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="twodwintodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceTwodwin1" [value]="twodwintoDate" [(ngModel)]="twodwintodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeTwoDwinToDate()">
                      <ngx-date-picker #dateInstanceTwodwin1></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getTwoDwinnerList()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">2D Winning List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tbltwodwin' + idtwodwinIndex}}" datatable [dtTrigger]="dtTrigger3"
                            [dtOptions]="dtOptions3" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Date</th>
                                <th style="font-family: unicode;">Section</th>
                                <th style="font-family: unicode;">Number</th>
                                <th style="font-family: unicode;">Odds</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Win Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of tempWinnerDTOList">
                                <td style="font-family: unicode;">{{wallet.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{wallet.bet_time}}</td>
                                <td style="font-family: unicode;">{{wallet.number}}</td>
                                <td style="font-family: unicode;">{{wallet.odds}}</td>
                                <td style="font-family: unicode;">{{wallet.bet_amount}} </td>
                                <td style="font-family: unicode;">
                                  <button type="button" *ngIf="wallet.status == 'CONFIRMED'"
                                    class="btn btn-block btn-success"
                                    style="width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white">
                                    Approved </button>
                                  <button type="button" *ngIf="wallet.status == 'PENDING'"
                                    class="btn btn-block btn-primary"
                                    style="width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white">
                                    Pending </button>
                                </td>
                                <td style="font-family: unicode;">{{wallet.created_by}} </td>
                                <td style="font-family: unicode;">{{wallet.amount}} </td>
                                <td style="font-family: unicode;">{{wallet.wallet}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="topup">
                  <div class="row">

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Transaction No</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="topuptran"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="topupamount"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Wallet Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="topupwallet"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="topupDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstancePending" [value]="topupDate" [(ngModel)]="topupdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeTopup()">
                      <ngx-date-picker #dateInstancePending></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="topuptodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstancePending1" [value]="topuptoDate" [(ngModel)]="topuptodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeTopupToDate()">
                      <ngx-date-picker #dateInstancePending1></ngx-date-picker>
                    </div>

                    <!-- <div class="col-md-4"></div> -->
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getTopupList()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Top UP List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tbltopup' + idtopupIndex}}" datatable [dtTrigger]="dtTrigger4"
                            [dtOptions]="dtOptions4" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Added Date</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Bank Type</th>
                                <th style="font-family: unicode;">Transaction Number</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let topup of tempFinancialTransactionDTOList">
                                <td style="font-family: unicode;">{{topup.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{topup.added_date | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{topup.approvedDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{topup.name}} </td>
                                <td style="font-family: unicode;">{{topup.transaction_no}} </td>
                                <td style="font-family: unicode;">
                                  <button type="button" class="btn btn-block btn-success"
                                    style=" width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white">
                                    {{topup.status}} </button>
                                </td>
                                <td style="font-family: unicode;">{{topup.updated_by}} </td>
                                <td style="font-family: unicode;">{{topup.amount}} </td>
                                <td style="font-family: unicode;">{{topup.wallet}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="withdrawal">
                  <div class="row">

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Account No</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="withdrawalAcc"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="withdrawalamount"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Wallet Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="withdrawalwallet"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="withDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceDenied" [value]="withDate" [(ngModel)]="withdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeWith()">
                      <ngx-date-picker #dateInstanceDenied></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="withtodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceDenied1" [value]="withtoDate" [(ngModel)]="withtodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeWithToDate()">
                      <ngx-date-picker #dateInstanceDenied1></ngx-date-picker>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getWithdrawalList()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Withdrawal List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblwithdrawal' + idwithdrawalIndex}}" datatable
                            [dtTrigger]="dtTrigger5" [dtOptions]="dtOptions5" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Bank Type</th>
                                <th style="font-family: unicode;">Bank Number</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let topup of tempFinancialTransactionDTOList1">
                                <td style="font-family: unicode;">{{topup.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{topup.approvedDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{topup.name}} </td>
                                <td style="font-family: unicode;">{{topup.account_no}} </td>
                                <td style="font-family: unicode;">
                                  <button type="button" class="btn btn-block btn-success"
                                    style=" width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white">
                                    {{topup.status}} </button>
                                </td>
                                <td style="font-family: unicode;">{{topup.updated_by}} </td>
                                <td style="font-family: unicode;">{{topup.amount}} </td>
                                <td style="font-family: unicode;">{{topup.wallet}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>


                <div class="tab-pane" id="threedbethistory">
                  <div class="row">
                    <div class="form-group col-md-2" style="display: none;">
                      <label>Bet Amount</label>
                      <input type="number" onKeyPress="if(this.value.length==6) return false;"
                        class="form-control form-control-sm" [(ngModel)]="threedhitamount"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Wallet Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="threedhitwallet"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>
                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="threedbetDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceThreedbet" [value]="threedbetDate" [(ngModel)]="threedbetdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeThreeDBetDate()">
                      <ngx-date-picker #dateInstanceThreedbet></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="threedbettodate" readonly class="form-control form-control-sm"
                        placeholder="Select Date" [ngxDatePicker]="dateInstanceThreedbet1" [value]="threedbettoDate"
                        [(ngModel)]="threedbettodate" [ngModelOptions]="{standalone: true}"
                        (valueChange)="onChangethreedbetToDate()">
                      <ngx-date-picker #dateInstanceThreedbet1></ngx-date-picker>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getThreedbetHistoryByParams()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">3D Bet History List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblthreedbet' + idthreedbetIndex}}" datatable
                            [dtTrigger]="dtTrigger6" [dtOptions]="dtOptions6" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">S/N</th>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Bet Date</th>
                                <th style="font-family: unicode;">Bet Detail</th>
                                <th style="font-family: unicode;">Odds</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of tempBetHistoryDTOList1, , let k = index">
                                <td style="font-family: unicode;">{{k+1}}</td>
                                <td style="font-family: unicode;">{{wallet.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{wallet.approvedDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;"><i class="far fa-eye"
                                    style="color:#4b88eb;cursor: pointer;;" (click)="goModal(wallet.threedbet_id)"></i>
                                </td>
                                <td style="font-family: unicode;">{{wallet.odds}}</td>
                                <td style="font-family: unicode;">{{wallet.amount}} </td>
                                <td style="font-family: unicode;">{{wallet.wallet}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="threedwin">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="threedwinDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceThreedwin" [value]="threedwinDate" [(ngModel)]="threedwindate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeThreedWin()">
                      <ngx-date-picker #dateInstanceThreedwin></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="threeedwintodate" readonly class="form-control form-control-sm"
                        placeholder="Select Date" [ngxDatePicker]="dateInstanceThreedwin1" [value]="threedwintoDate"
                        [(ngModel)]="threedwintodate" [ngModelOptions]="{standalone: true}"
                        (valueChange)="onChangeThreeDwinToDate()">
                      <ngx-date-picker #dateInstanceThreedwin1></ngx-date-picker>
                    </div>
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getThreeDwinnerList()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">3D Winning</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblthreedwin' + idthreedwinIndex}}" datatable
                            [dtTrigger]="dtTrigger7" [dtOptions]="dtOptions7" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">For Date</th>
                                <th style="font-family: unicode;">Number</th>
                                <th style="font-family: unicode;">Odds</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Win Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of tempWinnerDTOList1">
                                <td style="font-family: unicode;">{{wallet.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{wallet.for_date | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                <td style="font-family: unicode;">{{wallet.number}}</td>
                                <td style="font-family: unicode;">{{wallet.odds}}</td>
                                <td style="font-family: unicode;">{{wallet.bet_amount}} </td>
                                <td style="font-family: unicode;">
                                  <button type="button" *ngIf="wallet.status == 'CONFIRMED'"
                                    class="btn btn-block btn-success"
                                    style="width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white">
                                    Approved </button>
                                  <button type="button" *ngIf="wallet.status == 'PENDING'"
                                    class="btn btn-block btn-primary"
                                    style="width : 90px; padding : 1px 0px 1px 0px;font-size: 12px;border-radius: 90px;color:white">
                                    Pending </button>
                                </td>
                                <td style="font-family: unicode;">{{wallet.created_by}} </td>
                                <td style="font-family: unicode;">{{wallet.amount}} </td>
                                <td style="font-family: unicode;">{{wallet.wallet}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="gamebethistory" style="display: none;">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="gameDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceGame" [value]="gameDate" [(ngModel)]="gamedate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeThreeDBetDate()">
                      <ngx-date-picker #dateInstanceGame></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="gametodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceGame1" [value]="gametoDate" [(ngModel)]="gametodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangethreedbetToDate()">
                      <ngx-date-picker #dateInstanceGame1></ngx-date-picker>
                    </div>
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getUserById()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Game Bet History </h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblgame' + idgameIndex}}" datatable [dtTrigger]="dtTrigger8"
                            [dtOptions]="dtOptions8" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">S/N</th>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Bet Date</th>
                                <th style="font-family: unicode;">Bet Detail</th>
                                <th style="font-family: unicode;">Odds</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>


                <div class="tab-pane" id="gamewinlose">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="gamewinDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceGamewin" [value]="gamewinDate" [(ngModel)]="gamewindate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onWinLostDate()">
                      <ngx-date-picker #dateInstanceGamewin></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="gamewintodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceGamewin1" [value]="gamewintoDate" [(ngModel)]="gamewintodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeonWinLosttoDate()">
                      <ngx-date-picker #dateInstanceGamewin1></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>Game Provider</label>
                      <select (change)="flagProvider1()" id="providerId1"
                        class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="providerId"
                        [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let provider of gameproviderList" value="{{provider.provider_id}}">
                          {{provider.display_name}}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getGameBalanceLog()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Game Win/Lose</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblgamewinlose' + idgamewinIndex}}" datatable
                            [dtTrigger]="dtTriggergwinlose" [dtOptions]="dtOptionswinlose"
                            class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">S/N</th>
                                <td style="font-family: unicode;">Game User ID</td>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Game Provider</th>
                                <th style="font-family: unicode;">Balance Before</th>
                                <th style="font-family: unicode;">Post Balance</th>
                                <th style="font-family: unicode;">Win/Lose Amount</th>
                                <th style="font-family: unicode;">Win/Lose Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of userGameLogList, let j = index">
                                <td style="font-family: unicode;">{{j+1}}</td>
                                <td style="font-family: unicode;">{{'shtm'+displayUserId}}</td>
                                <td style="font-family: unicode;">{{wallet.created_date | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode;">{{wallet.display_name}}</td>
                                <td style="font-family: unicode;">{{wallet.balanceBefore | number :0 }}</td>
                                <td style="font-family: unicode;">{{wallet.postBalance | number :0 }}</td>
                                <td style="font-family: unicode;" *ngIf="wallet.status == 'Win' ">(+)
                                  {{wallet.differentAmt | number :0 }}</td>
                                <td style="font-family: unicode;" *ngIf="wallet.status == 'Lose' ">(-)
                                  {{wallet.differentAmt | number :0 }}</td>
                                <td style="font-family: unicode;" *ngIf="wallet.status == 'Win' "><button type="button"
                                    style="background-color: green;border-radius: 5px;width: 100px;color: white;pointer-events: none;">{{wallet.status}}</button>
                                </td>
                                <td style="font-family: unicode;" *ngIf="wallet.status == 'Lose' "><button type="button"
                                    style="background-color: orange;border-radius: 5px;width: 100px;color: white;pointer-events: none;">{{wallet.status}}</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>


                <div class="tab-pane" id="gametransactionhistory">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>Game Provider</label>
                      <select (change)="flagProvider()" id="providerId"
                        class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="providerId"
                        [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let provider of gameproviderList" value="{{provider.provider_id}}">
                          {{provider.display_name}}
                        </option>
                      </select>
                    </div>


                    <div class="form-group col-md-2" style="display: none;">
                      <label>Bet Amount</label>
                      <input type="number" onKeyPress="if(this.value.length==6) return false;"
                        class="form-control form-control-sm" [(ngModel)]="twodhitamount"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2" style="display: none;">
                      <label>Wallet Amount</label>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="twodhitwallet"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="queenmakerdate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceqm" [value]="qmdate" [(ngModel)]="queenmakerdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onchangeQmDate()">
                      <ngx-date-picker #dateInstanceqm></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="queenmakertodate" readonly class="form-control form-control-sm"
                        placeholder="Select Date" [ngxDatePicker]="dateInstanceqm1" [value]="qmtodate"
                        [(ngModel)]="queenmakertodate" [ngModelOptions]="{standalone: true}"
                        (valueChange)="onchangeQmToDate()">
                      <ngx-date-picker #dateInstanceqm1></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getQueenMakerTransactionHistory()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Game Transaction History List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblqmtran' + tblqmtranid}}" datatable [dtTrigger]="dtTriggerKM"
                            [dtOptions]="dtOptionsKM" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode; " class="text-center">S/N</th>
                                <th style="font-family: unicode; width:80px;" class="text-center">Game User ID</th>
                                <th style="font-family: unicode; width:80px;" class="text-center">Created Date</th>
                                <th style="font-family: unicode; width:90px;" class="text-center">Game Provider</th>
                                <th style="font-family: unicode;" class="text-center">Amount</th>
                                <th style="font-family: unicode;" class="text-center">Transaction Id</th>
                                <th style="font-family: unicode;" class="text-center">Remark</th>
                                <th style="font-family: unicode;" class="text-center">Type</th>
                                <th style="font-family: unicode;" class="text-center">Adjustment</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of tempQMfinancialTransactionDTOList, let j = index">
                                <td style="font-family: unicode;" class="text-center">{{j+1}}</td>
                                <td style="font-family: unicode; width: 80px;" class="text-center">{{'shtm'+displayUserIdTran}}</td>
                                <td style="font-family: unicode; width:80px;" class="text-center">{{wallet.created_date | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td style="font-family: unicode; width:90px;" class="text-center"> {{wallet.company_name}}</td>
                                <td style="font-family: unicode;" class="text-center">{{wallet.amount | number :0 }}</td>
                                <td style="font-family: unicode;" class="text-center">{{wallet.referenceid}}</td>
                                <td style="font-family: unicode;" class="text-center">{{wallet.remark}}</td>
                                <td style="font-family: unicode;" class="text-center">{{wallet.method}}</td>
                                <td style="font-family: unicode;" class="text-center">
                                  {{ wallet.source == 'adjustGame' ? 'Adjustment' : "" }}
                                </td>

                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="bonus" style="display: none;">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="bonusDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceBonus" [value]="bonusDate" [(ngModel)]="bonusdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeThreeDBetDate()">
                      <ngx-date-picker #dateInstanceBonus></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="bonustodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceBonus1" [value]="bonustoDate" [(ngModel)]="bonustodate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangethreedbetToDate()">
                      <ngx-date-picker #dateInstanceBonus1></ngx-date-picker>
                    </div>
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getUserById()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Bonus</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblbonus' + idbonusIndex}}" datatable [dtTrigger]="dtTrigger10"
                            [dtOptions]="dtOptions10" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">S/N</th>
                                <th style="font-family: unicode;"> Date</th>
                                <th style="font-family: unicode;"> Section</th>
                                <th style="font-family: unicode;"> Number</th>
                                <th style="font-family: unicode;"> Odds</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                                <th style="font-family: unicode;">Current Wallet</th>
                              </tr>
                            </thead>
                            <tbody>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <!-- ‌amk added for #adjust  -->
              <div class="tab-pane" id="adjust">
                  <div class="row">

                    <div class="form-group col-md-3">
                      <label> Type </label>
                      <select id = "adjusttype"   class="form-control form-control-sm" [(ngModel)]="adjusttype" [ngModelOptions]="{standalone: true}" >
                          <option value = "TOPUP" selected>TOPUP</option>
                          <option value = "WITHDRAWAL">WITHDRAWAL</option>
                          <option value = "Game Deposit">Game Deposit</option>
                          <option value = "Game Withdrawal">Game Withdrawal</option>
                          <option value = "" selected>All</option>
                          <!-- style="width:250px;" -->
                      </select>
                    </div>


                   

                    <!-- <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="adjustfromdate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceadjust" [value]="adjfromdate" [(ngModel)]="adjustfromdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onchangeQmDateForAdjust()">
                      <ngx-date-picker #dateInstanceadjust></ngx-date-picker>
                    </div> -->

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="adjustfromdate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceadjust" [value]="adjfromdate" [(ngModel)]="adjustfromdate"
                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeSingle()"
                        style="pointer-events: none;">
                      <ngx-date-picker #dateInstanceadjust></ngx-date-picker>
                    </div>
                 

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="adjusttodate" readonly class="form-control form-control-sm"
                        placeholder="Select Date" [ngxDatePicker]="dateInstanceadjust1" [value]="adjtodate"
                        [(ngModel)]="adjusttodate" [ngModelOptions]="{standalone: true}" (valueChange)="onchangeQmToDateForAdjust()"
                        >                      
                      <ngx-date-picker #dateInstanceadjust1></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="getAdjustTransactionList()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Transaction Adjust List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tbladjust' + tbladjustid}}" datatable [dtTrigger]="dtTriggeradjust"
                            [dtOptions]="dtOptionsadjust" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode; width:80px;" class="text-center">S/N</th>                            
                                <th style="font-family: unicode; width:100px;" class="text-center">Created Date</th>                             
                                <th style="font-family: unicode; width:80px;" class="text-center">Amount</th>
                               
                                <th style="font-family: unicode; width:80px;" class="text-center">Remark</th>
                                <th style="font-family: unicode; width:80px;" class="text-center">Type</th>
                            
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let wallet of tempAdjustTransactionList, let j = index">
                                <td style="font-family: unicode; width:80px;" class="text-center">{{j+1}}</td>
                                
                                <td style="font-family: unicode; width:100px;" class="text-center">{{wallet.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                               
                                <td style="font-family: unicode; width:80px;"  class="text-center">{{wallet.amount_str  }}</td> 
                                <!-- | number :0 -->
                               
                                <td style="font-family: unicode; width:80px;"    class="text-center">{{wallet.remark}}</td>
                                <td style="font-family: unicode; width:80px;"   class="text-center">{{wallet.type}}</td>
                              

                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- ‌amk added for adjust  -->




              </div>
            </div>

          </div>
        </form>
        <!--end of all tabs form-->

      </div>
      <!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>


<div id="browseAccountData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> 3D Bet History Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12" style="margin-top: 0.5%;">
              <div class="card">
                <div class="card-body">
                  <table width='100%' id="{{'tblthreedbetdetail' + idthreeddetailIndex}}" datatable
                    [dtTrigger]="dtTrigger11" [dtOptions]="dtOptions11" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">No</th>
                        <th style="font-family: unicode;">Bet Number</th>
                        <th style="font-family: unicode;">Amount</th>
                        <th style="font-family: unicode;">Odd</th>
                        <th style="font-family: unicode;">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let twodbet of threeDBetDetailDTOList, let i = index">
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.number}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                          {{twodbet.total_amount_Str}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.odd}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                          {{twodbet.result_number}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </form>
      </div>

    </div>
  </div>
</div>


<div id="browseAccountData1" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> 2D Bet History Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12" style="margin-top: 0.5%;">
              <div class="card">
                <div class="card-body">
                  <table width='100%' id="{{'tbltwodbetdetail' + idtwoddetailIndex}}" datatable
                    [dtTrigger]="dtTrigger12" [dtOptions]="dtOptions12" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">No</th>
                        <th style="font-family: unicode;">Bet Number</th>
                        <th style="font-family: unicode;">Amount</th>
                        <th style="font-family: unicode;">Created Date</th>
                        <th style="font-family: unicode;">Section Time</th>
                        <th style="font-family: unicode;">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let twodbet of twoDBetDetailDTOList, let i = index">
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.number}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                          {{twodbet.total_amount_Str}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                          {{twodbet.created_date_Str}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.am_pm}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                          {{twodbet.result_number}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </form>
      </div>

    </div>
  </div>
</div>


<!-- <div id="browseAccountData2" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Top-up Detail </h3>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="col-md-12">
    
                <div class="col-md-12">
                  
                    <div class="card-body">
                      <table class="table table-bordered">
                        <tr>
                          <th>Transaction ID</th><td>{{transactionDetailList.transaction_no}}</td>
                        </tr>
                        <tr>
                        <th>Amount</th><td>{{transactionDetailList.amount_Str}}</td>
                        </tr>
                        <tr>
                          <th>Type</th><td>{{transactionDetailList.type}}</td>
                        </tr>
                        <tr>
                          <th>Status</th><td>{{transactionDetailList.status}}</td>
                        </tr>
                        <tr>
                          <th>Bank Type</th><td>{{transactionDetailList.bankName}}</td>
                        </tr>
                        <tr>
                          <th>Account Number</th><td>{{transactionDetailList.accountNumber}}</td>
                        </tr>
                        <tr>
                          <th>Added Date</th><td>{{transactionDetailList.added_date_Str}}</td>
                        </tr>
                        <tr>
                          <th>Requested Date</th><td>{{transactionDetailList.created_date_Str}}</td>
                        </tr>
                        <tr>
                          <th>Approved Date</th><td>{{transactionDetailList.updated_date_Str}}</td>
                        </tr>
                        <tr>
                          <th>Approved By</th><td>{{transactionDetailList.approvedBy}}</td>
                        </tr>
                        <tr>
                          <th>Transfer From</th><td>{{transactionDetailList.tran_from}}</td>
                        </tr>
                      </table>
                    </div>
                </div>
              </div>
    
            </form>
          </div>
    
        </div>
      </div>
    </div> -->


<div id="browseAccountData2" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" *ngIf="transactionDetailList.isInsertByAdmin != 'True'"
          style="font-weight: bold;font-size: 1.1rem;"> Top-up Detail </h3>
        <h3 class="modal-title" *ngIf="transactionDetailList.isInsertByAdmin == 'True'"
          style="font-weight: bold;font-size: 1.1rem;"> Adjustment Top-up Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12">

              <div class="card-body">
                <table class="table table-bordered">
                  <tr>
                    <th>Transaction ID</th>
                    <td>{{transactionDetailList.transaction_no}}</td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>{{transactionDetailList.amount_Str}}</td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{{transactionDetailList.type}}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td *ngIf="transactionDetailList.isInsertByAdmin != 'True'">{{transactionDetailList.status}}</td>
                    <td *ngIf="transactionDetailList.isInsertByAdmin == 'True'">Success</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Bank Type</th>
                    <td>{{transactionDetailList.bankName}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Account Number</th>
                    <td>{{transactionDetailList.accountNumber}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Added Date</th>
                    <td>{{transactionDetailList.added_date_Str}}</td>
                  </tr>
                  <tr>
                    <th *ngIf="transactionDetailList.isInsertByAdmin != 'True'">Requested Date</th>
                    <th *ngIf="transactionDetailList.isInsertByAdmin == 'True'">Created Date</th>
                    <td>{{transactionDetailList.created_date_Str}}</td>
                  </tr>               
                  <tr>
                    <th *ngIf="transactionDetailList.isInsertByAdmin != 'True'">Approved By</th>
                    <th *ngIf="transactionDetailList.isInsertByAdmin == 'True'">Action By</th>
                    <td>{{transactionDetailList.approvedBy}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Transfer From</th>
                    <td>{{transactionDetailList.tran_from}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin == 'True'">
                    <th>Remark</th>
                    <td>{{transactionDetailList.insertRemark}}</td>
                  </tr>

                </table>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>





<!-- <div id="browseAccountData3" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Withdraw Detail </h3>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="col-md-12">
    
                <div class="col-md-12">
                  
                    <div class="card-body">
                      <table class="table table-bordered">
                        <tr>
                        <th>Amount</th><td>{{transactionDetailList.amount_Str}}</td>
                        </tr>
                        <tr>
                          <th>Type</th><td>{{transactionDetailList.type}}</td>
                        </tr>
                        <tr>
                          <th>Status</th><td>{{transactionDetailList.status}}</td>
                        </tr>
                        <tr>
                          <th>Bank Type</th><td>{{transactionDetailList.bankName}}</td>
                        </tr>
                        <tr>
                          <th>Account Number</th><td>{{transactionDetailList.withdrawAccnumber}}</td>
                        </tr>
                        <tr>
                          <th>Requested Date</th><td>{{transactionDetailList.created_date_Str}}</td>
                        </tr>
                        <tr>
                          <th>Approved Date</th><td>{{transactionDetailList.updated_date_Str}}</td>
                        </tr>
                        <tr>
                          <th>Approved By</th><td>{{transactionDetailList.approvedBy}}</td>
                        </tr>
                        <tr>
                          <th>Transfer From</th><td>{{transactionDetailList.tran_from}}</td>
                        </tr>
                      </table>
                    </div>
                </div>
              </div>
    
            </form>
          </div>
    
        </div>
      </div>
    </div> -->


<div id="browseAccountData3" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"
          *ngIf="transactionDetailList.isInsertByAdmin != 'True'"> Withdraw Detail </h3>
        <h3 class="modal-title" *ngIf="transactionDetailList.isInsertByAdmin == 'True'"
          style="font-weight: bold;font-size: 1.1rem;"> Adjustment Withdraw Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12">

              <div class="card-body">
                <table class="table table-bordered">
                  <tr>
                    <th>Amount</th>
                    <td>{{transactionDetailList.amount_Str}}</td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{{transactionDetailList.type}}</td>
                  </tr>

                  <tr>
                    <th>Status</th>
                    <td *ngIf="transactionDetailList.isInsertByAdmin == 'True'">Success</td>
                    <td *ngIf="transactionDetailList.isInsertByAdmin != 'True'">{{transactionDetailList.status}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Bank Type</th>
                    <td>{{transactionDetailList.bankName}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Account Number</th>
                    <td>{{transactionDetailList.withdrawAccnumber}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Withdraw Bank Account Number</th>
                    <td>{{transactionDetailList.accountNumber}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Withdraw Bank Account Name</th>
                    <td>{{transactionDetailList.accountName}}</td>
                  </tr>
                  <tr>
                    <th *ngIf="transactionDetailList.isInsertByAdmin != 'True'">Requested Date</th>
                    <th *ngIf="transactionDetailList.isInsertByAdmin == 'True'">Creted Date</th>
                    <td>{{transactionDetailList.created_date_Str}}</td>
                  </tr>
                 

                        <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                          <th>Approved Date</th>
                          <td>{{transactionDetailList.updated_date_Str}}</td>
                        </tr>
                  <tr>
                    <th *ngIf="transactionDetailList.isInsertByAdmin != 'True'">Approved By</th>
                    <th *ngIf="transactionDetailList.isInsertByAdmin == 'True'">Action By</th>
                    <td>{{transactionDetailList.approvedBy}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Transfer From</th>
                    <td>{{transactionDetailList.tran_from}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin == 'True'">
                    <th>Remark</th>
                    <td>{{transactionDetailList.insertRemark}}</td>
                  </tr>

                </table>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>


<div id="browseAccountData4" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> 2D Winner Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12">

              <div class="card-body">
                <table class="table table-bordered">
                  <tr>
                    <th>Winning Number</th>
                    <td>{{winnerDetail.number}}</td>
                  </tr>
                  <tr>
                    <th>Bet Amount</th>
                    <td>{{winnerDetail.betAmount_Str}}</td>
                  <tr>
                    <th>Odd</th>
                    <td>{{winnerDetail.odd}}</td>
                  </tr>
                  <tr>
                    <th>Win Amount</th>
                    <td>{{winnerDetail.winAmount_Str}}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{{winnerDetail.status}}</td>
                  </tr>
                  <tr>
                    <th>Winning Date</th>
                    <td>{{winnerDetail.created_date_Str}}</td>
                  </tr>
                  <tr>
                </table>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>

<div id="browseAccountData5" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> 3D Winner Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12">

              <div class="card-body">
                <table class="table table-bordered">
                  <tr>
                    <th>Winning Number</th>
                    <td>{{winnerDetail.number}}</td>
                  </tr>
                  <tr>
                    <th>Bet Amount</th>
                    <td>{{winnerDetail.betAmount_Str}}</td>
                  <tr>
                    <th>Odd</th>
                    <td>{{winnerDetail.odd}}</td>
                  </tr>
                  <tr>
                    <th>Win Amount</th>
                    <td>{{winnerDetail.winAmount_Str}}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{{winnerDetail.status}}</td>
                  </tr>
                  <tr>
                    <th>Winning Date</th>
                    <td>{{winnerDetail.created_date_Str}}</td>
                  </tr>
                  <tr>
                </table>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>

<div id="browseAccountData6" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Game Cash Flow Detail </h3> -->

        <h3 class="modal-title" *ngIf="gameTranDetailList.isInsertByAdmin != 'True'"
          style="font-weight: bold;font-size: 1.1rem;"> Game Cash Flow Detail </h3>
        <h3 class="modal-title" *ngIf="gameTranDetailList.isInsertByAdmin == 'True'"
          style="font-weight: bold;font-size: 1.1rem;"> Adjustment Game Cash Flow Detail </h3>

        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12">

              <div class="card-body">
                <table class="table table-bordered">
                  <tr>
                    <th>Amount</th>
                    <td>{{gameTranDetailList.amount | number : 0}}</td>
                  </tr>
                  <tr>
                    <th>Transaction Id</th>
                    <td>{{gameTranDetailList.referenceid}}</td>
                  <tr>
                    <th>Created Date</th>
                    <td>{{gameTranDetailList.created_date | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
                  </tr>
                  <!-- <tr>
                          <th *ngIf = "gameTranDetailList.isInsertByAdmin != 'True'">Game Provider</th>
                          <td>{{gameTranDetailList.providerName}}</td>
                        </tr> -->
                  <tr *ngIf="gameTranDetailList.isInsertByAdmin != 'True'">
                    <th>Game Provider</th>
                    <td>{{gameTranDetailList.providerName}}</td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{{gameTranDetailList.method}}</td>
                  </tr>
                  <tr>
                    <th *ngIf="gameTranDetailList.isInsertByAdmin != 'True'">Approved By</th>
                    <th *ngIf="gameTranDetailList.isInsertByAdmin == 'True'">Action By</th>
                    <td>{{gameTranDetailList.updated_by}}</td>
                  </tr>
                  <tr>
                    <th>Remark</th>
                    <td>{{gameTranDetailList.insertRemark}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>





<!-- <div id="deleteData" class="modal fade">
      <div class="modal-dialog">
          <div class="modal-content">
  
              <div class="modal-body">
                  <form>
  
                      <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                          <div class="form-group col-md-12">
                              <h5> This Account is used by another device. 
                                  Please Sign Out. Login Again. </h5>
                          </div>
                      </div>
  
                      <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                          <div class="col-md-5 col-sm-6" id="deleteBtn">
                              <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                          </div>
                      </div>
                  </form>
              </div>
  
          </div>
      </div>
  </div> -->


<div id="browseAccountData7" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Point Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12">

              <div class="card-body">
                <table class="table table-bordered">

                  <tr>
                    <th>First Topup Amount</th>
                    <td>{{userDTO.first_topup_amt_Str}}</td>
                  </tr>

                  <tr>
                    <th>V1.2.9 First Topup Amount</th>
                    <td>{{userDTO.v129_first_topup_amt_Str}}</td>
                  </tr>

                  <tr>
                    <th>Point Percentage (%)</th>
                    <td>{{userDTO.point_percentage}}</td>
                  </tr>

                  <tr>
                    <th>Point Balance</th>
                    <td>{{userDTO.total_promotion_balance | number : 0}}</td>
                  </tr>

                  <tr>
                    <th>First Top-up Date</th>
                    <td>{{userDTO.first_topup_date_Str}}</td>
                  </tr>

                  <tr>
                    <th>V1.2.9 First Top-up Date</th>
                    <td>{{userDTO.v129_first_topup_date_Str}}</td>
                  </tr>

                  <tr>
                    <th>Register Date</th>
                    <td>{{userDTO.created_date_Str}}</td>
                  </tr>

                </table>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>




<!-- <div>amk</div> -->

<!--Add-->
<div id="browseAccountData9" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="col-md-12">
              <div class="card-body">
                <table class="table table-bordered">
                  <tr>
                    <th>Amount</th>
                    <td>
                      <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="addTranAmt"
                        [ngModelOptions]="{standalone: true}">
                    </td>
                  </tr>
                  <tr>
                    <th>Remark</th>
                    <td>
                      <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="addTranRemark"
                        [ngModelOptions]="{standalone: true}">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="addTran(addTranAmt,addTranRemark)">Add
          Transaction</button>
      </div>
    </div>
  </div>
</div>





<!-- 
<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
              <form>
                
                  <div class="row justify-content-center">
                      <div class="col-md-12">
                          <h3 class="text-center">Are you sure you want to delete?</h3>
                      </div>
                  </div>

                  <div class="row justify-content-around" style="margin-top: 20px;">
                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-success" (click)="deletTran()"> Ok </button>
                      </div>

                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-danger" (click)="closeTran()"
                        [routerLink]="['/game-transaction-list']"> Cancel </button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div> -->


<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
              <form>
                
                  <div class="row justify-content-center">
                      <div class="col-md-12">
                          <h3 class="text-center">Are you sure you want to delete?</h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">                   

                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-danger" (click)="closeTran()"
                        [routerLink]="['/game-transaction-list']"> Cancel </button>
                      </div>

                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-success" (click)="deletTran()"> Confirm </button>
                      </div>

                  </div>
              </form>
          </div>
      </div>
  </div>
</div>






<!-- 

<div id="deleteDataForBank" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
              <form>
                
                  <div class="row justify-content-center">
                      <div class="col-md-12">
                          <h3 class="text-center">Are you sure you want to delete?</h3>
                      </div>
                  </div>

                  <div class="row justify-content-around" style="margin-top: 20px;">
                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-success" (click)="deletTranForBankAcc()"> Ok </button>
                      </div>

                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-danger" (click) = "closeTranForBank()" [routerLink] = "['/game-transaction-list']"> Cancel </button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div> -->





<div id="deleteDataForBank" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row justify-content-center">
                      <div class="col-md-12">
                        <h3 class="text-center">Are you sure you want to delete?</h3>
                      </div>
                  </div>

                  
                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">                

                    <div class="col-md-5">
                      <button type="button" class="btn btn-block btn-danger" (click) = "closeTranForBank()" [routerLink] = "['/game-transaction-list']"> Cancel </button>
                    </div>
                    
                    <div class="col-md-5">
                      <button type="button" class="btn btn-block btn-success" (click)="deletTranForBankAcc()"> Confirm </button>
                    </div>

                  
                  </div>

              </form>
          </div>

      </div>
  </div>
</div> 




<div id="browseAccountDataForFreeze" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"
          *ngIf="transactionDetailList.isInsertByAdmin != 'True'"> Withdraw Detail </h3>
        <h3 class="modal-title" *ngIf="transactionDetailList.isInsertByAdmin == 'True'"
          style="font-weight: bold;font-size: 1.1rem;"> Adjustment Withdraw Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="col-md-12">

              <div class="card-body">
                <table class="table table-bordered">
                  <tr>
                    <th>Amount</th>
                    <td>{{transactionDetailList.amount_Str}}</td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{{transactionDetailList.type}}</td>
                  </tr>

                  <tr>
                    <th>Status</th>
                    <td *ngIf="transactionDetailList.isInsertByAdmin == 'True'">Success</td>                   
                    <td *ngIf="transactionDetailList.status == 'PENDING'">{{"Freeze"}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Bank Type</th>
                    <td>{{transactionDetailList.bankName}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Account Number</th>
                    <td>{{transactionDetailList.withdrawAccnumber}}</td>
                  </tr>
                 
                  <tr>
                    <th *ngIf="transactionDetailList.isInsertByAdmin != 'True'">Requested Date</th>                    
                    <td>{{transactionDetailList.created_date_Str}}</td>
                  </tr>
                  <tr>
                    <th *ngIf="transactionDetailList.status == 'PENDING'">Approved Date</th>                    
                    <td>{{ " "}}</td>
                  </tr>
        
                  <tr>                  
                    <th *ngIf="transactionDetailList.status == 'PENDING'">Approved By</th>                    
                    <td>{{ " "}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin != 'True'">
                    <th>Transfer From</th>
                    <td>{{transactionDetailList.tran_from}}</td>
                  </tr>
                  <tr *ngIf="transactionDetailList.isInsertByAdmin == 'True'">
                    <th>Remark</th>
                    <td>{{transactionDetailList.insertRemark}}</td>
                  </tr>

                </table>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>




