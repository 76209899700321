import { Component, OnInit } from '@angular/core';
import { DtoService } from '../../service/dto.service';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { param } from 'jquery';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */
declare var $: any;
@Component({
  selector: 'app-users-topup',
  templateUrl: './users-topup.component.html',
  styleUrls: ['./users-topup.component.css']
})
export class UsersTopupComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  name: any;
  status  :any;
  amt : any;
  ephone : any;
  userList : any;
  idIndex : any;
  phone_no: any;
  prefix: any;
  sendPhone : any;
  userTopupEditModel : any;
  token: any;
  phone_No_topup: any;//phoneNo
  itemsPerPage =  10;
  totalItems : any; 
  page = 1;
  paymentList : any;
  detailph :any;
  detialBetLimit : any;
  detailDate: any;
  detialName :any;
  editBalance: any;
  userId : any;
  wallet : any;
  withdrawal : any;
  editTopup: any;
  remarkTopup:any;
  adjustModel: any;
  clickId = [];paymentAccList = [] ;
  paymentId: 0;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;
  constructor(private router: Router,private spinner: NgxSpinnerService,private toastr: ToastrService,private http: HttpClient,private storage: LocalStorageService,private dto: DtoService, private funct: FunctService) { 
    this.hardcode_view = "ManageTopup_View";
    this.storage.store('isNotiSong', "");
    this.hardcode_edit = "ManageTopup_Edit";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];    
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }     
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
        if(filteredMenuCodes_view.length != 0)
        {
              this.hardcode_view_bool = true;
        }       
        if(filteredMenuCodeds_edit.length != 0)
        {
              this.hardcode_edit_bool = true;
        }


    this.getAllData();

    this.phone_No_topup ='';

  }
  ngOnInit(): void {   
    this.userTopupEditModel={
      userId :'',
      actionType :'topup',
      payment_id  :1,
      amount :0.0,
      transaction_no :'superadmin',
      account_no :'000000',
      remarkTopup:''
    }
    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
      },
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip",
    }
  }
  
  getAllData() {
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams(); 
    if (!this.storage.retrieve('phone_No_topup')) {
      this.phone_No_topup = '';
    }
    else {
      this.phone_No_topup = this.storage.retrieve('phone_No_topup');
    }
    if(this.phone_No_topup != undefined)
    {
     params = params.set('phoneNo',this.phone_No_topup).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phone_No_topup == undefined && this.name == undefined)
    {
      params = params.set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phone_No_topup != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phone_No_topup).set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getUsersForManage', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     ).
    subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    
    })
  }
  
  gty(page: any){
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    if(this.phone_No_topup != undefined)
    {
     params = params.set('phoneNo',this.phone_No_topup).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.phone_No_topup == undefined && this.name == undefined)
    {
      params = params.set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.phone_No_topup != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phone_No_topup).set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getUsersForManage', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }
  getuserDetail(userId,phone_no,wallet,username, withdraw)
  {
    this.editTopup = '';
    this.detailph = phone_no
    this.wallet = wallet;
    this.sendPhone = this.detailph;
    this.detialName = username;
    this.userId = userId;
    this.withdrawal = withdraw;
    this.remarkTopup = '';
    $('#showeditModal').modal("show");
  }
  editUserTopup()
  {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      if(this.sendPhone == null || this.sendPhone == undefined)
      {
        this.toastr.error("Please enter phone number", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }

      if(this.editTopup == "" || this.editTopup == null || this.editTopup== undefined)
      {
        this.toastr.error("please fill amount", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      if(this.editTopup != null && this.editTopup!= undefined && this.editTopup < 0)
      {
        this.toastr.error("please fill amount", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      if(this.editTopup < 1000)
      {            
          this.toastr.error("The field amount must be between 1000 and 99999999999", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });        
        this.spinner.hide();
        return;
      }
    
      this.userTopupEditModel.userId= this.userId.toString();
      this.userTopupEditModel.amount= this.editTopup;
      this.userTopupEditModel.remarkTopup= this.remarkTopup;
      this.http.post(this.funct.ipaddress + 'topupWithdrawalAdjust/topupApprovedBySuperAdmin', this.userTopupEditModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response.status == "Success")
          {
              $('#showeditModal').modal("hide");
              this.toastr.success("TopUp Approved Success", 'Success!', 
              {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
              this.spinner.hide();
              this.editTopup = '';
              this.getAllData();            
          }
          else
          {
           
            $('#showeditModal').modal("hide");
            if (this.dto.Response.errors && this.dto.Response.errors.amount) {
              this.toastr.error(this.dto.Response.errors.amount[0], 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            } else {
              this.toastr.error("An error occurred", 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            }
            this.spinner.hide();
            return;
          }
          
        }); 
  }
  handleError(error: HttpErrorResponse)
  {
    this.spinner.hide();
    if(error.status == 403)
    {
      this.toastr.error("Limited Access", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.editTopup = '';
      return;
    }
    if(error.status == 406)
    {
      this.toastr.error("Not enough balance", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.editTopup = '';
      return;
    }
  }
  OkLogout()
  {
    window.location.href ="./ad-login";
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  getAssignedPayments() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('type', 'TOPUP');
    this.http.get(this.funct.ipaddress + 'payment/topuplistPayment', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList = this.dto.Response;
      }
    );
  } 
  changeAction(id) 
 {
   if(this.clickId.length == 0)
   {
    $("#"+id).css('filter','grayscale(0%)');
      this.clickId.push(id);
      this.paymentId = id;
   }
   else
   {
     for(var i = 0 ; i < this.clickId.length; i++)
     {
       if(this.clickId[i] != id)
       {
          $("#"+this.clickId[i]).css('filter','grayscale(100%)');
          $("#"+id).css('filter','grayscale(0%)');
          this.clickId[i] = id;
          this.paymentId = id;
       }
       else
       {
         $("#"+this.clickId[i]).css('filter','grayscale(0%)');
         this.clickId[i] = id;
         this.paymentId = id;
       }
     }
   }
   this.getPaymentById(id);
 }
 getPaymentById(id) {
  this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.token);
  let params = new HttpParams();
  params = params.set('id', id);
  console.log(this.paymentId)
  this.paymentAccList = [];
  this.http.get(this.funct.ipaddress + 'payment/GetAccountDetailByPaymentID', { params: params, headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      this.paymentAccList = this.dto.Response;
      this.paymentAccList.push(" ");
    });
}

onChangePhoneNo() {     
  this.storage.store('phone_No_topup', this.phone_No_topup.toString());  
}
}
