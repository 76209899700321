
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive';
declare var $: any;
@Component({
  selector: 'app-user-otps',
  templateUrl: './user-otps.component.html',
  styleUrls: ['./user-otps.component.css']
})
export class UserOtpsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  phoneNo: string= '';
  name: string= '';
  userOtpList: any;
  idIndex: any;
  PhoneNumber : any = "";
  SendCount : any = 0;
  editID : any = 0 ;
  userOTPSendCount: any;
  page = 1;
  totalItems : any; 
  itemsPerPage =  10;

  hardcode_edit :any;
  hardcode_block :any;
  hardcode_Unblock :any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_block_bool : any;
  hardcode_unblock_bool : any;
  hardcode_edit_bool : any;
  startDate: string;
  defaultTodayDate: string;
  isDateRange : Date;
  endDate: string;
  selectedPreset: string;
  approvefromdate: any; 
  approvetodate :any;
  approvetodayDate: any;
  approvechangeDate: any;
  approvetodatechangeDate:any;
  approveFromDate: string = '';  
  approveToDate: string ='';
  approvetodaytodate : any;
  addedtodaytodate : any;
  latest_function_name:any;

  constructor(private storage: LocalStorageService,private datepipe: DatePipe, private toastr: ToastrService,private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, 
    private route: ActivatedRoute, private funct: FunctService)
     {
      this.latest_function_name="";
      this.idIndex = 1;
      this.selectedPreset="today";
      this.storage.store('isNotiSong', "");
        this.hardcode_view = "Users_UserOtps_View";
        this.hardcode_edit = "Users_UserOtps_Edit";
        this.hardcode_block = "Users_UserOtps_Block";
        this.hardcode_Unblock = "Users_UserOtps_UnBlock";
        
        this.dto.menuCodes = this.storage.retrieve('menucodes');

        const filteredMenuCodes_view = [];
        const filteredMenuCodeds_bolock =[];
        const filteredMenuCodeds_edit =[];
        const filteredMenuCodeds_unbolock =[];
        this.dto.menuCodes.forEach(menuCode => {
          if (menuCode === this.hardcode_view) {
            filteredMenuCodes_view.push(menuCode);
          }         
          if (menuCode === this.hardcode_edit) {
            filteredMenuCodeds_edit.push(menuCode);
          }
          if (menuCode === this.hardcode_block) {
            filteredMenuCodeds_bolock.push(menuCode);
          }
          if (menuCode === this.hardcode_Unblock) {
            filteredMenuCodeds_unbolock.push(menuCode);
          }
        });
        if(filteredMenuCodes_view.length != 0)
        {
              this.hardcode_view_bool = true;
        }      
        if(filteredMenuCodeds_edit.length != 0)
        {
              this.hardcode_edit_bool = true;
        }
        if(filteredMenuCodeds_bolock.length != 0)
        {
              this.hardcode_block_bool = true;
        }
        if(filteredMenuCodeds_unbolock.length != 0)
        {
              this.hardcode_unblock_bool = true;
        }


      
        this.approvefromdate = new Date(); 
        this.approvetodate = new Date();
        this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');        
        this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');  
        this.storage.store('fromDate', "");
        this.storage.store('toDate', "");



      //   if (!this.storage.retrieve('fromDate'))
      //   {
      //    this.approvefromdate = new Date(); 
      //    this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');   
      //  }
      //  else
      //   {
      //    this.approvefromdate = new Date(this.storage.retrieve('fromDate'));
      //    this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
      //  }

      //  if (!this.storage.retrieve('toDate')) {
      //   this.approvetodate = new Date();  
      //   this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      // }
      // else {
      //   this.approvetodate = new Date(this.storage.retrieve('toDate'));
      //   this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      // }
       
      
    

      this.Search();
    }

  ngOnInit(): void {
    this.dtOptions = {
      order:[],         
      responsive:{
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
      },
      paging: false,
      info : true,
      dom: "Bfrltip"
      }
    this.dtOptions.columnDefs = [
      { targets: [0], orderable: true }
    ];
    this.userOTPSendCount = {
      id: 0,
      is_blocked: false,
      send_count: 0

    }
    this.onDateRangeChange(); 
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tbOtpSendCount' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  }

  
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  } 
  gty(page: any){
    this.userOtpList = [];
    var id = 'tbOtpSendCount' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show(); 
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();   
    
    
    if (!this.storage.retrieve('fromDate'))
    {
     this.approvefromdate = new Date(); 
     this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy'); 
     this.approvechangeDate = this.approvetodayDate;  
   }
   else
    {
        this.approvefromdate = new Date(this.storage.retrieve('fromDate'));
        this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
        this.approvechangeDate = this.approvetodayDate; 
    }

    if (!this.storage.retrieve('toDate')) {
      this.approvetodate = new Date();  
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      this.approvetodatechangeDate = this.approvetodaytodate;
    }
    else {
      this.approvetodate = new Date(this.storage.retrieve('toDate'));
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      this.approvetodatechangeDate = this.approvetodaytodate;
    }
   
    
    
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set('fdate',this.approvechangeDate).set('functionName',this.latest_function_name).set('tdate',this.approvetodatechangeDate).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    this.http.get(this.funct.ipaddress + 'userOtps/GetUserOtpLists', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
    )    
    .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      console.log("Result data :" + JSON.stringify(data.results));
      this.userOtpList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }

  Search() 
  {
    this.userOtpList = [];
    var id = 'tbOtpSendCount' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();  

    if (!this.storage.retrieve('fromDate'))
    {
     this.approvefromdate = new Date(); 
     this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy'); 
     this.approvechangeDate = this.approvetodayDate;  
   }
   else
    {
        this.approvefromdate = new Date(this.storage.retrieve('fromDate'));
        this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
        this.approvechangeDate = this.approvetodayDate; 
    }

    if (!this.storage.retrieve('toDate')) {
      this.approvetodate = new Date();  
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      this.approvetodatechangeDate = this.approvetodaytodate;
    }
    else {
      this.approvetodate = new Date(this.storage.retrieve('toDate'));
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      this.approvetodatechangeDate = this.approvetodaytodate;
    }
   
    
    
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set('fdate',this.approvechangeDate).set('functionName',this.latest_function_name).set('tdate',this.approvetodatechangeDate).set("pageNumber","1").set("rowsOfPage","10");
    this.http.get(this.funct.ipaddress + 'userOtps/GetUserOtpLists', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
    ) 
    .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      console.log("Result data :" + JSON.stringify(data.results));
      this.userOtpList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isBlock(blockToggle, idUserOtps ){
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);

    this.userOTPSendCount.id = idUserOtps;
    this.userOTPSendCount.is_blocked = blockToggle;

    this.http.post(this.funct.ipaddress + 'userOtps/UpdateIsBlock', this.userOTPSendCount, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
        this.Search();
      });
  }

  editInfoPopup(idUserOtps, phone_no, send_count ){
    $('#editInfo').modal("show");
    this.editID = idUserOtps;
    this.PhoneNumber = phone_no;
    this.SendCount = send_count;
  }

  goSave(){
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);

    this.userOTPSendCount.id = this.editID;
    this.userOTPSendCount.send_count = this.SendCount;

    this.http.post(this.funct.ipaddress + 'userOtps/UpdateSendCount', this.userOTPSendCount, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
        $('#editInfo').modal("hide");
        this.Search();
      });
  }
  
  onChangeSingle() {
    this.startDate = $("#startDate").val();
  }
  onChangeAllToDate() {
    this.endDate = $("#endDate").val();
  } 


  onDateRangeChange() 
  {
    const today = new Date();    
    const formatDate = (date) => {    
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options); 
    };
  
    this.defaultTodayDate = formatDate(today);
  
    switch (this.selectedPreset) {
      case 'today':
        this.startDate = this.defaultTodayDate;
        this.endDate = this.startDate;
        break;
  
      case 'yesterday':
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        this.startDate = formatDate(yesterday);
        this.endDate = this.startDate;
      
        break;
  
      case 'last7days':
        const last7days = new Date(today);
        last7days.setDate(today.getDate() - 6);
        this.startDate = formatDate(last7days);
        this.endDate = this.defaultTodayDate;
        break;
  
      case 'last30days':
        const last30days = new Date(today);
        last30days.setDate(today.getDate() - 29);
        this.startDate = formatDate(last30days);
        this.endDate = this.defaultTodayDate;
        break;
  
      case 'thisweek':
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + 1); 
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        this.startDate = formatDate(startOfWeek);
        this.endDate = formatDate(endOfWeek);
        break;
  
      case 'lastweek':
        const startOfLastWeek = new Date(today);
        startOfLastWeek.setDate(today.getDate() - today.getDay() - 6); 
        const endOfLastWeek = new Date(startOfLastWeek);
        endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
        this.startDate = formatDate(startOfLastWeek);
        this.endDate = formatDate(endOfLastWeek);
        break;
  
      case 'thismonth':
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.startDate = formatDate(startOfMonth);
        this.endDate = formatDate(endOfMonth);
        break;
  
      case 'lastmonth':
        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        this.startDate = formatDate(startOfLastMonth);
        this.endDate = formatDate(endOfLastMonth);
        break;
    }
  
    $("#startDate").val(this.startDate);
    $("#endDate").val(this.endDate);
  }

  onChangeApproveFromDate() {
    this.storage.store('fromDate', this.approvefromdate.toString());
  }

  onChangeApproveToDate()
  {
    this.storage.store('toDate', this.approvetodate.toString());
  }
}
