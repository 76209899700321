import { Component, OnInit } from '@angular/core';
import { DtoService } from '../../service/dto.service';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Console } from 'console';
import Responsive from 'datatables.net-responsive';
declare var $: any;

@Component({
  selector: 'app-configure-user-betlimit',
  templateUrl: './configure-user-betlimit.component.html',
  styleUrls: ['./configure-user-betlimit.component.css']
})
export class ConfigureUserBetlimitComponent implements OnInit {
dtTrigger: Subject<any> = new Subject();
dtOptions: DataTables.Settings = {};

 name: any;
 status  :any;
 amt : any;
 ephone : any;
 userList : any;
 idIndex : any;
 selectedHero : any;
 phone_no: any;
 prefix: any;
 sendPhone : any;
 twodbetEditModel : any;
 token: any;
 twodbetlimit:any;
 nphone: any;
 nprefix: any;
 ntwodbetlimit:any;
 limitAmt : any;
 phoneNo: any;
 itemsPerPage =  10;
 totalItems : any; 
 page = 1;
 userBetLimitList : any;
 detailph :any;
 detialBetLimit : any;
 detailDate: any;
 etwodbetlimit1: any;
 etwodbetlimit2:any;
 etwodbetlimit3:any;
 etwodbetlimit4:any;
 detialName :any;
 amt1030: any;
 amt1201: any;
 amt230: any;
 amt430: any;
 betLimitAmountModel : any;
 twodbetlimit1030: any;
 twodbetlimit1201: any;
 twodbetlimit230: any;
 twodbetlimit430: any;

 detbetlimit1030:any;
 detbetlimit1201: any;
 detbetlimit230: any;
 detbetlimit430: any;

 hardcode_edit :any;
 hardcode_add :any;
 hardcode_view: any;
 hardcode_view_bool : any;
 hardcode_add_bool : any;
 hardcode_edit_bool : any;


 constructor( private router: Router,private spinner: NgxSpinnerService,private toastr: ToastrService,private http: HttpClient,private storage: LocalStorageService,private dto: DtoService, private funct: FunctService) 
  { 
    this.prefix = "+959";
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "Configure2DUserBetLimit_View";
    this.hardcode_edit = "Configure2DUserBetLimit_Edit";
    this.hardcode_add = "Configure2DUserBetLimit_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodes_view.length != 0)
    {
         this.hardcode_view_bool = true;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
         this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
         this.hardcode_edit_bool = true;
    }


  }
  ngOnInit(): void
   {
    this.userList =[];
      this.status = 'all';
      this.twodbetEditModel={
        phone_no : '',
        twodbetlimit: ''
      }
  this.betLimitAmountModel=
   {
    betlimit1030:'',
    betlimit1201 :'',
    betlimit230:'',
    betlimit430:''
   }

      this.dtOptions = 
      {
        // responsive: true,
        responsive: {
          details: {
              renderer: Responsive.renderer.listHiddenNodes()
          }
      },
        order:[],
        paging: false,
        info : true,
        dom: "Bfrltip",
      }
      this.getAllData();
    }

  OkLogout()
  {
    window.location.href ="./ad-login";
  } 
  goSave()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    if(this.betLimitAmountModel.betlimit1030 == null || this.betLimitAmountModel.betlimit1030 == "" ||  this.betLimitAmountModel.betlimit1030 == undefined)
    { 
      this.toastr.error("Please enter bet limit amount 10:30 AM", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.spinner.hide();
      return;
    }
    else if(this.betLimitAmountModel.betlimit1030 != null || this.betLimitAmountModel.betlimit1030 != "" ||  this.betLimitAmountModel.betlimit1030 != undefined)
    {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      if (myanmarCharactersRegex.test(this.betLimitAmountModel.betlimit1030)) 
      {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });   
          return;   
      }
    }
    if(this.betLimitAmountModel.betlimit1201 == null || this.betLimitAmountModel.betlimit1201 == "" || this.betLimitAmountModel.betlimit1201 == undefined)
    {
      this.toastr.error("Please enter bet limit amount 12:01 PM", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.spinner.hide();
      return;
    }
    else if(this.betLimitAmountModel.betlimit1201 != null || this.betLimitAmountModel.betlimit1201 != "" || this.betLimitAmountModel.betlimit1201 != undefined)
    {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      if (myanmarCharactersRegex.test(this.betLimitAmountModel.betlimit1201)) 
      {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });   
          return;   
      }
    }
    if(this.betLimitAmountModel.betlimit230 == "" || this.betLimitAmountModel.betlimit230 == null || this.betLimitAmountModel.betlimit230 == undefined)
    {
      this.toastr.error("Please enter bet limit amount 02:30 PM", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.spinner.hide();
      return;
    }
    else if(this.betLimitAmountModel.betlimit230 != "" || this.betLimitAmountModel.betlimit230 != null || this.betLimitAmountModel.betlimit230 != undefined)
    {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      if (myanmarCharactersRegex.test(this.betLimitAmountModel.betlimit230)) 
      {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });   
          return;   
      }
    }
    if(this.betLimitAmountModel.betlimit430 == "" || this.betLimitAmountModel.betlimit430 == null || this.betLimitAmountModel.betlimit430 == undefined)
    {
      this.toastr.error("Please enter bet limit amount 04:30 PM", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.spinner.hide();
      return;
    }
    else if(this.betLimitAmountModel.betlimit430 != "" || this.betLimitAmountModel.betlimit430 != null || this.betLimitAmountModel.betlimit430 != undefined)
    {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      if (myanmarCharactersRegex.test(this.betLimitAmountModel.betlimit430)) 
      {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });   
          return;   
      }
    }
    var formData  = new FormData();
    formData.append("twodbetlimit",this.amt);
    this.http.post(this.funct.ipaddress + 'user/saveTwodBetLimitAllUser',this.betLimitAmountModel, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if( this.dto.Response.status == "Success")
        {
          this.toastr.success("Save Bet Limit Success", 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
          this.betLimitAmountModel.betlimit1030 = '';
          this.betLimitAmountModel.betlimit1201 = '';
          this.betLimitAmountModel.betlimit230 = '';
          this.betLimitAmountModel.betlimit430 = '';
          this.spinner.hide();
        }
      });
  }
  clearUsersData()
  {
    this.userList = [];
    this.ephone ='';
  }
  clearData()
  {
    this.betLimitAmountModel.betlimit1030 = '';
    this.betLimitAmountModel.betlimit1201 = '';
    this.betLimitAmountModel.betlimit230 = '';
    this.betLimitAmountModel.betlimit430 = '';
  }
  handleError(error: HttpErrorResponse)
    {
      this.spinner.hide();
      if(error.status == 403)
      {
        this.toastr.error("Limited Access", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.twodbetlimit = '';
        return;
      }
    }
    onInput(event: InputEvent) {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      const inputValue = (event.target as HTMLInputElement).value; 
      if(event.data!=null)
      {
        if (myanmarCharactersRegex.test(inputValue)) 
        {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });  
        }
      }   
      
    }
    // getUser()
    // {
    //   this.spinner.show();
    //   if(this.ephone != null && this.ephone != undefined)
    //   {
    //     const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    //     if (myanmarCharactersRegex.test(this.ephone)) 
    //     {        
    //         this.spinner.hide();
    //         this.toastr.error( "Myanmar typing detected!", '', {
    //         timeOut: 5000,
    //         positionClass: 'toast-top-right',
    //         });   
    //         return;   
    //     }
    //     // if(this.ephone.startsWith('09') || this.ephone.startsWith("+66") || this.ephone.startsWith("+959")
    //     // || this.ephone.startsWith("66") || this.ephone.startsWith("959"))
    //     // {
    //     //   this.ephone = this.ephone.substring(2);
    //     // }
    //         this.sendPhone = this.prefix + this.ephone;
    //         this.dto.token = this.storage.retrieve('token');
    //         let headers = new HttpHeaders();
    //         headers = headers.set('Authorization', this.dto.token);
    //         let params = new HttpParams();
    //         params = params.set('phone_no',this.sendPhone);
    //         this.http.get(this.funct.ipaddress + 'user/liveSearchUsers', { params: params, headers: headers } )
    //         .pipe(
    //           catchError(this.handleError.bind(this))
    //         )
    //         .subscribe(
    //           result => 
    //           {
    //             this.dto.Response = {};
    //             this.dto.Response = result;
    //             this.userList = this.dto.Response;
    //             // this.spinner.hide();
    //             if(result != null)
    //             {
    //               this.twodbetlimit = this.userList.twodbetlimit;
    //             }
    //             else 
    //             {
    //                 this.toastr.error("User not found", 'Invalid!', 
    //                 {
    //                   timeOut: 3000,
    //                   positionClass: 'toast-top-right'
    //                 });
                  
    //                 //this.twodbetlimit = '';
    //               //return;
    //             }
    //             this.spinner.hide();
               
    //           }
    //         );
    //       }
    //       else
    //       {
    //         this.toastr.error("Please enter phone number", 'Invalid!', {
    //           timeOut: 3000,
    //           positionClass: 'toast-top-right'
    //         });
    //         this.twodbetlimit = '';
    //         return;
    //       }
    //   }


    // getUser() {
    //   this.spinner.show();
      
    //   if (this.ephone != null && this.ephone != undefined) {
    //     const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        
    //     if (myanmarCharactersRegex.test(this.ephone)) {        
    //       this.spinner.hide();
    //       this.toastr.error("Myanmar typing detected!", '', {
    //         timeOut: 5000,
    //         positionClass: 'toast-top-right',
    //       });
    //       return;
    //     }
    
    //     this.sendPhone = this.prefix + this.ephone;
    //     this.dto.token = this.storage.retrieve('token');
    //     let headers = new HttpHeaders().set('Authorization', this.dto.token);
    //     let params = new HttpParams().set('phone_no', this.sendPhone);
        
    //     this.http.get(this.funct.ipaddress + 'user/liveSearchUsers', { params: params, headers: headers })
    //       .pipe(
    //         catchError(this.handleError.bind(this))
    //       )
    //       .subscribe(result => {
    //         this.dto.Response = result;
    //         this.userList = this.dto.Response;
    
    //         this.spinner.hide(); // hide spinner early to prevent duplicate hiding in multiple branches
    
    //         if (result != null) {
    //           this.twodbetlimit = this.userList.twodbetlimit;
    //         } else {
    //           this.toastr.error("User not found", 'Invalid!',
    //            {
    //             timeOut: 3000,
    //             positionClass: 'toast-top-right',
    //           });
    //           this.twodbetlimit = '';
    //           this.userList = "";
    //         }
    //       });
    //   } else {
    //     this.spinner.hide();
    //     this.toastr.error("Please enter phone number", 'Invalid!', {
    //       timeOut: 3000,
    //       positionClass: 'toast-top-right',
    //     });
    //     this.twodbetlimit = '';
    //     return;
    //   }
    // }
    


    getUser() {
      this.spinner.show();
    
      if (this.ephone != null && this.ephone != undefined) {
        const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        
        if (myanmarCharactersRegex.test(this.ephone)) {
          this.spinner.hide();
          this.toastr.error("Myanmar typing detected!", '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
          return;
        }
    
        this.sendPhone = this.prefix + this.ephone;
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders().set('Authorization', this.dto.token);
        let params = new HttpParams().set('phone_no', this.sendPhone);
    
        this.http.get(this.funct.ipaddress + 'user/liveSearchUsers', { params: params, headers: headers })
          .pipe(
            catchError(this.handleError.bind(this))
          )
          .subscribe(
            result => {
              this.spinner.hide(); 
              console.log('Result:', result);
              if (result != null && result !== undefined && Object.keys(result).length > 0) 
              {
                this.dto.Response = result;
                this.userList = this.dto.Response;
                this.twodbetlimit = this.userList.twodbetlimit;
              }
               else
               {               
                this.toastr.error("User not found", 'Invalid!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
                this.twodbetlimit = '';
                this.userList ="";
              }
            },
            error => 
            {            
              this.spinner.hide();
              this.toastr.error("Error fetching user data", 'Error!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              console.error('Error:', error);
              this.twodbetlimit = '';
              this.userList ="";
            }
          );
      } else {
        this.spinner.hide();
        this.toastr.error("Please enter phone number", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.twodbetlimit = '';
        this.userList ="";
        return;
      }
    }
    



    ResetData()
    {
      this.amt = '';
      this.ephone = '';
      this.twodbetlimit = '';
    }
    editBetLimit() 
    {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      if(this.sendPhone == null || this.sendPhone == undefined)
      {
        this.toastr.error("Please enter phone number", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      else if(this.sendPhone != null || this.sendPhone != undefined)
      {
        const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        if (myanmarCharactersRegex.test(this.sendPhone)) 
        {        
            this.spinner.hide();
            this.toastr.error( "Myanmar typing detected!", '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            });   
            return;   
        }
      }
      if(this.userList.betlimit1030 == null || this.userList.betlimit1030 == undefined)
      {
        this.toastr.error("Please enter bet limit amount 10:30 AM", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      else if(this.userList.betlimit1030 != null || this.userList.betlimit1030 != undefined)
      {
        const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        if (myanmarCharactersRegex.test(this.userList.betlimit1030)) 
        {        
            this.spinner.hide();
            this.toastr.error( "Myanmar typing detected!", '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            });   
            return;   
        }
      }
      if(this.userList.betlimit1201 == null || this.userList.betlimit1201 == undefined)
      {
        this.toastr.error("Please enter bet limit amount 12:01 PM", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      else if(this.userList.betlimit1201 != null || this.userList.betlimit1201 != undefined)
      {
        const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        if (myanmarCharactersRegex.test(this.userList.betlimit1201)) 
        {        
            this.spinner.hide();
            this.toastr.error( "Myanmar typing detected!", '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            });   
            return;   
        }
      }
      if(this.userList.betlimit230 == null || this.userList.betlimit230 == undefined)
      {
        this.toastr.error("Please enter bet limit amount 02:30 PM", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      else if(this.userList.betlimit230 != null || this.userList.betlimit230 != undefined)
      {
        const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        if (myanmarCharactersRegex.test(this.userList.betlimit230)) 
        {        
            this.spinner.hide();
            this.toastr.error( "Myanmar typing detected!", '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            });   
            return;   
        }
      }
      if(this.userList.betlimit430 == null || this.userList.betlimit430 == undefined)
      {
        this.toastr.error("Please enter bet limit amount 04:30 PM", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      else if(this.userList.betlimit430 == null || this.userList.betlimit430 == undefined)
      {
        const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        if (myanmarCharactersRegex.test(this.userList.betlimit430)) 
        {        
            this.spinner.hide();
            this.toastr.error( "Myanmar typing detected!", '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            });   
            return;   
        }
      }
      this.twodbetEditModel.phone_no= this.sendPhone ;
      this.twodbetEditModel.betlimit1030= this.userList.betlimit1030;
      this.twodbetEditModel.betlimit1201 = this.userList.betlimit1201;
      this.twodbetEditModel.betlimit230 = this.userList.betlimit230;
      this.twodbetEditModel.betlimit430 = this.userList.betlimit430;
      this.http.post(this.funct.ipaddress + 'user/editTwodbetLimit', this.twodbetEditModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if( this.dto.Response == true)
          {
            this.toastr.success("Edit Bet Limit Success", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
            this.amt = '';
            this.twodbetEditModel.phone_no= '' ;
            this.twodbetEditModel.twodbetlimit= '' ;
            this.twodbetlimit = '';
            this.ephone = '';
          }
        }); 
    }
    getAllData() {
      this.userBetLimitList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams(); 
      params = params.set('phoneNo',this.phoneNo).set('betLimitAmt',this.limitAmt).set("pageNumber","1").set("rowsOfPage","10");
      this.http.get(this.funct.ipaddress + 'user/getbetLimitUsers', { params: params, headers: headers } ).pipe(
        catchError(this.handleError.bind(this))
       ).subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data.results;
        this.userBetLimitList = this.dto.Response;
        this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
        this.phoneNo = '';
        this.limitAmt = '';
      })
    }
    gty(page: any){
      this.userBetLimitList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('phoneNo',this.phoneNo).set('betLimitAmt',this.limitAmt).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());
      this.http.get(this.funct.ipaddress + 'user/getbetLimitUsers', { params: params, headers: headers } ).pipe(
        catchError(this.handleError.bind(this))
       ).subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data.results;
        this.userBetLimitList = this.dto.Response;
        this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      })
    }
    getuserDetail(phone_no,twodbetlimit,betlimit1201,betlimit230,betlimit430, update_date,username)
    {
      this.detailph = phone_no
      this.detialBetLimit = twodbetlimit;
      this.detailDate = update_date;
      this.sendPhone = this.detailph;
      this.detialName = username;
      this.detbetlimit1201 = betlimit1201;
      this.detbetlimit230 = betlimit230;
       this.detbetlimit430 = betlimit430;
      $('#showeditModal').modal("show");
     
    }

    editBetLimitModal() 
    {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      this.twodbetEditModel.phone_no= this.sendPhone ;
      if(this.etwodbetlimit1 != null || this.etwodbetlimit1 != undefined)
      {
      this.twodbetEditModel.betlimit1030= this.etwodbetlimit1 ;
      }
      else
      {
        this.twodbetEditModel.betlimit1030 = this.detialBetLimit
      }
      if(this.etwodbetlimit2 != null || this.etwodbetlimit2 != undefined)
      {
        this.twodbetEditModel.betlimit1201= this.etwodbetlimit2 ;
      }
      else
      {
        this.twodbetEditModel.betlimit1201= this.detbetlimit1201 ;
      }
      if(this.etwodbetlimit3 != null || this.etwodbetlimit3 != undefined)
      {
        this.twodbetEditModel.betlimit230= this.etwodbetlimit3 ;
      }
      else
      {
        this.twodbetEditModel.betlimit230= this.detbetlimit230 ;
      }
      if(this.etwodbetlimit4 != null || this.etwodbetlimit4 != undefined)
      {
        this.twodbetEditModel.betlimit430= this.etwodbetlimit4 ;
      }
      else
      {
        this.twodbetEditModel.betlimit430= this.detbetlimit430 ;
      }
      this.http.post(this.funct.ipaddress + 'user/editTwodbetLimit', this.twodbetEditModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if( this.dto.Response == true)
          {
            this.getAllData();
            this.toastr.success("Edit Bet Limit Success", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
            this.sendPhone = '';
            // this.etwodbetlimit1 = '';
            // this.etwodbetlimit2 = '';
            // this.etwodbetlimit3 = '';
            // this.etwodbetlimit4 = '';
            $('#showeditModal').modal("hide");
          }
        }); 
    }

    onSelect(data: any){
    this.ephone = data;
    $("#ename").val(data);
    }
    onFocused(data: any)
    {
      
    }
    onChangeSearch(data: any)
    {

    }
    selectEvent(data : any)
    {

    }
}
