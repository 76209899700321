<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                    <div class="col-sm-8">
                        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
                            Game Maintain All
                        </h3>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Maintain All Alert</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tblalert' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Description (English)</th> 
                                                <th style="font-family: unicode;">Description (Myanmar)</th> 
                                                <th style="font-family: unicode;">Description (Thailand)</th> 
                                                <th style="font-family: unicode;">Description (Chinese)</th> 
                                                <th style="font-family: unicode;">Status</th> 
                                                <th style="font-family: unicode;">Updated By</th> 
                                                <th style="font-family: unicode;">Updated Date</th>                                             
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let gameAlert of gamealertList">
                                                <td style="font-family: unicode;">{{gameAlert.description_en}}</td> 
                                                <td style="font-family: unicode;">{{gameAlert.description_my}}</td> 
                                                <td style="font-family: unicode;">{{gameAlert.description_th}}</td>
                                                <td style="font-family: unicode;">{{gameAlert.description_zh}}</td>
                                                <td style="font-family: unicode;">{{gameAlert.status}}</td>
                                                <td style="font-family: unicode;">{{gameAlert.updated_by}}</td>
                                                <td style="font-family: unicode;">{{gameAlert.updated_date | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                             
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <a (click)="goDetail(gameAlert.id)">
                                                    <i title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>

<div id="editGameMaintainAll" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content" style="width : 700px; margin-left: -100px;">
        <div class="modal-header">
          <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;">Detail </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
  
        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-4"> Description (English) </label>
                <div class="form-group col-md-8">
                  <textarea class="form-control form-control-sm" [(ngModel)]="desEng" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
              </div>
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-4"> Description (Mayanmar) </label>
                <div class="form-group col-md-8">
                  <textarea class="form-control form-control-sm" [(ngModel)]="desMM" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
              </div>
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-4"> Description (Thailand) </label>
                <div class="form-group col-md-8">
                  <textarea class="form-control form-control-sm" [(ngModel)]="desThai" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
              </div>
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-4"> Description (Chinese) </label>
                <div class="form-group col-md-8">
                  <textarea class="form-control form-control-sm" [(ngModel)]="desZh" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
              </div>

              
              <!-- <div class="row">
                <label class="col-md-4"> From Time (24 Hours Format)</label>
                <div class="form-group col-md-1">
                  <input onKeyPress="if(this.value.length==2) return false;"
                    class="form-control form-control-sm" placeholder="HH" [(ngModel)]="fromhours"
                    [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                </div>
                <div class="form-group col-md-1">
                  <input onKeyPress="if(this.value.length==2) return false;"
                    class="form-control form-control-sm" placeholder="MM" [(ngModel)]="fromminutes"
                    [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                </div>
            </div>
            <div class="row">
                <label class="col-md-4"> To Time ( 24 Hours Format )</label>
                <div class="form-group col-md-1">
                    <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="HH" [(ngModel)]="tohours"
                      [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                  </div>
                  <div class="form-group col-md-1">
                    <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="MM" [(ngModel)]="tominutes"
                      [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                  </div>
            </div> -->


            


              <div class="row" style="margin-left: 0.1%">
                <label class="col-md-4"> Status </label>
                <div class="form-group col-md-8 radio">
                  <div class="form-group clearfix">
                    <div class="icheck-success d-inline">
                      <input type="radio" name="r3" checked id="radioSuccess1" value="ACTIVE"
                        [(ngModel)]="statusDetail" [ngModelOptions]="{standalone: true}">
                      <label for="radioSuccess1">Active &nbsp;&nbsp;
                      </label>
                    </div>
                    <div class="icheck-success d-inline">
                      <input type="radio" name="r3" id="radioSuccess2" value="INACTIVE"
                        [(ngModel)]="statusDetail" [ngModelOptions]="{standalone: true}">
                      <label for="radioSuccess2">Inactive
                      </label>
                    </div>
                  </div>
                </div>
            </div>


            <div class="row" style="margin-left: 0.1%">
              <label class="col-md-4"> Notification Option </label>
              <div class="form-group col-md-8 radio">
                <div class="form-group clearfix">
                  <div class="icheck-success d-inline">
                    <input type="radio" name="r4" checked id="radioSuccess3" value="ACTIVE"
                      [(ngModel)]="decisionDetail" [ngModelOptions]="{standalone: true}">
                    <label for="radioSuccess3">is Now? &nbsp;&nbsp;
                    </label>
                  </div>
                  <div class="icheck-success d-inline">
                    <input type="radio" name="r4" id="radioSuccess4" value="INACTIVE"
                      [(ngModel)]="decisionDetail" [ngModelOptions]="{standalone: true}">
                    <label for="radioSuccess4">Advance?
                    </label>
                  </div>
                </div>
              </div>
          </div>



          <div class="row" style="margin-left: 0.1%">
            <label class="col-md-4"> Send Noti? 
            </label>
              <div class="form-group col-md-8 radio">
                <div class="form-group clearfix">
                  <div class="icheck-success d-inline">
                    <input _ngcontent-gba-c139="" type="radio" name="r5" checked="" id="radioTrue" value="false"  [(ngModel)]="NotSend"  [ngModelOptions]="{standalone: true}">
                      <label _ngcontent-gba-c139="" for="radioTrue">Yes &nbsp;&nbsp;
                      </label>
                  </div>
                

                  <div class="icheck-success d-inline">
                    <input type="radio" name="r5" id="radioFalse" value="true"
                      [(ngModel)]="NotSend" [ngModelOptions]="{standalone: true}">
                      <label _ngcontent-gba-c139="" for="radioFalse">No 
                      </label>
                  </div>

                </div>
              </div>

            </div>




            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-2">From Date</label>
              <div class="form-group col-md-2">
                  <input id="alldate" readonly class="form-control form-control-sm" placeholder="Select Date"
                  [ngxDatePicker]="dateInstanceSingle"  [(ngModel)]="alldate"
                  [ngModelOptions]="{standalone: true}" (valueChange)="onChangeSingle()">
                   <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
              </div>
      
              <div class="col-md-1"></div>
              <label class="col-md-2">From Time</label>             
              <div class="form-group col-md-1">
                  <input onKeyPress="if(this.value.length==2) return false;"
                  class="form-control form-control-sm" placeholder="HH" [(ngModel)]="fromhours"
                  [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
              </div>
              <div class="form-group col-md-1">
                  <input onKeyPress="if(this.value.length==2) return false;"
                    class="form-control form-control-sm" placeholder="MM" [(ngModel)]="fromminutes"
                    [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                </div>
          </div>

          <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-2">To  Date</label>
              <div class="form-group col-md-2">
                  <input id="alltodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                  [ngxDatePicker]="dateInstanceSingle1"  [(ngModel)]="alltodate"
                  [ngModelOptions]="{standalone: true}" (valueChange)="onChangeAllToDate()">
                <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
              </div>
      
              <div class="col-md-1"></div>
              <label class="col-md-2">To Time </label>
              <div class="form-group col-md-1">
                  <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="HH" [(ngModel)]="tohours"
                    [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
              </div>
              <div class="form-group col-md-1">
                  <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="MM" [(ngModel)]="tominutes"
                    [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                </div>
          </div> 

          









            <div class="row" style="margin-top: 1%;">
                <div class="col-md-4"></div>
                <div class="col-md-2 col-sm-6">
                    <button type="button" id = "approvesavebutton" class="btn btn-block btn-success"
                        (click)="goSave()">Save</button>&nbsp;</div>
                <div class="col-md-2 col-sm-6">
                    <button type="button" class="btn btn-block btn-danger" data-dismiss="modal">Cancel</button></div>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>