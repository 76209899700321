import { Component, OnInit } from '@angular/core';
import { DtoService } from '../../service/dto.service';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { param } from 'jquery';
declare var $: any;
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */


@Component({
  selector: 'app-users-withdrawal',
  templateUrl: './users-withdrawal.component.html',
  styleUrls: ['./users-withdrawal.component.css']
})
export class UsersWithdrawalComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  name: any;
  status  :any;
  amt : any;
  ephone : any;
  userList : any;
  idIndex : any;
  selectedHero : any;
  phone_no: any;
  prefix: any;
  sendPhone : any;
  userWalletEditModel : any;
  token: any;
  phone_No_withdrawal: any;//phoneNo
  itemsPerPage =  10;
  totalItems : any; 
  page = 1;
  userBetLimitList : any;
  detailph :any;
  detialBetLimit : any;
  detailDate: any;
  detialName :any;
  editBalance: any;
  userId : any;
  wallet : any;
  withdrawal : any;
  editWithdrawal: any;
  adjustModel: any;
  remarkWithdrawal:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;

  constructor(private router: Router,private spinner: NgxSpinnerService,private toastr: ToastrService,private http: HttpClient,private storage: LocalStorageService,private dto: DtoService, private funct: FunctService) { 
    
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "ManageWithdrawal_View";
    this.hardcode_edit = "ManageWithdrawal_Edit";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];    
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }     
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
        if(filteredMenuCodes_view.length != 0)
        {
              this.hardcode_view_bool = true;
        }       
        if(filteredMenuCodeds_edit.length != 0)
        {
              this.hardcode_edit_bool = true;
        }


    //this.getAllData();
    // if (!this.storage.retrieve('phone_No_withdrawal')) {
    //   this.phone_No_withdrawal = '';
    // }
    // else {
    //   this.phone_No_withdrawal = this.storage.retrieve('phone_No_withdrawal');
    // }

    this.storage.store('phone_No_withdrawal', "");  
    //this.phone_No_withdrawal = '';
  }

  ngOnInit(): void {
    this.adjustModel={
      userId :'',
      amount : 0.0,
      actionType : 'withdrawal',
      remarkWithdrawal: ''
    };
    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
      },
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip",
    }
    this.getAllData();
  }
  getAllData() {
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams(); 
    if (!this.storage.retrieve('phone_No_withdrawal')) {
      this.phone_No_withdrawal = '';
    }
    else {
      this.phone_No_withdrawal = this.storage.retrieve('phone_No_withdrawal');
    }
    if(this.phone_No_withdrawal != undefined)
    {
     params = params.set('phoneNo',this.phone_No_withdrawal).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phone_No_withdrawal == undefined && this.name == undefined)
    {
      params = params.set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phone_No_withdrawal != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phone_No_withdrawal).set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    //params = params.set('phoneNo',this.phoneNo).set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getUsersForManage', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     ).
    subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
      //this.phoneNo = '';
    })
  }
  gty(page: any){
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    if (!this.storage.retrieve('phone_No_withdrawal')) 
    {
      this.phone_No_withdrawal = '';
    }
    else 
    {
      this.phone_No_withdrawal = this.storage.retrieve('phone_No_withdrawal');
    }
    if(this.phone_No_withdrawal != undefined)
    {
     params = params.set('phoneNo',this.phone_No_withdrawal).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.getuserDetail == undefined && this.name == undefined)
    {
      params = params.set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.phone_No_withdrawal != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phone_No_withdrawal).set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getUsersForManage', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }
  getuserDetail(userId,phone_no,wallet,username, withdraw)
  {
    this.detailph = phone_no
    this.wallet = wallet;
    this.sendPhone = this.detailph;
    this.detialName = username;
    this.userId = userId;
    this.withdrawal = withdraw;
    this.remarkWithdrawal='';
    $('#showeditModal').modal("show");
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  editUserWithdrawal()
  {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      if(this.sendPhone == null || this.sendPhone == undefined)
      {
        this.toastr.error("Please enter phone number", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      if(this.editWithdrawal == null || this.editWithdrawal== undefined)
      {
        this.toastr.error("please fill amount", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      if(this.editWithdrawal != null && this.editWithdrawal!= undefined && this.editWithdrawal < 0)
      {
        this.toastr.error("please fill amount", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      this.adjustModel.userId= this.userId.toString();
      this.adjustModel.amount= this.editWithdrawal;
      this.adjustModel.remarkWithdrawal= this.remarkWithdrawal;
      this.http.post(this.funct.ipaddress + 'topupWithdrawalAdjust/withdrawalApprovedBySuperAdmin', this.adjustModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          $('#showeditModal').modal("hide");
            this.toastr.success("Withdrawal Approved Success", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
            this.editWithdrawal = '';
            this.getAllData();
        }); 
  }
  handleError(error: HttpErrorResponse)
  {
    this.spinner.hide();
    if(error.status == 403)
    {
      this.toastr.error("Limited Access", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.editWithdrawal = '';
      return;
    }
    if(error.status == 406)
    {
      this.toastr.error("Not enough balance", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.editBalance = '';
      return;
    }
  }
  OkLogout()
  {
    window.location.href ="./ad-login";
  } 
  onChangePhoneNo() {     
    this.storage.store('phone_No_withdrawal', this.phone_No_withdrawal.toString());  
  }
}
