import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { catchError, retry } from 'rxjs/operators';
import { from, throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
declare var $: any;

@Component({
  selector: 'bank-maintenance-withdraw-detail',
  templateUrl: './bank-maintenance-withdraw-detail.component.html',
  styleUrls: ['./bank-maintenance-withdraw-detail.component.css']
})
export class BankMaintenanceWithdrawDetailComponent implements OnInit {

  bankDTO: any;
  token: any;
  bankId: any;
  imagePath: any;
  imgURL: any;
  imgURL1 : any;
  message: string = '';
  count = 0;
  filePath : any;
  displayAcc : any;
  disableAccId : any;
  isHot :any;
  addOrEdit:any;
  originalAdminDTO:any;
  status_filter :any;
  status_newfilter :any;
  decisionDetail :any;
  NotSend:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,) {
      this.storage.store('isNotiSong', "");
     }
  ngOnInit(): void {
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }

    const adminDataString = localStorage.getItem('maintenanceData');
    this.originalAdminDTO = JSON.parse(adminDataString);
	

    this.bankId = this.route.snapshot.paramMap.get("id");
    if (this.bankId == null || this.bankId == 0) {
      $(document).ready(function () {
      });
      this.addOrEdit=false;
      this.bankDTO = {
        id: 0,
        bank_type: '',
        description_en: '',
        description_th: '',
        description_my: '',
        description_zh: '',
        fromTime:'',
        toTime:'',        
        is_maintain: false,
        sendNow:false,
        NotSend:false
      };
    }
    else {
      $(document).ready(function () {
      });
      this.addOrEdit=true;
      this.bankDTO = {
        id: 0,
        bank_type: '',
        description_en: '',
        description_th: '',
        description_my: '',
        description_zh: '',
        fromTime :new Date(),
        toTime: new Date(),
        is_maintain: false,
        created_date: new Date(),
        created_date_str:'',
        created_by: '',
        updated_date:new Date(),
        updated_date_str:'',
        sendNow:false,
        NotSend:false
      };
      this.getbankById();
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    getbankById() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', this.bankId);
    this.http.get(this.funct.ipaddress + 'maintenance/get_maintenance_byid', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response.data));
        this.bankDTO = this.dto.Response.data;
        // const currentDate = new Date().toISOString().slice(0, 16);
        // if (!this.bankDTO.fromTime || this.bankDTO.fromTime === "") {
        //     this.bankDTO.fromTime = currentDate;
        // }
        // if (!this.bankDTO.toTime || this.bankDTO.toTime === "") {
        //     this.bankDTO.toTime = currentDate;
        // }
        
      });
  }
  goSave() {
    if (this.bankId == null || this.bankId == 0) {
      this.save();
    }
    else {
      this.edit();
    }
  }

   save() {
          if(this.bankDTO.bank_type == null || this.bankDTO.bank_type == undefined || this.bankDTO.bank_type =="")
          {
            this.toastr.error("Please enter Bank Name", 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            return;
          }
          if(this.bankDTO.description_en == null || this.bankDTO.description_en == undefined || this.bankDTO.description_en =="")
          {
            this.toastr.error("Please enter Description (English)", 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            return;
          }
         this.spinner.show();
          this.token = this.storage.retrieve('token');
          let headers = new HttpHeaders();
          headers = headers.set('Authorization', this.token);
          this.http.post(this.funct.ipaddress + 'maintenance/insert', this.bankDTO, { headers: headers })
          .pipe(
            catchError(this.handleError.bind(this))
           )
          .subscribe( //change
            result => {
              this.dto.Response = {};
              this.dto.Response = result;
              if (this.dto.Response.status == 'Success') {
                this.spinner.hide();
                this.router.navigate(['/paymentsshistory/threed']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
              }
              else {
                this.spinner.hide();
                this.toastr.error(this.dto.Response.message, 'Invalid!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
              }
            });
  }


  edit() {

    if(this.bankDTO.bank_type == null || this.bankDTO.bank_type == undefined || this.bankDTO.bank_type =="")
    {
      this.toastr.error("Please enter Bank name", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    if(this.bankDTO.description_en == null || this.bankDTO.description_en == undefined || this.bankDTO.description_en =="")
    {
      this.toastr.error("Please enter Description (English)", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization',  this.token);
    
      let editedArray = this.getEditedArray();   
      let headers = new HttpHeaders();

      if(editedArray.length > 0)
      {
        headers = headers.set('Authorization', this.token)
        .set('editedArray', editedArray); 
      }
      else{
        headers = headers.set('Authorization',  this.token);
      } 

      if(this.bankDTO.sendNow =="false")
      {
        this.bankDTO.sendNow = false;

      }
      else{
        this.bankDTO.sendNow = true;
      }
      if(this.bankDTO.NotSend =="false")
      {
        this.bankDTO.NotSend = false;

      }
      else{
        this.bankDTO.NotSend = true;
      } 
    let params = {
      id : this.bankId,
      is_maintain : this.bankDTO.is_maintain,
      description_en : this.bankDTO.description_en,
      description_th : this.bankDTO.description_th,
      description_my : this.bankDTO.description_my,
      description_zh : this.bankDTO.description_zh,
      fromTime : this.bankDTO.fromTime,
      toTime : this.bankDTO.toTime,
      sendNow : this.bankDTO.sendNow,
      NotSend : this.bankDTO.NotSend,
      formpage : "Withdrawal"

    };
    this.http.post(this.funct.ipaddress + 'maintenance/change_is_maintain_new', params, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/paymentsshistory/threed']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }); 
  }

  
  getEditedArray() {
    let editedArray = '';
  
    if (this.bankDTO.is_maintain !== this.originalAdminDTO.is_maintain)
    {
      if(this.bankDTO.is_maintain == true)
      {
          this.status_newfilter = "Active";
      }
      else
      {
          this.status_newfilter = "InActive";
      }
      if(this.originalAdminDTO.is_maintain == true)
      {
          this.status_filter = "Active";
      }
      else
      {
          this.status_filter = "InActive";
      }
      editedArray += 'Status: ' + this.status_filter + ' -> ' + this.status_newfilter + ', ';
    }
    if (editedArray.endsWith(', ')) {
      editedArray = editedArray.slice(0, -2);
    }
    
    return editedArray;
  }
  
}

