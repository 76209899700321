<div class="wrapper">
  <!-- Navbar -->
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-8">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
              Bank Account List
            </h3>
          </div>
        </div>
      </div>
    </div>


    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="form-group col-md-2">
              <label> Account No </label>
              <input class="form-control form-control-sm" [(ngModel)]="accountNo" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-2" style="display: none;">
              <label> Payment Type </label>
              <select id="parent_id" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type"
                [ngModelOptions]="{standalone: true}">
                <option *ngFor="let payment of paymentList" value="{{payment.id}}">
                  {{payment.payment_type}}
                </option>
              </select>
            </div>

            <div class="form-group col-md-2">
              <label> Status </label>
              <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status"
                [ngModelOptions]="{standalone: true}">
                <option value="ACTIVE" selected>Active</option>
                <option value="INACTIVE">Inactive</option>
              </select>
            </div>

            <div class="form-group col-md-2">
              <label> Bank Type </label>
              <!-- <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" 
                                [(ngModel)]="BankType" [ngModelOptions]="{standalone: true}">
                                <option value="null" selected>All</option>
                                <option *ngFor="let bt of bankTypeList" value = "{{bt.id}}" >
                                  {{bt.bankType}}
                                </option>
                          </select> -->

              <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankType"
                [ngModelOptions]="{standalone: true}">
                <option value="null" selected>All</option>
                <option *ngFor="let bt of bankTypeList" [value]="bt.id">
                  {{ type === 'TOPUP' ? bt.bankType : bt.name }}
                </option>
              </select>

            </div>

            <div class="form-group col-md-2">
              <label> Type </label>
              <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeType()">
                <option value="TOPUP" selected>TOPUP</option>
                <option value="WITHDRAWAL">WITHDRAWAL</option>
              </select>
            </div>

            <div class="form-group col-md-2 col-sm-4 col-xs-4">
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                (click)="search()">
                Search
              </button>
            </div>

            <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
              <label></label>
              <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                [routerLink]="['/payment-info-detail-new']">
                Add New
              </button>
            </div>

            <div class="col-md-12" *ngIf="type === 'TOPUP'">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <h3 class="card-title font-weight-bold">Bank Account List</h3>
                    <div class="form-group col-md-2 col-sm-4 col-xs-4 ml-auto">
                      <button id="confirm" type="button" class="btn btn-block btn-sm"
                        style="background-color: #FFC107; color: white; margin-bottom: 12%;" (click)="readNotiData()">
                        <span> Config = {{config}} {{persentage}}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- *ngIf="type === 'TOPUP'" -->
                <div class="card-body">
                  <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                    class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">Bank Type</th>

                        <th style="font-family: unicode;">Type</th>

                        <th style="font-family: unicode;">Account Number</th>

                        <th style="font-family: unicode;">Code Number</th>

                        <th style="font-family: unicode;">Account Name</th>

                        <th style="font-family: unicode;">Minimum Amount</th>

                        <th style="font-family: unicode;">Maximum Amount</th>

                        <th style="font-family: unicode;">Max-Account Count</th>

                        <th style="font-family: unicode;">Limited Amount</th>

                        <th style="font-family: unicode;">Today Reach Amount</th>

                        <th style="font-family: unicode;">Status</th>

                        <!-- <th style="font-family: unicode;">Order Number</th> -->

                        <th style="font-family: unicode;">Created Date</th>
                        <th style="font-family: unicode;">Updated Date</th>
                        <th style="font-family: unicode;">Updated By</th>

                        <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let payment of paymentList">

                        <td style="font-family: unicode;">{{payment.payment_type}}</td>

                        <td style="font-family: unicode;">{{payment.type}}</td>

                        <td style="font-family: unicode;">{{payment.account_no}}</td>

                        <td style="font-family: unicode;">{{payment.code_number}}</td>

                        <td style="font-family: unicode;">{{payment.account_name}}</td>

                        <td style="font-family: unicode;">{{payment.min_amount_Str}}</td>
                        <td style="font-family: unicode;">{{payment.max_amount_Str}}</td>
                        <td style="font-family: unicode;">{{payment.maxAccountCount}}</td>
                        <td style="font-family: unicode;">{{payment.limited_amt_Str}}</td>

                        <td style="font-family: unicode;">{{payment.today_reach_amt_Str}}</td>

                        <td style="font-family: unicode;">{{payment.status}}</td>

                        <!-- <td style="font-family: unicode;">{{payment.order_id}}</td> -->

                        <td style="font-family: unicode;">{{payment.created_date_Str}}</td>

                        <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                        <td style="font-family: unicode;">{{payment.updated_by_name}}</td>


                        <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">

                          <a (click)="addStepAndNavigate(payment)">
                            <i class="fas fa-edit" style="color:#4b88eb;"></i>
                          </a>
                          &nbsp;&nbsp;
                          <a herf="#" id="viewDetailMe" title="View Detail">
                            <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                              (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                            </i>

                          </a>

                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <!-- amk added for WITHDRAWAL -->

            <div class="col-md-12" *ngIf="type === 'WITHDRAWAL'">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Bank Account List </h3>
                </div>

                <div class="card-body">
                  <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                    class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">Bank Type</th>

                        <th style="font-family: unicode;">Type</th>

                        <th style="font-family: unicode;">Account Number</th>


                        <th style="font-family: unicode;">Code Number</th>
                        <th style="font-family: unicode;">Account Name</th>
                        <th style="font-family: unicode;">Today Withdrawal Amount</th>

                        <th style="font-family: unicode;">Status</th>

                        <!-- <th style="font-family: unicode;">Order Number</th> -->

                        <th style="font-family: unicode;">Created Date</th>
                        <th style="font-family: unicode;">Updated Date</th>
                        <th style="font-family: unicode;">Updated By</th>

                        <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let payment of paymentList">

                        <td style="font-family: unicode;">{{payment.payment_type}}</td>

                        <td style="font-family: unicode;">{{payment.type}}</td>

                        <td style="font-family: unicode;">{{payment.account_no}}</td>

                        <td style="font-family: unicode;">{{payment.code_number}}</td>
                        <td style="font-family: unicode;">{{payment.account_name}}</td>

                        <td style="font-family: unicode;">{{payment.today_withdrawal_amt_Str}}</td>
                        <td style="font-family: unicode;">{{payment.status}}</td>



                        <td style="font-family: unicode;">{{payment.created_date_Str}}</td>

                        <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                        <td style="font-family: unicode;">{{payment.updated_by_name}}</td>


                        <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">

                          <a (click)="addStepAndNavigate(payment)">
                            <i class="fas fa-edit" style="color:#4b88eb;"></i>
                          </a>
                          &nbsp;&nbsp;
                          <a herf="#" id="viewDetailMe" title="View Detail">
                            <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                              (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                            </i>

                          </a>

                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



          </div>
        </form>

      </div>

    </section>
  </div>

  <aside class="control-sidebar control-sidebar-dark">

  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>


<div id="browseAccountHolderList" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width : 700px; margin-left: -100px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Account Holder (Admin) Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-2"> Bank Type </label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="bankName" [ngModelOptions]="{standalone: true}"
                  disabled>
              </div>
              <div class="col-md-1"></div>
              <label class="col-md-2">Account Number</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="account_no"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
            </div>

            <div class="row" style="margin-left: 0.1%;">

              <label class="col-md-2">Code Number</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="code_number"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
              <div class="col-md-1"></div>
              <label class="col-md-2">Account Name</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="account_name"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
            </div>

            <div class="col-md-12" style="margin-top: 0.5%;">

              <div class="card">
                <div class="card-body">
                  <table width='100%' id="{{'paymentBankAccountHolderDetaiList' + idIndex2}}" datatable
                    [dtTrigger]="dtTrigger2" [dtOptions]="dtOptions2" class="table table-bordered table-hover">

                    <thead>
                      <tr>
                        <th style="font-family: unicode;">No</th>
                        <th style="font-family: unicode;">Account Holder(Admin)</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let accDetail of paymentBankAccountHolderDetaiList, let i = index">
                        <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                        <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                          {{accDetail.adminName}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<!-- 
<div id="readData" class="modal fade">
  <div class="modal-dialog modal-dialog-above" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div _ngcontent-xai-c117="" class="row" style="margin-left: 70px;">
          <label _ngcontent-xai-c117="" class="col-md-4">
            Percent (%) </label>
        </div>
        <div _ngcontent-xai-c117="" class="row" style="margin-left: 70px;">
          <div _ngcontent-xai-c117="" class="form-group col-md-8">
            <input _ngcontent-xai-c117="" class="form-control form-control-sm ng-untouched ng-pristine ng-valid" [(ngModel)]="persentage_data">
          </div>
        </div>
       
        <div _ngcontent-xai-c117="" class="row" style="margin-left: 70px;">
          <div _ngcontent-xai-c117="" id="editBtn" class="col-md-3 col-sm-3">
            <button _ngcontent-xai-c117=""
              type="button" class="btn btn-block btn-success" (click)="readNoti()"> Ok 
            </button>
          </div>
          <div _ngcontent-xai-c117="" class="col-md-8 col-sm-8">
            <button _ngcontent-xai-c117="" type="button"
              class="btn btn-block btn-danger" tabindex="0" (click)="closeTran()"> Cancel 
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->






<div id="readData" class="modal fade">
  <div class="modal-dialog modal-dialog-above" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="row justify-content-center mb-3">
          <label class="col-md-4 text-right">Percent (%)</label>
          <div class="col-md-6">
            <input class="form-control form-control-sm" [(ngModel)]="persentage_data">
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-3">
            <button type="button" class="btn btn-success btn-block" (click)="readNoti()">Ok</button>
          </div>
          <div class="col-md-3">
            <button type="button" class="btn btn-danger btn-block" (click)="closeTran()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
