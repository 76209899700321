<div class="wrapper">

  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
  
    <div class="content-header">
      <div class="container-fluid">
      </div>
    </div>
  
    <section class="content">
      <div class="container-fluid">
        <form>

          <!-- <div class="card">
            <div class="card-header">
              <h3 class="card-title" style="font-weight: bold;"> Forgot Password Detail </h3>
            </div>
            <div class="card-body">
              <form>
                <div class = "row">
                  <label class="col-md-2" style="font-weight: bold;"> Transaction screenshot </label>
                  <div class="form-group col-md-3">
                      <img [src] ="imgURL" height="100%" width="70%">
                  </div>  
                </div>
              </form>
            </div>
          </div> -->

<!-- 
          <div class="card">
            <div class="card-header">
              <h3 class="card-title" style="font-weight: bold;">Forgot Password Detail</h3>
            </div>
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <label style="font-weight: bold;">Transaction screenshot</label>
                    <div class="form-group ">
                      <img [src]="imgURL" height="auto" width="70%" style="max-height: 200px;">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h4 style="font-weight: bold;">Last Topup Information</h4>
                    <div class="form-group">
                      <label for="topupAmount">Topup Amount:</label>
                      <input type="text" class="form-control" id="topupAmount" style="width: 200px; height: 30px;" disabled>
                    </div>
                    <div class="form-group">
                      <label for="topupDate">Topup Date:</label>
                      <input type="text" class="form-control" id="topupDate">
                    </div>
                    <div class="form-group">
                      <label for="topupMethod">Topup Method:</label>
                      <input type="text" class="form-control" id="topupMethod">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
           -->


           <div class="card">
            <div class="card-header">
              <h3 class="card-title" style="font-weight: bold;">Forgot Password Detail</h3>
            </div>
            <div class="card-body">
              <!-- <form>
                <div class="row">            
                  
                  <div class="col-md-6">
                    <label style="font-weight: bold;">Transaction screenshot</label>
                    <div class="form-group ">
                      <img [src]="imgURL" height="auto" width="70%" style="max-height: 200px;">
                    </div>
                  </div>
                
                  <div class="col-md-6">
                    <h4 style="font-weight: bold;">Last TopUp Information</h4>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label">Transaction No:</label>
                      <div class="col-sm-8">                      
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_transaction_no" style="width: 300px; height: 30px;"
                        [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label">Topup Amount:</label>
                      <div class="col-sm-8">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_amount_str" style="width: 300px; height: 30px;"
                        [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label">Bank Type:</label>
                      <div class="col-sm-8">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_bank_type" style="width: 300px; height: 30px;"
                        [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label">Last TopUp Date:</label>
                      <div class="col-sm-8">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_date_str" style="width: 300px; height: 30px;"
                        [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </form> -->

              <form>
                <div class="row">
                
                  <div class="col-md-6">
                   
                    <div class="form-group row">
                      <label class="col-md-4" style="font-weight: bold;"> Transaction screenshot </label>
                      <div class="col-sm-6">
                        <img [src]="imgURL" height="auto" width="70%" style="height: 400px;">
                        <!-- <img [src] ="imgURL" height="100%" width="70%"> -->
                      </div>
                    </div>
                  </div>

          
              
                
                  <div class="col-md-6">
                    <h4 style="font-weight: bold;">Last TopUp Information</h4>

                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label">Transaction No:</label>
                      <div class="col-sm-8">                      
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_transaction_no" style="width: 300px; height: 30px;"
                        [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>

                    
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Topup Amount:</label>
                      <div class="col-sm-8">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_amount_str"
                        style="width: 300px; height: 30px;"  [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Bank Type:</label>
                      <div class="col-sm-8">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_bank_type"
                        style="width: 300px; height: 30px;"  [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Last TopUp Date:</label>
                      <div class="col-sm-8">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.last_date_str"
                        style="width: 300px; height: 30px;" [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              

            </div>
          </div>
          
          


          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Forgot Password Detail </h3>
                </div>
               
                <div class="card-body">
                  <form>

                    <div class="row">
                      <label class="col-md-2"> Name </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.name"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>

                      <!-- <div class="col-md-1"></div>
                      <label class="col-md-2"> Latest Trans No </label>
                      <div class="form-group col-md-3">
                        <input autosize class="form-control form-control-sm" [(ngModel)]="bankslipDTO.phone_no"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div> -->

                    </div>


                    <div class="row">
                      <label class="col-md-2"> Phone Number </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.phone_no"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Created Date </label>
                      <div class="form-group col-md-3">
                        <input autosize class="form-control form-control-sm" [(ngModel)]="bankslipDTO.created_date_str"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Updated Date </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.updated_date_str"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Updated By </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="bankslipDTO.updated_by"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    
                    <div class="row">
                      <label class="col-md-2"> Status </label>
                      <div class="form-group col-md-3">
                        <select *ngIf="bsstatus=='FINISHED' || bsstatus == 'IGNORED'" disabled id = "bsstatus"  class="form-control" [(ngModel)]="bsstatus" [ngModelOptions]="{standalone: true}">
                            <option value = "ADDED">ADDED</option>
                            <option value = "APPROVED">APPROVED</option>
                            <option value = "DENIED">DENIED</option>
                            <option value = "FINISHED">FINISHED</option>
                            <option value = "IGNORED">IGNORED</option>
                        </select>
                        <select id = "bsstatus" *ngIf="bsstatus!='FINISHED'" class="form-control" [(ngModel)]="bsstatus" [ngModelOptions]="{standalone: true}">
                            <option value = "ADDED" disabled>ADDED</option>
                            <option value = "APPROVED">APPROVED</option>
                            <option value = "DENIED">DENIED</option>
                        </select>
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                      </div>
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-danger"
                          [routerLink]="['/bank-slip-list']">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
               
              </div>
            </div>
          </div>
        </form>
      
      </div>

    </section>
  
  </div>

  <aside class="control-sidebar control-sidebar-dark">

  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>