import { Component, OnInit } from '@angular/core';
import { DtoService } from '../../service/dto.service';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
// import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Console } from 'console';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-configure-user-betlimit',
  templateUrl: './configure-user-3Dbetlimit.component.html',
  styleUrls: ['./configure-user-3Dbetlimit.component.css']
})
export class ConfigureUser3DBetlimitComponent implements OnInit {
dtTrigger: Subject<any> = new Subject();
dtOptions: DataTables.Settings = {};

 name: any;
 status  :any;
 amt : any;
 ephone : any;
 userList : any;
 idIndex : any;
 selectedHero : any;
 phone_no: any;
 prefix: any;
 sendPhone : any;
 twodbetEditModel : any;
 token: any;
 twodbetlimit:any;
 nphone: any;
 nprefix: any;
 ntwodbetlimit:any;
 limitAmt : any;
 phoneNo: any;
 itemsPerPage =  10;
 totalItems : any; 
 page = 1;
 userBetLimitList : any;
 detailph :any;
 detialBetLimit : any;
 detailDate: any;
 etwodbetlimit1: any;
 etwodbetlimit2:any;
 etwodbetlimit3:any;
 etwodbetlimit4:any;
 threedbetlimit:any;
 detialName :any;
 amt1030: any;
 amt1201: any;
 amt230: any;
 amt430: any;
 betLimitAmountModel : any;
 twodbetlimit1030: any;
 twodbetlimit1201: any;
 twodbetlimit230: any;
 twodbetlimit430: any;

 detbetlimit1030:any;
 detbetlimit1201: any;
 detbetlimit230: any;
 detbetlimit430: any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  type:any;



 constructor( private router: Router,private spinner: NgxSpinnerService,private toastr: ToastrService,private http: HttpClient,private storage: LocalStorageService,private dto: DtoService, private funct: FunctService,private route: ActivatedRoute) 
  { 
    this.prefix = "+959";
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "Configure3DUserBetLimit_View";
    this.hardcode_edit = "Configure3DUserBetLimit_Edit";
    this.hardcode_add = "Configure3DUserBetLimit_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
      if(filteredMenuCodes_view.length != 0)
      {
          this.hardcode_view_bool = true;
      }
      if(filteredMenuCodeds_add.length != 0)
      {
          this.hardcode_add_bool = true;
      }
      if(filteredMenuCodeds_edit.length != 0)
      {
          this.hardcode_edit_bool = true;
      }
  }
  ngOnInit(): void
   {

    //amk added 2024-07-17

    document.addEventListener('DOMContentLoaded', (event) => 
    {
      document.getElementById('pending-tab').addEventListener('click', function() {
          window.location.href = '/configure-threedbetlimit/Main';
      });  
      document.getElementById('approve-tabs').addEventListener('click', function() {
          window.location.href = '/configure-threedbetlimit/Specific-user';
      });  
      document.getElementById('rejected-tab').addEventListener('click', function() {
          window.location.href = '/configure-threedbetlimit/bet-limit-user-list';
      });  
     
  });
  

    //


    this.userList =[];
      this.status = 'all';  
      this.threedbetlimit ='';
      this.twodbetEditModel={
        phone_no : '',
        threedbetlimit: ''
      }
  this.betLimitAmountModel=
   {   
    threedbetlimit:''
   }
      this.dtOptions = 
      {
        responsive: true,
        order:[],
        paging: false,
        info : true,
        dom: "Bfrltip",
      }
      this.getAllData();//amk comment 2024-07-17
      //amk added 2024-07-17
      this.type = this.route.snapshot.paramMap.get("type");
      if(this.type == 'Main')
      {
        $('#pending-tab').tab('show');
      }
      else if(this.type == 'Specific-user'){
        this.clearUsersData();
        $('#approve-tabs').tab('show');
      }
      else if(this.type == 'bet-limit-user-list')
      {      
        $('#rejected-tab').tab('show');
      } 
      //
    }

  OkLogout()
  {
    window.location.href ="./ad-login";
  } 
  goSave()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    if(this.betLimitAmountModel.threedbetlimit == null || this.betLimitAmountModel.threedbetlimit == "" ||  this.betLimitAmountModel.threedbetlimit == undefined)
    { 
      this.toastr.error("Please enter 3D bet limit amount", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.spinner.hide();
      return;
    }
    else if(this.betLimitAmountModel.threedbetlimit != null || this.betLimitAmountModel.threedbetlimit != "" ||  this.betLimitAmountModel.threedbetlimit != undefined)
    {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      if (myanmarCharactersRegex.test(this.betLimitAmountModel.threedbetlimit)) 
      {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });   
          return;   
      }
    }
   
    var formData  = new FormData();
    formData.append("threedbetlimit",this.amt);
    this.http.post(this.funct.ipaddress + 'user/saveThreedBetLimitAllUser',this.betLimitAmountModel, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if( this.dto.Response.status == "Success")
        {
          this.toastr.success("Save Three Bet Limit Success", 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
          this.betLimitAmountModel.threedbetlimit = '';
      
          this.spinner.hide();
        }
      });
  }
  clearUsersData()
  {
    this.userList = [];
    this.ephone ='';
  }
  clearData()
  {
    this.betLimitAmountModel.threedbetlimit = '';
  }
  handleError(error: HttpErrorResponse)
    {
      this.spinner.hide();
      if(error.status == 403)
      {
        this.toastr.error("Limited Access", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.twodbetlimit = '';
        return;
      }
    }
    onInput(event: InputEvent) {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      const inputValue = (event.target as HTMLInputElement).value; 
      if(event.data!=null)
      {
        if (myanmarCharactersRegex.test(inputValue)) 
        {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });  
        }
      }   
      
    }
    // getUser()
    // {
    //   this.spinner.show();
    //   if(this.ephone != null && this.ephone != undefined)
    //   {
    //     const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    //     if (myanmarCharactersRegex.test(this.ephone)) 
    //     {        
    //         this.spinner.hide();
    //         this.toastr.error( "Myanmar typing detected!", '', {
    //         timeOut: 5000,
    //         positionClass: 'toast-top-right',
    //         });   
    //         return;   
    //     }
    //     // if(this.ephone.startsWith('09') || this.ephone.startsWith("+66") || this.ephone.startsWith("+959")
    //     // || this.ephone.startsWith("66") || this.ephone.startsWith("959"))
    //     // {
    //     //   this.ephone = this.ephone.substring(2);
    //     // }
    //       this.sendPhone = this.prefix + this.ephone;
    //         this.dto.token = this.storage.retrieve('token');
    //         let headers = new HttpHeaders();
    //         headers = headers.set('Authorization', this.dto.token);
    //         let params = new HttpParams();
    //         params = params.set('phone_no',this.sendPhone);
    //         this.http.get(this.funct.ipaddress + 'user/liveSearchUsers3D', { params: params, headers: headers } )
    //         .pipe(
    //           catchError(this.handleError.bind(this))
    //         )
    //         .subscribe(
    //           result => 
    //           {
    //             this.dto.Response = {};
    //             this.dto.Response = result;
    //             this.userList = this.dto.Response;
    //             this.spinner.hide();
    //             if(this.userList == null)
    //             {
    //               this.toastr.error("User not found", 'Invalid!', {
    //                 timeOut: 3000,
    //                 positionClass: 'toast-top-right'
    //               });
    //               this.twodbetlimit = '';
    //               return;
    //             }
    //             else
    //             {
    //               this.twodbetlimit = this.userList.twodbetlimit;
    //             }
    //           }
    //         );
    //       }
    //       else
    //       {
    //         this.toastr.error("Please enter phone number", 'Invalid!', {
    //           timeOut: 3000,
    //           positionClass: 'toast-top-right'
    //         });
    //         this.twodbetlimit = '';
    //         return;
    //       }
    //   }

  


  getUser() {
    this.spinner.show();
  
    if (this.ephone != null && this.ephone != undefined) {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      
      if (myanmarCharactersRegex.test(this.ephone)) {
        this.spinner.hide();
        this.toastr.error("Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
        });
        return;
      }
  
      this.sendPhone = this.prefix + this.ephone;
      this.dto.token = this.storage.retrieve('token');
      const headers = new HttpHeaders().set('Authorization', this.dto.token);
      const params = new HttpParams().set('phone_no', this.sendPhone);

  
      this.http.get(this.funct.ipaddress + 'user/liveSearchUsers3D', { params, headers })
      .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.spinner.hide(); 
            console.log('Result:', result);
            if (result != null && result !== undefined && Object.keys(result).length > 0) 
            {
              this.dto.Response = result;
              this.userList = this.dto.Response;
              this.twodbetlimit = this.userList.twodbetlimit;
            }
             else
             {               
              this.toastr.error("User not found", 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              this.twodbetlimit = '';
              this.userList ="";
            }
          },
          error => 
          {            
            this.spinner.hide();
            this.toastr.error("Error fetching user data", 'Error!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            console.error('Error:', error);
            this.twodbetlimit = '';
            this.userList ="";
          }
        );
    } else {
      this.spinner.hide();
      this.toastr.error("Please enter phone number", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      this.twodbetlimit = '';
      this.userList ="";
      return;
    }
  }
  



    ResetData()
    {
      this.amt = '';
      this.ephone = '';
      this.twodbetlimit = '';
    }
    editBetLimit() 
    {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      if(this.sendPhone == null || this.sendPhone == undefined)
      {
        this.toastr.error("Please enter phone number", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      else if(this.sendPhone != null || this.sendPhone != undefined)
      {
        const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
        if (myanmarCharactersRegex.test(this.sendPhone)) 
        {        
            this.spinner.hide();
            this.toastr.error( "Myanmar typing detected!", '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            });   
            return;   
        }
      }     
      
      this.twodbetEditModel.phone_no= this.sendPhone ;
      this.twodbetEditModel.threedbetlimit= this.userList.threedbetlimit; 
      this.http.post(this.funct.ipaddress + 'user/editThreedbetLimit', this.twodbetEditModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if( this.dto.Response == true)
          {
            this.toastr.success("Edit 3D Bet Limit Success", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
            this.amt = '';
            this.twodbetEditModel.phone_no= '' ;
            this.twodbetEditModel.threedbetlimit= '' ;
            this.ephone = '';
          }
        }); 
    }
    getAllData() {
      this.userBetLimitList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams(); 
      params = params.set('phoneNo',this.phoneNo).set('betLimitAmt',this.limitAmt).set("pageNumber","1").set("rowsOfPage","10");
      this.http.get(this.funct.ipaddress + 'user/get3DbetLimitUsers', { params: params, headers: headers } ).pipe(
        catchError(this.handleError.bind(this))
       ).subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data.results;
        this.userBetLimitList = this.dto.Response;
        this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
        this.phoneNo = '';
        this.limitAmt = '';
      })
    }
    gty(page: any){
      this.userBetLimitList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('phoneNo',this.phoneNo).set('betLimitAmt',this.limitAmt).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());
      this.http.get(this.funct.ipaddress + 'user/getbetLimitUsers', { params: params, headers: headers } ).pipe(
        catchError(this.handleError.bind(this))
       ).subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data.results;
        this.userBetLimitList = this.dto.Response;
        this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      })
    }
    getuserDetail(phone_no,threedbetlimit, update_date,username)
    {
      this.detailph = phone_no;  
      this.detailDate = update_date;
      this.sendPhone = this.detailph;
      this.detialName = username;
      this.threedbetlimit = threedbetlimit;   
      $('#showeditModal').modal("show");
     
    }

    editBetLimitModal() 
    {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      this.twodbetEditModel.phone_no= this.sendPhone ;
      if(this.threedbetlimit != null || this.threedbetlimit != undefined)
      {
      this.twodbetEditModel.threedbetlimit= this.threedbetlimit ;
      }
      else
      {
        this.twodbetEditModel.threedbetlimit = this.threedbetlimit;
      }    
      this.http.post(this.funct.ipaddress + 'user/editThreedbetLimit', this.twodbetEditModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if( this.dto.Response == true)
          {
            this.getAllData();
            this.toastr.success("Edit 3D Bet Limit Success", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
            this.sendPhone = '';              
            $('#showeditModal').modal("hide");
          }
        }); 
    }

    onSelect(data: any){
    this.ephone = data;
    $("#ename").val(data);
    }
    onFocused(data: any)
    {
      
    }
    onChangeSearch(data: any)
    {

    }
    selectEvent(data : any)
    {

    }
}
